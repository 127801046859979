import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import * as _ from "underscore";
import {Helmet} from 'react-helmet';
import {Link, withRouter} from "react-router-dom";
import {addElemToCart, addElemToIndexes, clearCart, setCartSum} from "../../actions/CartActions";
import {getCheck, setActiveAccount, setActivePeriodForCart} from "../../actions/MenuActions";
import GetCheckModal from "../Widget/GetCheckNew";
import ArrowLeft from "../../images/menu/arrow_left.svg";
import NoPhoto from "../../images/menu/no_photo.png";
import Minus from '../../images/menu/minus.svg';
import Plus from '../../images/menu/plus.svg';
import {MAIN_COLOR} from "../../utils/constants";

const Cart = props => {
  const {history} = props;

  const [isKitchenAvailable, setKitchenAvailable] = useState(false);

  const {cart, sum, cartModifiedIndexes} = useSelector(store => store.cart);
  const {activeAccountForCart, activeDay, activePeriod, checkData, checkLoaded} = useSelector(store => store.menu);
  const {activeCompany} = useSelector(store => store.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(cart, 'cart')

    if (Array.isArray(cart) && cart.length === 0) {
      dispatch(setActiveAccount(null));
      dispatch(setActivePeriodForCart(null));
    }
  }, [cart]);

  useEffect(() => {
    console.log(activePeriod, 'activePeriod')
  }, [activePeriod]);

  useEffect(() => {
    console.log(activeAccountForCart, 'activeAccountForCart')
  }, [activeAccountForCart]);

  const editCart = item => {
    let items = cart ? cart.slice() : [];
    let sumModified = 0;

    if (_.size(item.modifiers) > 0) {
      let modItemIndex = items.findIndex(elem => {
        if (elem.modifiers.length > 0) {
          return (
            elem.id === item.id && elem.modifiers[0].id === item.modifiers[0].id
          );
        } else {
          return false;
        }
      });

      let modItemInCart = items.find(elem => {
        if (elem.modifiers.length > 0) {
          return (
            elem.id === item.id && elem.modifiers[0].id === item.modifiers[0].id
          );
        } else {
          return false;
        }
      });

      const newAmount = item.amount;
      modItemInCart = {...modItemInCart, amount: newAmount};
      items[modItemIndex] = modItemInCart;

      sumModified =
        (parseInt(item.price, 10) + parseInt(item.modifiers[0].price, 10)) *
        parseInt(item.amount, 10);
    } else {
      let withoutModItemIndex = items.findIndex(
        elem => elem.id === item.id && elem.modifiers.length === 0,
      );

      let itemInCart = items.find(
        elem => elem.id === item.id && elem.modifiers.length === 0,
      );
      const newAmount = item.amount;
      itemInCart = {...itemInCart, amount: newAmount};
      items[withoutModItemIndex] = itemInCart;

      sumModified = parseInt(item.price, 10) * parseInt(item.amount, 10);
    }

    items = items.filter(elem => elem.amount !== 0);

    let cartSum = items.reduce((acc, cur) => {
      if (_.size(cur.modifiers) > 0) {
        return acc + (+cur.price + +cur.modifiers[0].price) * cur.amount;
      } else {
        return acc + +cur.price * cur.amount;
      }
    }, 0);

    dispatch(setCartSum(cartSum));
    dispatch(addElemToCart(items));
  };

  const removeFromIndexes = (productId, modId) => {
    if (_.some(cartModifiedIndexes, item => item.id === productId)) {
      let arr = cartModifiedIndexes.map(a => Object.assign({}, a));
      arr.reverse();
      let item = arr.find(i => i.id === productId && i.modId === modId);
      if (item) {
        if (item.amount > 1) {
          item.amount -= 1;
        } else {
          let index = arr.findIndex(
            i => i.id === productId && i.modId === modId,
          );
          arr.splice(index, 1);
        }
      }
      arr.reverse();
      dispatch(addElemToIndexes(arr));
    }
  };

  const addToIndexes = (productId, modId, amount) => {
    if (_.some(cartModifiedIndexes, item => item.id === productId)) {
      let arr = cartModifiedIndexes ? cartModifiedIndexes.slice() : [];
      arr.push({id: productId, modId, amount});
      dispatch(addElemToIndexes(arr));
    }
  };

  const removeItem = data => {
    let item = {
      ...data,
      amount: data.amount - 1,
    };

    editCart(item);

    removeFromIndexes(
      data.id,
      _.size(data.modifiers) > 0 ? data.modifiers[0].id : null,
    );
  };

  const addItem = data => {
    let item = {
      ...data,
      amount: data.amount + 1,
    };

    editCart(item);

    addToIndexes(
      data.id,
      _.size(data.modifiers) > 0 ? data.modifiers[0].id : null,
      1,
    );
  };

  const check = owner => {
    let filteredCart = cart.filter(order => order.restId === owner)
    dispatch(getCheck(activeDay, owner, activeCompany.id, activePeriod.id, filteredCart));
    history.push('/check'); 
  };

  const CartItem = (data, index) => {
    return (
      <OrderItem isNotFirst={index}>
        <ItemImage
          src={data.mediaId
            ? `https://menuforme.online/img/cache/so_product_preview_quad/${data.mediaId}.jpg`
            : NoPhoto
          }
        />
        <ItemInfo>
          <ItemName>{data.name}</ItemName>
          {data.modifiers.length > 0 && (
            <ModifierName>{data.modifiers[0].name}</ModifierName>
          )}
          {+data.weight > 0 ? (
            <ItemWeight>{+data.weight} г</ItemWeight>
          ) : null}
          <ButtonsBlock>
            <CounterMinusButton onClick={() => removeItem(data)}>
              <img src={Minus} width={10} height={1} alt="" />
            </CounterMinusButton>
            <Counter>{data.amount}</Counter>
            <CounterPlusButton
              notMaxAmount={
                data.amount <
                (_.size(data.modifiers) > 0
                  ? Math.min(
                    data.amountPerOrder !== null
                      ? data.amountPerOrder
                      : Infinity,
                    data.modifiers[0].amountPerOrder !== null
                      ? data.modifiers[0].amountPerOrder
                      : Infinity,
                  )
                  : data.amountPerOrder !== null
                    ? data.amountPerOrder
                    : Infinity)
              }
              onClick={() => {
                if (
                  data.amount <
                  (_.size(data.modifiers) > 0
                    ? Math.min(
                      data.amountPerOrder !== null
                        ? data.amountPerOrder
                        : Infinity,
                      data.modifiers[0].amountPerOrder !== null
                        ? data.modifiers[0].amountPerOrder
                        : Infinity,
                    )
                    : data.amountPerOrder !== null
                      ? data.amountPerOrder
                      : Infinity)
                ) {
                  addItem(data);
                }
              }}
            >
              <img src={Plus} width={10} height={10} alt="" />
            </CounterPlusButton>
          </ButtonsBlock>
        </ItemInfo>
        <ItemPrice>
          {data.modifiers.length > 0
            ? (+data.price + +data.modifiers[0].price) * data.amount + ' ₽'
            : +data.price * data.amount + ' ₽'}
        </ItemPrice>
      </OrderItem>
    );
  };

  return (
    <Page>
      <Helmet>
        <title>Корзина</title>
      </Helmet>
      {/* <GetCheckModal
        isMobile={false}
        checkData={checkData}
        periods={activePeriod ? [[activePeriod.id, activePeriod.from, activePeriod.to]] : []}
        getCheck={dispatch(getCheck)}
        checkLoaded={checkLoaded}
      /> */}
      <CartWrapper>
        <CartTitleWrapper>
          <CartContainer>
            <CartTitle>
              <BackButton to={`/`}>
                <img src={ArrowLeft} width={42} height={42} alt='' />
              </BackButton>
              <TitleText>Корзина</TitleText>
              {(cart && cart.length) ? (
                <CleatCartButton
                  onClick={() => {
                    dispatch(clearCart());
                    dispatch(setActiveAccount(null));
                    dispatch(setActivePeriodForCart(null));
                  }}
                >
                  Очистить корзину
                </CleatCartButton>
              ) : null}
            </CartTitle>
          </CartContainer>
        </CartTitleWrapper>
        <CartMain>
          <CartContainer>
            {(cart && cart.length) ? (
              <>
                <Order>
                  <OrderTitle>Заказ</OrderTitle>
                  {activeAccountForCart ? (
                    <RestName>{activeAccountForCart.name}</RestName>
                  ) : null}
                  <OrderList>
                    {cart.map((item, index) => CartItem(item, index))}
                  </OrderList>
                </Order>
                <CartBottom>
                  <CartBottomPrice>{sum + ' ₽'}</CartBottomPrice>
                  <BuyButton onClick={() => check(activeAccountForCart.id)}>
                    <BuyButtonText>Перейти к оформлению</BuyButtonText>
                  </BuyButton>
                </CartBottom>
              </>
            ) : (
              <Order>
                <OrderTitle>Корзина пуста</OrderTitle>
              </Order>
            )}
          </CartContainer>
        </CartMain>
      </CartWrapper>
    </Page>
  );
};

export default withRouter(Cart);

const Page = styled.div`
  padding-top: 122px;
`;

const CartWrapper = styled.div`
  width: 100%;
`;

const CartTitleWrapper = styled.div`
  width: 100%;
  padding: 12px 0 45px;
  background-color: #FFFFFF;
  border-bottom-right-radius: 32px;
  border-bottom-left-radius: 32px;
`;

const CartContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 45px;
`;

const CartTitle = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled(Link)`
  display: flex;
  width: 42px;
  height: 42px;
`;

const TitleText = styled.p`
  margin: 0 0 0 20px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #2C2E32;
  user-select: none;
`;

const CleatCartButton = styled.p`
  margin: 0 0 0 auto;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #9D9D9D;
  user-select: none;
  cursor: pointer;
`;

const CartMain = styled.div`
  padding: 40px 0;
`;

const Order = styled.div`
  width: 100%;
  padding: 50px 60px;
  background-color: #FFFFFF;
  border-radius: 32px;
`;

const OrderTitle = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #2C2E32;
`;

const RestName = styled.p`
  margin: 9px 0 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #2C2E32;
`;

const OrderList = styled.div`
  width: 100%;
  margin-top: 18px;
`;

const OrderItem = styled.div`
  display: flex;
  width: 100%;
  padding 24px 0;
  border-top: ${({isNotFirst}) => isNotFirst ? '1px solid #EEEEEE' : '0'};
`;

const ItemImage = styled.div`
  width: 136px;
  height: 136px;
  border-radius: 16px;
  background: ${({src}) => src ? 'url(' + src + ')' : ''};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 274px);
  margin: 0 18px;
`;

const ItemName = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #464646;
`;

const ModifierName = styled.p`
  margin: 4px 0 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #464646;
`;

const ItemWeight = styled.p`
  margin: 10px 0 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #A4A4A4;
`;

const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 112px;
  height: 36px;
  margin-top: auto;
`;

const CounterMinusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: #FAFAFA;
  border: 1px solid #F3F3F3;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
`;

const CounterPlusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: #FAFAFA;
  border: 1px solid #F3F3F3;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  opacity: ${({notMaxAmount}) => notMaxAmount ? '1' : '0.3'};
`;

const Counter = styled.p`
  width: 39px;
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #464646;
  text-align: center;
  user-select: none;
`;

const ItemPrice = styled.p`
  margin: 0 0 0 auto;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #2C2E32;
`;

const CartBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  padding: 32px 34px 32px 51px;
  background-color: #FFFFFF;
  border-radius: 32px;
`;

const BuyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  padding: 0 48px;
  background-color: ${MAIN_COLOR};
  border-radius: 16px;
  cursor: pointer;
`;

const BuyButtonText = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
  user-select: none;
`;

const CartBottomPrice = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #464646;
  user-select: none;
`;
