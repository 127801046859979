import * as _ from "underscore";

export const TEMPORARY_VALUE = "TEMPORARY_VALUE";
export const AUTH_MODAL_TOGGLE = "AUTH_MODAL_TOGGLE";
export const CITY_MODAL_TOGGLE = "CITY_MODAL_TOGGLE";
export const PROFILE_MODAL_TOGGLE = "PROFILE_MODAL_TOGGLE";
export const INFO_VIEW_TOGGLE = "INFO_VIEW_TOGGLE";


export const authModalToggle = () => ({
    type: AUTH_MODAL_TOGGLE
});

export const cityModalToggle = () => ({
    type: CITY_MODAL_TOGGLE
});

export const profileModalToggle = () => ({
    type: PROFILE_MODAL_TOGGLE
});

export const infoViewToggle = (bool = null) => (dispatch, getState) => {
    let result = getState().modal.viewInfoPerformance;
    let cookieWarnedAddCompany = window.getCookie("warnedAddCompany");
    let companies = getState().profile.companies;

    if (localStorage.getItem('token') !== null && localStorage.getItem('deviceId') !== null) {
        if (bool === null) {
            if (!cookieWarnedAddCompany && _.size(companies) === 0) {
                result = true;
                document.cookie = "warnedAddCompany = Y";
            } else if (cookieWarnedAddCompany && _.size(companies) === 0) {
                result = false;
            } else if (!cookieWarnedAddCompany && _.size(companies) !== 0) {
                result = false;
            }
        } else if (bool !== null) {
            result = bool;
        }
    }

    dispatch({
        type: INFO_VIEW_TOGGLE,
        payload: result
    })
};

export const deliveryCycleInfoMessage = (actionUser, messText = null, timeOut = 2500, isHaveOrder = false) => async (dispatch, getState) => {
    const mathRandomNumber = Math.floor(Math.random()*10000);
    let state = getState().modal.temporaryStorageFacility;
    let objectTemporaryInfo = {};
    let newTemporaryState = [];
    let isMobile = document.documentElement.clientWidth <= 992;

    switch(actionUser){
        case 'welcome': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Добро пожаловать!', color: 'rgba(0,148,0,0.65)'};
            break;
        case 'enter_email': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Выполнен вход по e-mail'};
            break;
        case 'enter_phone': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Выполнен вход по номеру телефона'};
            break;
        case 'exit': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Вы вышли из аккаунта'};
            break;
        case 'save': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Сохранено'};
            break;
        case 'save_address': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Вы сменили адрес на', messText: messText};
            break;
        case 'add': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: isMobile ? `Добавлено в корзину` : `Добавлено`, messText: messText};
            break;
        case 'send': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Отправлено'};
            break;
        case 'remove': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: isMobile ? `Удалено из корзины` : 'Удалено', messText: messText};
            break;
        case 'create_order': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Заказ создан'};
            break;
        case 'remove_order': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Заказ удалён'};
            break;
        case 'edit_order': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Заказ изменён'};
            break;
        case 'error_save_order': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Заказ не создан', messText: messText, color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'error_limit_save_order': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Ваш заказ в одном из ресторанов меньше заданного лимита', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'save_info': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Информация сохранена'};
            break;
        case 'send_invite': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Приглашение отправлено'};
            break;
        case 'remove_invite': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Приглашение аннулировано'};
            break;
        case 'remove_invite_from_user': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Приглашение отклонено'};
            break;
        case 'reject_invite': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Приглашение отклонено'};
            break;
        case 'accept_invite': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Приглашение принято'};
            break;
        case 'manager_out_user': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Пользователь исключен'};
            break;
        case 'you_left_the_company': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Вы вышли из компании'};
            break;
        case 'remove_from_card': objectTemporaryInfo = {actionId: mathRandomNumber, messText: 'Карта удалена'};
            break;
        case 'error_active_company': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: messText, color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'send_code_error': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Системе не удалось выполнить отправку смс. Повторите позже', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'email_not_found': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'E-mail не найден', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'cas603_error_email': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Доступ запрещен. Проверка безопасности не прошла успешно.', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'rs200_activation_mail': objectTemporaryInfo = {actionId: mathRandomNumber,  messTitle: 'Успешно!', messText: 'На вашу почту отправлено письмо', color: 'rgba(0,148,0,0.65)'};
            break;
        case 'error_activation_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Попробуйте позже', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'rs201_activation_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Неверный формат Email', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'rs202_activation_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Превышен лимит регистраций для email', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'rs203_activation_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Аккаунт существует или уже активирован', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'rs400_reg_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Успешно', messText: 'Активация аккаунта выполнена', color: 'rgba(0,148,0,0.65)'};
            break;
        case 'error_reg_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Попробуйте позже', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'rs401_reg_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Неверный формат Email', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'rs402_reg_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Неверный формат пароля', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'rs403_reg_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Аккаунт существует или уже активирован', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'ad_1101_remove_company': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'У Вас имеются активные заказы в этой компании', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'not_date_from': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Выберите начало периода', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'not_date_to': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Выберите конец периода', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'not_valid_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Неправильный формат email-адреса', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'not_data_in_period': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Данные за выбранный период не найдены', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'send_details_to_mail': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Успешно', messText: 'Детализация сформирована и отправлена Вам на почту', color: 'rgba(0,148,0,0.65)'};
            break;
        case 'access_denied': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'У вас нет прав на выполнение этой операции', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'error_operation': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'В данный момент эта операция Вам недоступна. Попробуйте позже', color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'you_have_an_order': objectTemporaryInfo = {actionId: mathRandomNumber, isHaveOrder: isHaveOrder};
            break;
        case 'promo_message': objectTemporaryInfo = {actionId: mathRandomNumber, messText: messText};
            break;
        case 'promo_message_error': objectTemporaryInfo = {actionId: mathRandomNumber, messText: messText, color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'check_error': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка проверки заказа', messText: messText, color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'location_error': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка сохранения адреса', messText: messText, color: 'rgba(255, 0, 0, 0.65)'};
            break;
        case 'unpublished_period': objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Меню не опубликовано', messText: messText};
            break;
        default: objectTemporaryInfo = {actionId: mathRandomNumber, messTitle: 'Ошибка', messText: 'Попробуйте позже', color: 'rgba(255, 0, 0, 0.65)'};
    }

    if(_.size(getState().modal.temporaryStorageFacility) !== 0){
        newTemporaryState.push(objectTemporaryInfo, ...state);
    }else{
        newTemporaryState.push(objectTemporaryInfo);
    }

    dispatch({
        type: TEMPORARY_VALUE,
        payload: newTemporaryState
    });

    setTimeout(()=>{
        dispatch({
            type: TEMPORARY_VALUE,
            payload: _.filter(getState().modal.temporaryStorageFacility, (item)=>{return item.actionId !== mathRandomNumber})
        });
    }, timeOut)
};
