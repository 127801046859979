import React from 'react';
import styled from 'styled-components';

const Divider = ({ style, className, color, margin }) => {
    return (
        <StyledDivider
            style={style}
            className={className}
            color={color}
            margin={margin}
        />
    );
};

export default Divider;

const StyledDivider = styled.hr`
    border-color: ${props => props.color || '#dddddd'};
    margin: ${props => props.margin || '32px 0'};
`
