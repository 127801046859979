import React from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

const Input = ({ type='text', size, value, placeholder, mask, onChange, ...props }) => {
  if (type === 'phone') {
    return (
      <StyledInput
        as={InputMask}
        mask="+7 (999) 999-9999"
        size={size}
        value={value}
        onChange={(e) => onChange(e.target.value.replace(/[\(\)_\s\-]/g, ''))}
        placeholder={placeholder}
        {...props}
      />
    );
  }

  return (
    <StyledInput
      type={type}
      size={size}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default Input;

const StyledInput = styled(InputMask)`
  display: flex;
  width: 100%;
  min-width: 250px;
  max-width: 400px;
  height: ${({ size }) => (size === 'small' ? '50px' : '60px')};
  padding: 0 24px;
  background: #FBFBFB;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 500;
  outline: none;
  transition: border .2s ease;

  &:hover {
    border: 1px solid #AAA8A8;
  }

  &:focus {
    border: 1px solid #4CC76E;
  }
`;
