import React, {useState, useEffect, useRef} from "react"
import Modal from "react-modal"
import styled from "styled-components"
import plural from "plural-ru"
import * as _ from "underscore"
import * as moment from 'moment'
import {useDispatch, useSelector} from 'react-redux';
import {clearCheck, clearOrder, getCheck, newSaveOrder, clearPayUrl} from "../../../actions/MenuActions"
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions";
import {MAIN_COLOR} from "../../../utils/constants"
import {request} from "../../../utils/request";

import selectedImg from "../../../images/check/tick_white.svg"
import addressImg from "../../../images/check/address_icon.png"
import courierImg from "../../../images/check/courier_icon.png"
import infoImg from "../../../images/check/paysystem_info_icon.svg"
import arrowDown from "../../../images/arrow_down.svg";
import feeInfoImg from "../../../images/check/fee_info.png";
import closeImg from "../../../images/close-nav-cross.svg";
import closeModal from "../../../images/close-nav-cross.svg";

Modal.setAppElement("#root")

const GetCheckModal = (props) => {
    const {periods} = props;

    const [openGetCheckModal, setOpenGetCheckModal] = useState(false)
    const [openOrderModal, setOpenOrderModal] = useState(false)
    const [openPointsModal, setOpenPointsModal] = useState(false)
    const [promoCode, setPromoCode] = useState('')
    const [selectedPaySystem, setSelectedPaySystem] = useState('')
    const [selectedWallet, setSelectedWallet] = useState('')
    const [selectedWalletObj, setSelectedWalletObj] = useState(null);
    const [selectedCard, setSelectedCard] = useState('')
    const [bonusPoints, setBonusPoints] = useState('')
    const [openProducts, setOpenProducts] = useState(false)
    const [payStatus, setPayStatus] = useState(null)
    const [info, setInfo] = useState('')
    const isClickedSubmitButton = useRef(false)
    const test1 = useRef()

    const checkData = useSelector(store => store.menu.checkData)
    const checkLoaded = useSelector(store => store.menu.checkLoaded)
    const createdOrder = useSelector(store => store.menu.createdOrder)
    const payUrl = useSelector(store => store.menu.payUrl)
    const profile = useSelector(store => store.profile.profile)
    const dispatch = useDispatch()

    useEffect(() => {
        if (checkLoaded) {
            setOpenGetCheckModal(true)
        }
    }, [checkLoaded])

    useEffect(() => {
        if (createdOrder && !(+selectedPaySystem === 16 || +selectedPaySystem === 2)) {
            closeGetCheckModal()
            setOpenOrderModal(true)
        }
    }, [createdOrder])

    useEffect(() => {
        if (payStatus) {
            dispatch(clearPayUrl())

            if (payStatus === 'success') {
                closeGetCheckModal()
            }
            setOpenOrderModal(true)
        }
    }, [payStatus])

    useEffect(() => {
        if (!openGetCheckModal) {
            dispatch(clearCheck())
        }
    }, [openGetCheckModal])

    useEffect(() => {
        if (!openOrderModal) {
            dispatch(clearOrder())
        }
    }, [openOrderModal])

    useEffect(() => {
        if (selectedWallet && !checkData.promocode.result) {
            setOpenPointsModal(true)
        }
    }, [selectedWallet])

    useEffect(() => {
        // if (checkData.wallet && bonusPoints === '') {
        //     setBonusPoints(checkData.wallet.limitAmountOnOrder + '')
        // }
        if (checkData.promocode && checkData.promocode.result && checkData.wallet) {
            setSelectedWallet(checkData.wallet.id)
            setSelectedWalletObj(checkData.wallet)
        }
    }, [checkData])

    useEffect(()=>{
        resize();
    }, []);

    useEffect(()=>{
        console.log(props, 'props.periods')
    }, [props]);

    useEffect(() => {
        console.log(selectedWalletObj, 'selectedWalletObj')
        if (selectedWalletObj && bonusPoints === '') {
            setBonusPoints(selectedWalletObj.limitAmountOnOrder + '')
        }
    }, [selectedWalletObj])

    useEffect(() => {
        console.log(bonusPoints, 'bonusPoints')
    }, [bonusPoints])

    const resize = () => {
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }

    const afterOpen = () => {
        const promoInput = document.getElementById('promo_input')
        promoInput.addEventListener('focusout', () => clearPromo())
    }

    const afterOpen2 = () => {

    }

    const closeGetCheckModal = e => {
        if (e) {
            e.stopPropagation()
        }

        const promoInput = document.getElementById('promo_input')
        promoInput && promoInput.removeEventListener('focusout', () => clearPromo())

        setPromoCode('')
        setSelectedPaySystem('')
        setSelectedWallet('')
        setSelectedWalletObj(null)
        setBonusPoints('')
        setOpenGetCheckModal(false)
    }

    const closeGetCheckModal2 = e => {
        if (e) {
            e.stopPropagation();
        }

        setOpenOrderModal(false)
    }

    const closePointsModal = e => {
        if (e) {
            e.stopPropagation()
        }

        setOpenPointsModal(false)
        setSelectedWallet('')
        setSelectedWalletObj(null)
        setBonusPoints('')
        check(promoCode, selectedPaySystem, '')
    }

    const clearPromo = () => {
        setTimeout(() => {
            if (!isClickedSubmitButton.current) {
                setPromoCode('')
            } else {
                isClickedSubmitButton.current = false
            }
        }, 100)
    }

    const check = (promo, paysys, walletId) => {
        let paysystem = null
        if (checkData.paysystems && checkData.paysystems.length) {
            paysystem = _.find(checkData.paysystems, paysystem => {
                return paysystem.id === paysys
            })
        }

        let wallet = null

        if (walletId) {
            if (checkData.wallet && checkData.wallet.id === walletId) {
                wallet = checkData.wallet
                if (+bonusPoints > 0) {
                    wallet.payAmount = +bonusPoints
                }
            } else {
                if (checkData.wallets && checkData.wallets.length) {
                    wallet = _.find(checkData.wallets, wlt => {
                        return wlt.id === walletId
                    })
                    if (+bonusPoints > 0) {
                        wallet.payAmount = +bonusPoints
                    }
                }
            }
        }

        dispatch(getCheck(
            checkData.date.dateString,
            checkData.owner.id,
            checkData.company.id,
            checkData.period.id,
            checkData.products,
            promo,
            paysystem,
            wallet
        ))
    }

    const onChangePoints = (e, maxPoints) => {
        let regexp = [0 - 9];
        let value = e.target.value;
        value = value.replace(regexp, '').substring(0, (+maxPoints + '').length);
        console.log(value, 'valuevaluevalue')

        if (value === '') {
            setBonusPoints(value)
        } else {
            if (value <= 0) {
                setBonusPoints('1')
            } else if (value >= +maxPoints) {
                setBonusPoints(maxPoints + '')
            } else {
                setBonusPoints(value + '')
            }
        }
    }

    const formatPeriod = (currentPeriod) => {
        let periodArr = []
        if (props.periods) {
            if (props.periods.length > 0) {
                periodArr = _.find(props.periods, period => {
                    return period[0] === currentPeriod
                })
            }
        }
        return 'c ' + periodArr[1] + ' по ' + periodArr[2]
    }

    const saveOrder = () => {
        let wallet = null

        if (selectedWallet) {
            if (checkData.wallet) {
                wallet = checkData.wallet
                if (+bonusPoints > 0) {
                    wallet.payAmount = +bonusPoints
                }
            } else {
                if (checkData.wallets && checkData.wallets.length) {
                    wallet = _.find(checkData.wallets, wlt => {
                        return wlt.id === selectedWallet
                    })
                }
            }
        }

        dispatch(newSaveOrder(
            selectedCard !== 'new_card' ? selectedCard : null,
            checkData.company.id,
            checkData.date.dateString,
            checkData.owner.id,
            selectedPaySystem,
            checkData.period.id,
            checkData.products,
            null, // shortchange
            wallet,
            profile.phone,
        ))
    }

    const parseUrl = (url) => {
        // извлекаем строку из URL
        let queryString = url.split('?')[1]

        // объект для хранения параметров
        let obj = {}

        // если есть строка запроса
        if (queryString) {

            // данные после знака # будут опущены
            queryString = queryString.split('#')[0]

            // разделяем параметры
            let arr = queryString.split('&');

            for (let i = 0; i < arr.length; i++) {
                // разделяем параметр на ключ => значение
                let a = arr[i].split('=')

                // обработка данных вида: list[]=thing1&list[]=thing2
                let paramNum = undefined
                let paramName = a[0].replace(/\[\d*\]/, v => {
                    paramNum = v.slice(1, -1)
                    return ''
                })

                // передача значения параметра ('true' если значение не задано)
                let paramValue = typeof a[1] === 'undefined' ? true : a[1]

                // преобразование регистра
                paramName = paramName.toLowerCase()
                if (typeof paramValue === 'string') {
                    paramValue = paramValue.toLowerCase()
                }

                // если ключ параметра уже задан
                if (obj[paramName]) {
                    // преобразуем текущее значение в массив
                    if (typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]]
                    }
                    // если не задан индекс...
                    if (typeof paramNum === 'undefined') {
                        // помещаем значение в конец массива
                        obj[paramName].push(paramValue)
                    } else { // если индекс задан...
                        // размещаем элемент по заданному индексу
                        obj[paramName][paramNum] = paramValue
                    }
                } else {
                    obj[paramName] = paramValue // если параметр не задан, делаем это вручную
                }
            }
        }

        console.log(obj, "URL_OBJECT")
        return obj
    }

    const removeCard = async (cardId, restId, paysystemId) => {
        let response = await request("PaySystemService", "DeleteCard", {
            cardId,
            account: restId,
            paysystem: {id: paysystemId}
        })

        if (response.result === 1) {
            dispatch(deliveryCycleInfoMessage('remove_from_card'));
            check(promoCode, selectedPaySystem, selectedWallet)
        }

        if (selectedCard === cardId) {
            setSelectedCard('')
        }
    }

    return (
        <>
            <Modal
                isOpen={openOrderModal}
                closeTimeoutMS={100}
                style={props.isMobile ? customStylesMobile : customStyles}
                overlayClassName={"Modifiers__Overlay"}
                onAfterOpen={() => afterOpen2()}
                onRequestClose={e => closeGetCheckModal2(e)}
            >
                {createdOrder ?
                    !payStatus || payStatus === 'success' ? (
                        <ModalWrapper
                            onClick={e => e.stopPropagation()}
                            style={{
                                overflowY: 'auto',
                                maxHeight: '100vh',
                            }}
                        >
                            <CheckTop style={{paddingBottom: 30}}>
                                <ModalTitle>
                                    Заказ №{createdOrder.order.id} на {+createdOrder.priceResult} ₽ оформлен
                                </ModalTitle>
                                <OwnerTitle>
                                    <img src={createdOrder.owner.logo} alt="✔" width={40} height={40}/>
                                    <OwnerName>{createdOrder.owner.name}</OwnerName>
                                    <OpenProductsButton onClick={() => {
                                        setOpenProducts(prev => !prev)
                                    }}>
                                        <Arrow opened={openProducts} src={arrowDown} alt="" width={16} height={9}/>
                                    </OpenProductsButton>
                                </OwnerTitle>
                                {openProducts ? (
                                    <Products>
                                        {createdOrder.products.map((item, index) => {
                                            return (
                                                <CreatedOrderItem>
                                                    <Product key={item.id + index}>
                                                        <ProductName>{item.name}</ProductName>
                                                        <ProductPrice>
                                                            <span style={{color: '#999'}}>{item.amount} x </span>{+item.price + (item.modifiers ? +item.modifiers.reduce((acc, el) => acc += +el.price, 0) : 0)} ₽
                                                        </ProductPrice>
                                                    </Product>
                                                    <Modifier>
                                                        {item.modifiers && item.modifiers.map(el => {
                                                            return <span>{el.name}</span>
                                                        })}
                                                    </Modifier>
                                                </CreatedOrderItem>
                                            )
                                        })}
                                        <BlockTitle>Способ оплаты</BlockTitle>
                                        <TimeText>
                                            {+createdOrder.paysystem.id === 1 ? 'Олпачено наличными'
                                                : +createdOrder.paysystem.id === 2 || +createdOrder.paysystem.id === 16 ? 'Оплачено онлайн'
                                                    : +createdOrder.paysystem.id === 32 ? 'Оплачено баллами' : 'Оплачено'}
                                        </TimeText>
                                    </Products>
                                ) : null}
                                <></>
                                <Hr/>
                                <Time style={{marginBottom: 30}}>
                                    <img src={courierImg} alt="" width={40} height={40}/>
                                    <TimeInfo>
                                        <TimeText>
                                            {moment(createdOrder.date.dateString).format("D MMMM, dddd")}
                                        </TimeText>
                                        <TimeText>
                                            {formatPeriod(createdOrder.period.id)}
                                        </TimeText>
                                        <TimeText>
                                            {!createdOrder.prices.delivery ? (
                                                'Доставка бесплатно'
                                            ) : (
                                                'Доставка ' + createdOrder.prices.delivery + '₽'
                                            )}
                                        </TimeText>
                                    </TimeInfo>
                                </Time>
                                <Hr/>
                                <OrderButton active onClick={() => {
                                    closeGetCheckModal2()
                                }}>
                                    <OrderButtonText>На главную</OrderButtonText>
                                </OrderButton>
                            </CheckTop>
                        </ModalWrapper>
                    ) : (
                        <ModalWrapper onClick={e => e.stopPropagation()}>
                            <CheckTop style={{paddingBottom: 30}}>
                                <ModalTitle>
                                    Ошибка оформления заказа
                                </ModalTitle>
                                <OwnerTitle>
                                    <OwnerName style={{maxWidth: '100%'}}>Заказ не оплачен</OwnerName>
                                </OwnerTitle>
                                <OrderButton active onClick={() => {
                                    closeGetCheckModal2()
                                }}>
                                    <OrderButtonText>Вернуться</OrderButtonText>
                                </OrderButton>
                            </CheckTop>
                        </ModalWrapper>
                    )
                : null}
            </Modal>
            <Modal
                isOpen={openGetCheckModal}
                closeTimeoutMS={100}
                style={props.isMobile ? customStylesMobile : customStyles}
                overlayClassName={"Modifiers__Overlay"}
                onAfterOpen={() => afterOpen()}
                onRequestClose={e => closeGetCheckModal(e)}
            >
                <ModalWrapper
                    onClick={e => e.stopPropagation()}
                    style={{
                        overflowY: 'auto',
                        maxHeight: '100vh',
                        height: props.isMobile ? 'calc(var(--vh, 1vh) * 100)' : 'auto',
                        position: 'relative',
                    }}
                >
                    {/*isMOBILE!!!!!*/}
                    <>
                        <CheckTop>
                            <img
                                src={closeModal}
                                onClick={e => closeGetCheckModal(e)}
                                style={{position: 'absolute', top: 18, right: 18, cursor: 'pointer'}}
                                alt="x"
                            />
                            <ModalTitle>Оформление заказа</ModalTitle>
                            {checkData && !checkData.wallet ? (
                                <BlockWrapper>
                                    <BlockTitle>Промокод</BlockTitle>
                                    <PromoLine>
                                        <PromoInput
                                            placeholder="Промокод"
                                            value={promoCode}
                                            type="text"
                                            onChange={e => setPromoCode(e.target.value)}
                                            id="promo_input"
                                        />
                                        <UsePromo onClick={() => {
                                            isClickedSubmitButton.current = true
                                            check(promoCode, selectedPaySystem, selectedWallet)
                                        }}>
                                            Применить
                                        </UsePromo>
                                    </PromoLine>
                                </BlockWrapper>
                            ) : null}
                            {checkData.wallets && checkData.wallets.length && checkData.promocode && !checkData.promocode.result ? (
                                <BlockWrapper>
                                    <BlockTitle>Баллы</BlockTitle>
                                    {checkData.wallets.map(wallet => {
                                        return (
                                            <Wallet
                                                key={'wallet_' + wallet.id}
                                                onClick={() => {
                                                    setBonusPoints('')
                                                    if (selectedWallet !== wallet.id) {
                                                        setSelectedWallet(wallet.id)
                                                        setSelectedWalletObj(wallet)
                                                        setPromoCode('')
                                                        //check('', selectedPaySystem, wallet.id)
                                                    } else {
                                                        setSelectedWallet('')
                                                        setSelectedWalletObj(null)
                                                        check(promoCode, selectedPaySystem, '')
                                                    }
                                                }}
                                            >
                                                {selectedWallet === wallet.id ? (
                                                    <WalletRadio active>
                                                        <img src={selectedImg} alt="✔" width={10} height={10}/>
                                                    </WalletRadio>
                                                ) : (
                                                    <WalletRadio/>
                                                )}
                                                <WalletName>{wallet.name}</WalletName>
                                                <Points>
                                                    <PointsCount>{+wallet.amount}</PointsCount>
                                                    <PointsIcon>
                                                        <PointsIconText>Б</PointsIconText>
                                                    </PointsIcon>
                                                </Points>
                                            </Wallet>
                                        )
                                    })}
                                </BlockWrapper>
                            ) : null}
                            {(checkData.promocode && checkData.promocode.result && checkData.wallet) ? (
                                <BlockWrapper>
                                    <BlockTitle>Скидка по промокоду</BlockTitle>
                                    <Wallet onClick={() => {
                                        if (selectedWallet !== checkData.wallet.id) {
                                            setSelectedWallet(checkData.wallet.id)
                                            setSelectedWalletObj(checkData.wallet)
                                            check(promoCode, selectedPaySystem, checkData.wallet.id)
                                        } else {
                                            setSelectedWallet('')
                                            setSelectedWalletObj(null)
                                            setPromoCode('')
                                            setBonusPoints('')
                                            check('', selectedPaySystem, '')
                                        }
                                    }}>
                                        {selectedWallet === checkData.wallet.id ? (
                                            <WalletRadio active>
                                                <img src={selectedImg} alt="✔" width={10} height={10}/>
                                            </WalletRadio>
                                        ) : (
                                            <WalletRadio/>
                                        )}
                                        <WalletName>{checkData.wallet.name}</WalletName>
                                        <Points>
                                            <PointsCount>{+checkData.wallet.amount}</PointsCount>
                                            <PointsIcon>
                                                <PointsIconText>Б</PointsIconText>
                                            </PointsIcon>
                                        </Points>
                                    </Wallet>
                                </BlockWrapper>
                            ) : null}
                            <BlockWrapper>
                                <BlockTitle>Рубли</BlockTitle>
                                {checkData.paysystemsComment ? (
                                    <PaysystemsComment>
                                        <img src={infoImg} alt="i" width={16} height={16}/>
                                        <PaysystemsCommentText>{checkData.paysystemsComment}</PaysystemsCommentText>
                                    </PaysystemsComment>
                                ) : null}
                                <PaySystems>
                                    {checkData.paysystems && checkData.paysystems.map(paysystem => {
                                        if (paysystem.isDisabled && selectedPaySystem === paysystem.id) {
                                            setSelectedPaySystem('')
                                        }
                                        return (
                                            <PaySystem
                                                key={'paysystem_' + paysystem.id}
                                                disabled={paysystem.isDisabled}
                                                onClick={() => {
                                                    if (!paysystem.isDisabled) {
                                                        if (checkData.promocode && !checkData.promocode.code) {
                                                            setSelectedPaySystem(paysystem.id)
                                                            setPromoCode('')
                                                            //check('', paysystem.id, selectedWallet)
                                                        }
                                                        if (checkData.promocode && checkData.promocode.code) {
                                                            setSelectedPaySystem(paysystem.id)
                                                            //check(promoCode, paysystem.id, selectedWallet)
                                                        }
                                                    }
                                                }}
                                            >
                                                <PaySystemRadio active={selectedPaySystem === paysystem.id}>
                                                    {selectedPaySystem === paysystem.id && (
                                                        <img src={selectedImg} alt="✔" width={10} height={10}/>
                                                    )}
                                                </PaySystemRadio>
                                                <PaySystemName>
                                                    {+paysystem.id === 1 ? 'Наличными' : 'Картой онлайн'}
                                                </PaySystemName>
                                            </PaySystem>
                                        )
                                    })}
                                </PaySystems>
                                {selectedPaySystem && +selectedPaySystem !== 1 ? (
                                    <Cards>
                                        {(checkData.cards && checkData.cards.length > 0) ? checkData.cards.map(card => {
                                            return (
                                                <CardWrapper key={card.id}>
                                                    <Card onClick={() => {
                                                        setSelectedCard(card.id)
                                                    }}>
                                                        <CardRadio active={selectedCard === card.id}>
                                                            {selectedCard === card.id ? (
                                                                <CardRadioInner/>
                                                            ) : null}
                                                        </CardRadio>
                                                        <CardName>{card.name}</CardName>
                                                    </Card>
                                                    <RemoveCardButton onClick={() => removeCard(card.id, checkData.owner.id, card.paysystemId)}>
                                                        <img src={closeImg} width={12} alt="x" />
                                                    </RemoveCardButton>
                                                </CardWrapper>
                                            )
                                        }) : null}
                                        <Card key={'new_card'} onClick={() => {
                                            setSelectedCard('new_card')
                                        }}>
                                            <CardRadio active={selectedCard === 'new_card'}>
                                                {selectedCard === 'new_card' ? (
                                                    <CardRadioInner/>
                                                ) : null}
                                            </CardRadio>
                                            <CardName style={{letterSpacing: 'normal'}}>Новая карта</CardName>
                                        </Card>
                                    </Cards>
                                ) : null}
                            </BlockWrapper>
                            {checkData.company && checkData.company.address ? (
                                <BlockWrapper>
                                    <BlockTitle>Адрес доставки</BlockTitle>
                                    <Address>
                                        <img src={addressImg} alt="" width={40} height={40}/>
                                        <AddressInfo>
                                            <AddressText>
                                                {
                                                    checkData.company.address.street + ' ' +
                                                    checkData.company.address.house +
                                                    (checkData.company.address.structure ? (', к.' + checkData.company.address.structure) : '') +
                                                    (checkData.company.address.floor ? (', этаж ' + checkData.company.address.floor) : '') +
                                                    (checkData.company.address.flat ? (', к.' + checkData.company.address.flat) : '')
                                                }
                                            </AddressText>
                                            <AddressText>{checkData.company.name}</AddressText>
                                        </AddressInfo>
                                    </Address>
                                </BlockWrapper>
                            ) : null}
                            {checkData.date && checkData.date.dateString && checkData.period && checkData.period.id ? (
                                <TimeWrapper>
                                    <BlockTitle>Дата и время доставки</BlockTitle>
                                    <Time>
                                        <img src={courierImg} alt="" width={40} height={40}/>
                                        <TimeInfo>
                                            <TimeText>
                                                {moment(checkData.date.dateString).format("D MMMM, dddd")}
                                            </TimeText>
                                            <TimeText>
                                                {formatPeriod(checkData.period.id)}
                                            </TimeText>
                                        </TimeInfo>
                                    </Time>
                                </TimeWrapper>
                            ) : null}
                            {checkData.prices ? (
                                <>
                                    {checkData.products && checkData.prices.cart ? (
                                        <DeliveryWrapper>
                                            <TitleWrapper>
                                                <BlockTitle style={{marginBottom: 0}}>
                                                    {`${plural(checkData.products.length, '%d товар', '%d товара', '%d товаров')}`}
                                                </BlockTitle>
                                                <BlockTitle style={{marginBottom: 0}}>
                                                    {+checkData.prices.cart + ' ₽'}
                                                </BlockTitle>
                                            </TitleWrapper>
                                        </DeliveryWrapper>
                                    ) : null}
                                    <DeliveryWrapper>
                                        <TitleWrapper>
                                            <FeeBlockTitle>
                                                <BlockTitle style={{marginBottom: 0, marginRight: 8}}>
                                                    Доставка
                                                </BlockTitle>
                                                {checkData.prices.isPayedDelivery && +checkData.prices.delivery > 0 ? (
                                                    <img src={feeInfoImg} alt="" width={16} height={16} onClick={() => {
                                                        setInfo('delivery')
                                                    }}/>
                                                ) : null}
                                            </FeeBlockTitle>
                                            <BlockTitle
                                                style={{
                                                    marginBottom: 0,
                                                    color: (checkData.prices.isPayedDelivery || +checkData.prices.delivery === 0)
                                                        ? '#A4A4A4'
                                                        : '#161616',
                                                    textDecoration: (checkData.prices.isPayedDelivery && +checkData.prices.delivery > 0)
                                                        ? 'line-through'
                                                        : 'none',
                                                }}
                                            >
                                                {+checkData.prices.delivery} ₽
                                            </BlockTitle>
                                        </TitleWrapper>
                                    </DeliveryWrapper>
                                    <DeliveryWrapper>
                                        <TitleWrapper>
                                            <FeeBlockTitle>
                                                <BlockTitle style={{marginBottom: 0, marginRight: 8}}>
                                                    Сервисный сбор
                                                </BlockTitle>
                                                <img src={feeInfoImg} alt="" width={16} height={16} onClick={() => {
                                                    setInfo('service')
                                                }}/>
                                            </FeeBlockTitle>
                                            <BlockTitle
                                                style={{
                                                    marginBottom: 0,
                                                    color: (checkData.prices.isPayedServiceFee || +checkData.prices.serviceFee === 0)
                                                        ? '#A4A4A4'
                                                        : '#161616',
                                                    textDecoration: (checkData.prices.isPayedServiceFee && +checkData.prices.serviceFee > 0)
                                                        ? 'line-through'
                                                        : 'none',
                                                }}
                                            >
                                                {+checkData.prices.serviceFee + ' ₽'}
                                            </BlockTitle>
                                        </TitleWrapper>
                                    </DeliveryWrapper>
                                    {checkData.prices.wallet && !openPointsModal ? (
                                        <DeliveryWrapper>
                                            {checkData.promocode && checkData.promocode.result === 1 ? (
                                                <TitleWrapper>
                                                    <BlockTitle style={{marginBottom: 0}}>
                                                        Скидка по промокоду
                                                    </BlockTitle>
                                                    <BlockTitle style={{marginBottom: 0}}>
                                                        -{+checkData.prices.wallet} ₽
                                                    </BlockTitle>
                                                </TitleWrapper>
                                            ) : (
                                                <TitleWrapper>
                                                    <BlockTitle style={{marginBottom: 0}}>
                                                        Баллы
                                                    </BlockTitle>
                                                    <Points>
                                                        <BlockTitle style={{marginBottom: 0}}>
                                                            -{+checkData.prices.wallet}
                                                        </BlockTitle>
                                                        <PointsIcon style={{marginLeft: 6}}>
                                                            <PointsIconText>Б</PointsIconText>
                                                        </PointsIcon>
                                                    </Points>
                                                </TitleWrapper>
                                            )}
                                        </DeliveryWrapper>
                                    ) : null}
                                </>
                            ) : null}
                        </CheckTop>
                        <CheckBottom>
                            {checkData.prices ? (
                                <Total>
                                    <BlockTitle style={{marginBottom: 0}}>
                                        Итого:
                                    </BlockTitle>
                                    {(checkData.prices.total && +checkData.prices.total !== +checkData.prices.pay) ? (
                                        <Points>
                                            <BlockTitle
                                                style={{
                                                    marginBottom: 0,
                                                    marginRight: 10,
                                                    color: '#A4A4A4',
                                                    textDecoration: 'line-through',
                                                }}
                                            >
                                                {+checkData.prices.total} ₽
                                            </BlockTitle>
                                            <BlockTitle style={{marginBottom: 0}}>
                                                {+checkData.prices.pay} ₽
                                            </BlockTitle>
                                        </Points>
                                    ) : (
                                        <BlockTitle style={{marginBottom: 0}}>
                                            {+checkData.prices.pay} ₽
                                        </BlockTitle>
                                    )}
                                </Total>
                            ) : null}
                            {checkData.prices && checkData.prices.pay ? (
                                <OrderButton
                                    active={(+selectedPaySystem === 2 || +selectedPaySystem === 16) && selectedCard || +selectedPaySystem === 1}
                                    onClick={() => {
                                        if (selectedPaySystem) {
                                            if ((+selectedPaySystem === 2 || +selectedPaySystem === 16) && selectedCard || +selectedPaySystem === 1) {
                                                saveOrder()
                                            }
                                        }
                                    }}>
                                    <OrderButtonText>
                                        {
                                            (+selectedPaySystem === 2 || +selectedPaySystem === 16)
                                                ? 'Оплатить онлайн ' + checkData.prices.pay + ' ₽'
                                                : 'Отправить заказ на ' + checkData.prices.pay + ' ₽'
                                        }
                                    </OrderButtonText>
                                </OrderButton>
                            ) : (
                                <OrderButton
                                    active={checkData.prices && !checkData.prices.pay}
                                    onClick={() => {
                                        if (checkData.prices && !checkData.prices.pay) {
                                            saveOrder()
                                        }
                                    }}>
                                    <OrderButtonText>{'Оплатить заказ баллами'}</OrderButtonText>
                                </OrderButton>
                            )}
                            <PaymentProblemsButton>
                                <SberLink href="http://www.sberbank.ru/ru/certificates" target="_blank">
                                    Проблемы с оплатой онлайн?
                                </SberLink>
                            </PaymentProblemsButton>
                        </CheckBottom>
                        <Modal
                            isOpen={openPointsModal}
                            closeTimeoutMS={100}
                            style={props.isMobile ? customStylesMobilePoints : customStylesPoints}
                            overlayClassName={"Modifiers__Overlay"}
                            onRequestClose={e => closePointsModal(e)}
                        >
                            <ModalWrapper onClick={e => e.stopPropagation()}>
                                {selectedWalletObj ? (
                                    <PointsModal>
                                        <PointsModalTitle>
                                            {selectedWalletObj.name}
                                        </PointsModalTitle>
                                        <PointsModalAmount>
                                            <PointsCount style={{marginLeft: 0}}>
                                                {+selectedWalletObj.amount}
                                            </PointsCount>
                                            <PointsIcon>
                                                <PointsIconText>Б</PointsIconText>
                                            </PointsIcon>
                                        </PointsModalAmount>
                                        <PointsModalInput
                                            value={bonusPoints === '' ? bonusPoints : (+bonusPoints).toFixed(0)}
                                            type="number"
                                            onChange={e => onChangePoints(e, selectedWalletObj.limitAmountOnOrder)}
                                        />
                                        <PointsModalText>
                                            Вы можете использовать
                                            только {`${plural(+selectedWalletObj.limitAmountOnOrder, '%d балл', '%d балла', '%d баллов')}`} для
                                            оплаты заказа частично
                                        </PointsModalText>
                                        <OrderButton
                                            active={+bonusPoints > 0}
                                            onClick={() => {
                                                if (+bonusPoints > 0) {
                                                    setOpenPointsModal(false)
                                                    check(promoCode, selectedPaySystem, selectedWallet)
                                                }
                                            }}
                                        >
                                            <OrderButtonText>Применить</OrderButtonText>
                                        </OrderButton>
                                    </PointsModal>
                                ) : null}
                            </ModalWrapper>
                        </Modal>
                        <Modal
                            isOpen={info}
                            closeTimeoutMS={100}
                            style={props.isMobile ? customStylesMobilePoints : customStylesPoints}
                            overlayClassName={"Modifiers__Overlay"}
                            onRequestClose={e => {
                                e.stopPropagation()
                                setInfo('')
                            }}
                        >
                            <ModalWrapper onClick={e => e.stopPropagation()}>
                                <PointsModal>
                                    <PointsModalTitle style={{marginBottom: 20}}>
                                        {info === 'service' ? 'Сервисный сбор' : info === 'delivery' ? 'Доставка' : ''}
                                    </PointsModalTitle>
                                    {(info === 'service' && checkData.prices.isPayedServiceFee && +checkData.prices.serviceFee > 0) ? (
                                        <PointsModalText style={{marginBottom: 10}}>
                                            Вы уже совершали заказ сегодня, поэтому платить повторно за сервисный сбор не нужно
                                        </PointsModalText>
                                    ) : null}
                                    {info === 'service' ? (
                                        <PointsModalText style={{marginBottom: 0}}>
                                            Мы развиваемся и растём. Сервисный сбор помогает сделать сервис лучше для вас. Если вы делаете несколько заказов в день, то сервисный сбор добавляется только в ваш первый заказ
                                        </PointsModalText>
                                    ) : null}
                                    {info === 'delivery' ? (
                                        <PointsModalText style={{marginBottom: 0}}>
                                            Доставка уже оплачена
                                        </PointsModalText>
                                    ) : null}
                                </PointsModal>
                            </ModalWrapper>
                        </Modal>
                    </>
                </ModalWrapper>
            </Modal>

            <Modal
                closeTimeoutMS={100}
                isOpen={payUrl}
                style={props.isMobile ? customStylesPayMobile : customStylesPay}
                overlayClassName={"Modifiers__Overlay"}
                onRequestClose={(e) => dispatch(clearPayUrl())}>
                <ModalWrapper style={{height: '100vh', position: 'relative'}} onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <p onClick={(e) => dispatch(clearPayUrl())}
                       style={{position: 'absolute', top: 8, right: 20, cursor: 'pointer', fontSize: 20}}>x</p>
                    {payUrl ? (
                        <iframe ref={test1} id="payFrame" src={payUrl} frameBorder="0" width="100%" height="100%" onLoad={e => {
                            try {
                                const target = e.currentTarget.contentWindow.location.href
                                console.log(target, 'TARGET')
                                if (parseUrl(target).success) {
                                    setPayStatus('success')
                                } else {
                                    setPayStatus('fail')
                                }
                            } catch (e) {
                                console.log(e, 'ERROR')
                            }
                        }}/>
                    ) : null}
                </ModalWrapper>
            </Modal>
        </>
    )
}

const customStyles = {
    content: {
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        margin: 'auto',
        //transform: "translate(-50%, -50%)",
        padding: "0px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "414px",

        display: 'flex',
        alignItems: 'center',
        overflow: 'visible',
    }
}

const customStylesMobile = {
    content: {
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        margin: 'auto',
        padding: "0px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "414px",

        display: 'flex',
        alignItems: 'center',
        overflow: 'visible',
        borderRadius: 0,
    }
}

const customStylesPay = {
    content: {
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        margin: 'auto',
        //transform: "translate(-50%, -50%)",
        padding: "0px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "90vw",
        maxWidth: "1920px",
    }
};

const customStylesPayMobile = {
    content: {
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        margin: 'auto',
        padding: "0px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100vw",
        maxWidth: "1920px",
    }
}

const customStylesPoints = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "0px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "360px",
    }
};

const customStylesMobilePoints = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "0px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
        maxWidth: "360px",
        maxHeight: "100vh",
    }
};

const ModalWrapper = styled.div`
	background: #fff;
	border-radius: 6px;
    overflow: hidden;
    width: 100%;
    
    @media (max-width: 992px) {
        border-radius: 0;
    }
`

const CheckTop = styled.div`
    width: 100%;
    transition: all .2s linear;
    padding: 40px 30px 0;
    overflow: hidden;
    position: relative;

    & p {
        font-family: "Roboto", sans-serif;
        color: #161616;
    }

    @media (max-width: 992px) {
        padding: 25px 37px;
    }
`

const ModalTitle = styled.p`
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 21px;
`

const BlockWrapper = styled.div`
    margin-bottom: 32px;
`

const BlockTitle = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
`

const PromoLine = styled.div`
    display: flex;
    align-items: center;
`

const PromoInput = styled.input`
    width: 200px;
    margin-right: 43px;
    padding: 14px 16px;
    background: #eeeeee;
    border: none;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #161616;
    border-radius: 6px;
    
    @media (max-width: 360px) {
        width: 140px;
    }
`

const UsePromo = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A18CD1 !important;
    margin: 0;
    cursor: pointer;
`

const Wallet = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const WalletRadio = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({active}) => active ? MAIN_COLOR : '#E6E9F0'};
    width: 22px;
    height: 22px;
    margin-right: 11px;
    border-radius: 6px;
`

const WalletName = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    max-width: calc(100% - 110px);
`

const Points = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`

const PointsCount = styled.p`
    font-size: 16px;
    line-height: 19px;
    margin: 0 6px 0;
`

const PointsIcon = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 666px;
    background: ${MAIN_COLOR};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5px;
`

const PointsIconText = styled.p`
    font-size: 10px;
    font-weight: 700;
    color: #ffffff !important;
    margin: 0;
`

const PointsModal = styled.div`
    width: 100%;
    transition: all .2s linear;
    padding: 22px 26px 26px;
    overflow: hidden;
    position: relative;

    & p {
        font-family: "Roboto", sans-serif;
        color: #161616;
    }

    @media (max-width: 992px) {
        padding: 25px 37px;
    }
`

const PointsModalTitle = styled(BlockTitle)`
    margin-bottom: 6px;
`

const PointsModalAmount = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 17px;
`

const PointsModalInput = styled.input`
    width: 100%;
    margin-bottom: 8px;
    padding: 10px 12px;
    background: #E6E9F0;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.05);
    border: none;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #161616;
    border-radius: 6px;
`

const PointsModalText = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 22px;
`

const OrderButton = styled.div`
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: ${({active}) => active ? MAIN_COLOR : '#EEEEEE'};
    cursor: ${({active}) => !active ? 'not-allowed' : 'pointer'};

    & p {
        color: ${({active}) => active ? '#ffffff !important' : '#999999 !important'};
    }
`

const OrderButtonText = styled.p`
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
`

const PaySystems = styled.div`
    display: flex;
`

const PaySystem = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    opacity: ${({disabled}) => disabled ? '0.5' : '1'};
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
`

const PaySystemRadio = styled(WalletRadio)`
    border-radius: 66px;
`

const PaySystemName = styled(WalletName)`
    max-width: none;
`

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
`

const CardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
`

const Card = styled.div`
    display: inline-flex;
    /*display: flex;*/
    align-items: center;
    /*margin-bottom: 15px;*/
    cursor: pointer;
`

const RemoveCardButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
`

const CardRadio = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: ${({active}) => active ? '0' : '1px solid #CACACA'};
    background: ${({active}) => active ? '#67B8EE' : '#DADADA'};
`

const CardRadioInner = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #ffffff;
`

const CardName = styled.p`
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.12em;
    color: #2D2D2D;
    margin: 0 0 0 10px;
`

const Address = styled.div`
    display: flex;
`

const AddressInfo = styled.div`
    margin-left: 8px;
    max-width: calc(100% - 48px);
`

const AddressText = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;

    &:last-child {
        marin-bottom: 0;
    }
`

const TimeWrapper = styled.div`
    margin-bottom: 40px;
`

const Time = styled(Address)``

const TimeInfo = styled(AddressInfo)``

const TimeText = styled(AddressText)``

const DeliveryWrapper = styled.div`
    margin-bottom: 18px;
`

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const FeeBlockTitle = styled.div`
    display: flex;
    align-items: center;
`

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
`

const DeliveryInfo = styled.p`
   margin-bottom: 0;
   margin-left: 6px;
   font-weight: 400;
   font-size: 11px;
   line-height: 14px;
`

const CheckBottom = styled.div`
    width: 100%;
    padding: 25px 30px 32px;
    border-top: 2px solid #E6E9F0;
    
    background-color: #ffffff;
    position: sticky;
    bottom: 0;

    & p {
        font-family: "Roboto", sans-serif;
        color: #161616;
    }
`

const Total = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`

const TotalText = styled(AddressText)`
    margin-bottom: 0;
`

const PaysystemsComment = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`

const PaysystemsCommentText = styled.p`
    font-size: 13px;
    line-height: 15px;
    margin: 0 0 0 6px;
`

const OwnerTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`

const OwnerName = styled.p`
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 12px;
    max-width: calc(100% - 92px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Products = styled.div`
    margin-bottom: 30px;
`

const CreatedOrderItem = styled.div`
    margin-bottom: 15px;
    
    &:last-child{
		margin-bottom: 20px;
	}
`

const Product = styled.div`
    display: flex;
    justify-content: space-between;
`

const Modifier = styled.div`
    & > span {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        line-height: 18px;
        color: #161616;
        padding-left: 10px;
    }
`

const ProductName = styled.p`
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 0;
    max-width: calc(100% - 90px);
`

const ProductPrice = styled.p`
    font-size: 13px;
    line-height: 15px;
    margin: 2px 0 0 auto;
`

const OpenProductsButton = styled.div`
    width: 40px;
    height: 40px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const Arrow = styled.img`
    transform: ${({opened}) => opened ? 'rotate(0deg)' : 'rotate(90deg)'};
    transition: transform 0.5s;
`

const Hr = styled.div`
    width: 100%;
    height: 1px;
    background: #E6E9F0;
    margin-bottom: 30px;
`

const PaymentProblemsButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    margin: 16px auto 0;
`

const SberLink = styled.a`
    border-bottom: 1px dashed ${MAIN_COLOR};
    font-size: 14px;
    line-height: 17px;
	  color: ${MAIN_COLOR};

    &:hover {
        text-decoration: none;
        color: ${MAIN_COLOR};
    }
`

export default GetCheckModal
