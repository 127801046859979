import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import moment from "moment";
import Modal from "react-modal";
import plural from "plural-ru";
import * as _ from "underscore";
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import Periods from "../Menu/Periods";
import Category from "../Menu/CategoryNew";
import {
  getKitchens,
  getAccountData,
  getDeliveryPeriods,
  setActiveDay,
  getMenuNew,
  setActivePeriod,
  setActiveAccount,
  setActivePeriodForCart,
} from '../../actions/MenuActions';
import {addElemToCart, addElemToIndexes, setAmounts, setCart, setCartSum} from "../../actions/CartActions";
import {deliveryCycleInfoMessage} from "../../actions/ModalActions";
import ArrowLeft from '../../images/menu/arrow_left.svg';
import bicycle from "../../images/bicycle.svg";
import NoPhoto from "../../images/menu/no_photo.png";
import Minus from '../../images/menu/minus.svg';
import Plus from '../../images/menu/plus.svg';
import Tick from '../../images/menu/tick_white.svg';
import {MAIN_COLOR} from "../../utils/constants";

const RestMenu = props => {
  const {params: {id}} = props.match;

  const [isKitchenAvailable, setKitchenAvailable] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [dataForModal, setDataForModal] = useState(false);
  const [count, setCount] = useState(0);
  const [isShowGoBackModal, setIsShowGoBackModal] = useState(null);
  const [isShowGoBackModal2, setIsShowGoBackModal2] = useState(null);

  const [amountForAdd, setAmountForAdd] = useState(1);
  const [amountForEdit, setAmountForEdit] = useState(1);
  const [amountForEditPrev, setAmountForEditPrev] = useState(null);
  const [cartEditMode, setCartEditMode] = useState(false);
  const [mod, setMod] = useState({});
  const [availableToOrder, setAvailableToOrder] = useState(1);
  const [availableModToOrder, setAvailableModToOrder] = useState(1);

  const {
    day,
    activeDay,
    activePeriod,
    kitchens,
    accountData,
    deliveryPeriods,
    newMenu,
    activeAccountForCart,
    activePeriodForCart,
  } = useSelector(store => store.menu);
  const {cart, amounts, sum, cartModifiedIndexes} = useSelector(store => store.cart);
  const {isAuth, activeCompany} = useSelector(store => store.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountData(id));
    dispatch(getKitchens());

    if (day && day !== activeDay) {
      dispatch(setActiveDay(day));
    }
  }, []);

  useEffect(() => {
    if (activeDay) {
      dispatch(getMenuNew(id, moment(activeDay).format('YYYY-MM-DD'), '1'));
    }
  }, [activeDay]);

  useEffect(() => {
    console.log(newMenu, 'newMenu')
  }, [newMenu]);

  useEffect(() => {
    console.log(kitchens, 'kitchens')
    if (kitchens.length) {
      const targetKitchen = kitchens.find(item => item.id === id);
      if (targetKitchen) {
        setKitchenAvailable(true);
        setCurrentAccount(targetKitchen);
      } else {
        setKitchenAvailable(false);
      }
    }
  }, [kitchens]);

  useEffect(() => {
    if (isKitchenAvailable) {
      dispatch(getDeliveryPeriods(id));
    }
  }, [isKitchenAvailable]);

  useEffect(() => {
    console.log(cart, 'cart')
    const obj = {};
    if (cart && Array.isArray(cart) && cart.length) {
      cart.forEach(item => {
        if (obj.hasOwnProperty(item.id)) {
          obj[item.id] += item.amount;
        } else {
          obj[item.id] = item.amount;
        }
      });
    }
    dispatch(setAmounts(obj));
  }, [cart]);

  useEffect(() => {
    console.log(deliveryPeriods, 'deliveryPeriods');

    if (deliveryPeriods && deliveryPeriods.length) {
      const selectedDay = deliveryPeriods.find(item => moment(item.dateString).isSame(moment(day), 'day'));
      if (selectedDay && selectedDay.periods && selectedDay.periods.length) {
        dispatch(setActivePeriod(selectedDay.periods[0]));
      }
    }
  }, [deliveryPeriods]);

  useEffect(() => {
    if (dataForModal) {
      setMod({});
    }
  }, [dataForModal]);

  useEffect(() => {
    if (dataForModal) {
      addDataForModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mod]);

  const openModal = item => {
    setDataForModal(item);
    setCount(prev => ++prev);
  };

  const addToCart = (item, edit, test) => {
    const pushToCart = () => {
      items.push({
        mediaId: item.mediaId, // ToDo: проверить
        amount: item.amount,
        id: item.id,
        name: item.name,
        price: String(item.price * 1),
        restId: item.restId,
        image: item.image,
        section: item.section,
        modifiers: item.modifiers ? item.modifiers : [],
        amountForOrder: item.amountForOrder,
        amountOrdered: item.amountOrdered,
        amountPerOrder: item.amountPerOrder,
      });
    };

    let items = !test && cart ? cart.slice() : [];
    let itemIndex = items.findIndex(elem => elem.id === item.id);
    let sumModified = 0;

    // если в корзине есть добавляемое блюдо...
    if (itemIndex !== -1) {
      // если блюдо добавляется с модификатором
      if (_.size(item.modifiers) > 0) {
        let modItemIndex = items.findIndex(elem => {
          if (elem.modifiers.length > 0) {
            return (
              elem.id === item.id &&
              elem.modifiers[0].id === item.modifiers[0].id
            );
          } else {
            return false;
          }
        });

        if (modItemIndex !== -1) {
          let modItemInCart = items.find(elem => {
            if (elem.modifiers.length > 0) {
              return (
                elem.id === item.id &&
                elem.modifiers[0].id === item.modifiers[0].id
              );
            } else {
              return false;
            }
          });

          //const newAmount = modItemInCart.amount + item.amount;
          const newAmount = edit
            ? item.amount
            : modItemInCart.amount + item.amount;
          modItemInCart = {...modItemInCart, amount: newAmount}; //!!!
          items[modItemIndex] = modItemInCart;
        } else {
          pushToCart();
        }

        sumModified =
          (parseInt(item.price, 10) + parseInt(item.modifiers[0].price, 10)) *
          parseInt(item.amount, 10);
      } else {
        let withoutModItemIndex = items.findIndex(
          elem => elem.id === item.id && elem.modifiers.length === 0,
        );

        if (withoutModItemIndex !== -1) {
          let itemInCart = items.find(
            elem => elem.id === item.id && elem.modifiers.length === 0,
          );
          const newAmount = edit
            ? item.amount
            : itemInCart.amount + item.amount;
          itemInCart = {...itemInCart, amount: newAmount};
          items[withoutModItemIndex] = itemInCart;
        } else {
          pushToCart();
        }

        sumModified = parseInt(item.price, 10) * parseInt(item.amount, 10);
      }
    } else {
      pushToCart();

      if (_.size(item.modifiers) > 0) {
        sumModified =
          (parseInt(item.price, 10) + parseInt(item.modifiers[0].price, 10)) *
          parseInt(item.amount, 10);
      } else {
        sumModified = parseInt(item.price, 10) * parseInt(item.amount, 10);
      }
    }

    items = items.filter(elem => elem.amount !== 0);

    if (edit || test) {
      let cartSum = items.reduce((acc, cur) => {
        if (_.size(cur.modifiers) > 0) {
          return acc + (+cur.price + +cur.modifiers[0].price) * cur.amount;
        } else {
          return acc + +cur.price * cur.amount;
        }
      }, 0);
      dispatch(setCartSum(cartSum));
    } else {
      dispatch(setCartSum(sum + sumModified));
    }

    if (items.length === 0) {
      dispatch(setActiveAccount(null));
      dispatch(setActivePeriodForCart(null));
    } else if ((cart && cart.length === 0) || test) {
      dispatch(setActiveAccount({
        id: currentAccount.id,
        name: currentAccount.name,
        minOrderPrice: currentAccount.minOrderPrice,
      }));
      dispatch(setActivePeriodForCart({day: activeDay, period: activePeriod}));
      dispatch(setCart(null));
    }
    dispatch(addElemToCart(items));

    //detailedModalRef.current && detailedModalRef.current.close();
    setDataForModal(false);
    setAmountForAdd(1);
    setAmountForEdit(1);
    setAmountForEditPrev(null);
    setCartEditMode(false);
  };

  const addDataForModal = () => {
    let withModifiers = _.size(dataForModal.modifiers) > 0;
    let isItemOnCart = cart.some(i => i.id === dataForModal.id);
    if (activePeriodForCart && (activePeriodForCart.day !== activeDay || String(activePeriodForCart.period.id) !== String(activePeriod.id))) {
      isItemOnCart = false;
    }

    if (!withModifiers && isItemOnCart) {
      setCartEditMode(true);
      let amountOnCart = cart.find(i => i.id === dataForModal.id).amount;
      setAmountForEdit(amountOnCart);
      setAmountForEditPrev(amountOnCart);
    }

    let availableProductsToOrder =
      dataForModal.amountPerOrder !== null
        ? dataForModal.amountPerOrder
        : Infinity;
    let availableModifiersToOrder =
      _.size(mod) > 0
        ? mod.amountPerOrder !== null
          ? mod.amountPerOrder
          : Infinity
        : 0;

    if (cart && cart.length > 0) {
      let filterCart1 = cart.filter(item => item.id === dataForModal.id);
      let countProductsInCart = filterCart1.reduce((prev, item) => {
        return prev + item.amount;
      }, 0);
      availableProductsToOrder =
        dataForModal.amountPerOrder !== null
          ? dataForModal.amountPerOrder - countProductsInCart
          : Infinity;

      if (_.size(mod) > 0) {
        let filterCart2 = cart.filter(item =>
          (item.modifiers && item.modifiers.length > 0) ? item.modifiers[0].id === mod.id : false,
        );
        let countModsInCart = filterCart2.reduce((prev, item) => {
          return prev + item.amount;
        }, 0);
        availableModifiersToOrder =
          mod.amountPerOrder !== null
            ? mod.amountPerOrder - countModsInCart
            : Infinity;
      }
    }

    setAvailableToOrder(availableProductsToOrder);
    setAvailableModToOrder(availableModifiersToOrder);
  };

  const addToIndexes = (productId, modId, amount) => {
    const arr = cartModifiedIndexes ? cartModifiedIndexes.slice() : [];
    arr.push({id: productId, modId, amount});
    dispatch(addElemToIndexes(arr));
  };

  const newItem = (edit = false) => {
    let image = _.find(dataForModal.images, img => {
      return img ? parseInt(img.id, 10) === parseInt('1000' + dataForModal.MediaId, 10) : false;
    });

    let item = {
      ...dataForModal,
      activeDay: activeDay,
      amount: edit ? amountForEdit : amountForAdd,
      image: image ? image.source : null,
      modifiers:
        _.size(mod) > 0
          ? [
            {
              amount: 1,
              id: mod.id,
              name: mod.name,
              price: mod.price,
              amountPerOrder: mod.amountPerOrder,
            },
          ]
          : [],
      restId: id,
    };

    delete item.images;

    if (activeAccountForCart && String(activeAccountForCart.id) !== String(id)) {
      setIsShowGoBackModal({item, edit});
      // dispatch(deliveryCycleInfoMessage('promo_message_error', 'В вашей корзине уже есть товары другой кухни. Освободите корзину'));
    } else if (
      activePeriodForCart
      && (activePeriodForCart.day !== activeDay || String(activePeriodForCart.period.id) !== String(activePeriod.id))
    ) {
      setIsShowGoBackModal2({item, edit});
      // dispatch(deliveryCycleInfoMessage('promo_message_error', 'В вашей корзине уже есть товары на другой день/период. Освободите корзину'));
    } else {
      addToCart(item, edit);
    }

    if (_.size(dataForModal.modifiers) > 0) {
      addToIndexes(
        dataForModal.id,
        _.size(mod) > 0 ? mod.id : null,
        amountForAdd,
      );
    }
  };

  return (
    <Page>
      <Helmet>
        <title>Меню</title>
      </Helmet>
      <Modal
        closeTimeoutMS={100}
        isOpen={Boolean(isShowGoBackModal)}
        style={customStyles}
        overlayClassName={"Modifiers__Overlay__9991"}
        onRequestClose={() => setIsShowGoBackModal(null)}
      >
        {isShowGoBackModal ? (
          <ModalWrapper2>
            <ModalWrapperTitle>В вашей корзине уже есть товары другой кухни. Освободить корзину?</ModalWrapperTitle>
            <ButtonsRemove>
              <ModalWrapperPay
                onClick={() => {
                  addToCart(isShowGoBackModal.item, isShowGoBackModal.edit, true)
                  setIsShowGoBackModal(null);
                }}
              >
                Да
              </ModalWrapperPay>
              <ModalWrapperClose onClick={() => setIsShowGoBackModal(null)}>
                Отмена
              </ModalWrapperClose>
            </ButtonsRemove>
          </ModalWrapper2>
        ) : null}
      </Modal>
      <Modal
        closeTimeoutMS={100}
        isOpen={Boolean(isShowGoBackModal2)}
        style={customStyles}
        overlayClassName={"Modifiers__Overlay__9991"}
        onRequestClose={() => setIsShowGoBackModal2(null)}
      >
        {isShowGoBackModal2 ? (
          <ModalWrapper2>
            <ModalWrapperTitle>В вашей корзине уже есть товары на другой день/период. Освободить корзину?</ModalWrapperTitle>
            <ButtonsRemove>
              <ModalWrapperPay
                onClick={() => {
                  addToCart(isShowGoBackModal2.item, isShowGoBackModal2.edit, true)
                  setIsShowGoBackModal2(null);
                }}
              >
                Да
              </ModalWrapperPay>
              <ModalWrapperClose onClick={() => setIsShowGoBackModal2(null)}>
                Отмена
              </ModalWrapperClose>
            </ButtonsRemove>
          </ModalWrapper2>
        ) : null}
      </Modal>
      <Modal
        closeTimeoutMS={100}
        isOpen={Boolean(dataForModal)}
        style={customStyles}
        overlayClassName={"Modifiers__Overlay"}
        onRequestClose={() => {
          setDataForModal(false);
          setAmountForAdd(1);
          setAmountForEdit(1);
          setAmountForEditPrev(null);
          setCartEditMode(false);
        }}
      >
        {dataForModal ? (
          <ModalWrapper>
            <ModalLeft>
              <ModalImage
                src={dataForModal.mediaId
                  ? `https://menuforme.online/img/cache/so_product_preview_quad/${dataForModal.mediaId}.jpg`
                  : NoPhoto
                }
              />
              {dataForModal.description ? (
                <>
                  <DescriptionTitle>Состав:</DescriptionTitle>
                  <Description>
                    {dataForModal.description}
                  </Description>
                </>
              ) : null}
            </ModalLeft>
            <ModalRight>
              {dataForModal.name ? (
                <ItemName>{dataForModal.name}</ItemName>
              ) : null}
              {dataForModal.weight ? (
                <ItemWeight>{+dataForModal.weight} г</ItemWeight>
              ) : null}
              <Hr />
              <ModsAndAmount>

                {dataForModal.modifiers && dataForModal.modifiers.length ? (
                  <Mods>
                    {_.sortBy(dataForModal.modifiers, modifier => modifier.price).map(v => {
                      if (+v.price) {
                        let availableModsToOrder =
                          v.amountPerOrder !== null ? +v.amountPerOrder : Infinity;

                        if (cart && cart.length > 0) {
                          let filterCart = cart.filter(
                            item =>
                              item.modifiers.length > 0 &&
                              item.modifiers[0].id === v.id,
                          );
                          let countInCart = filterCart.reduce((prev, item) => {
                            return prev + item.amount;
                          }, 0);
                          availableModsToOrder =
                            v.amountPerOrder !== null
                              ? +v.amountPerOrder - countInCart
                              : Infinity;
                        }

                        return (
                          <ModifierItem
                            key={v.id}
                            enable={availableToOrder > 0}
                            onClick={() => {
                              if (availableModsToOrder > 0) {
                                setMod(prev =>
                                  prev.id === v.id ? {} : v,
                                );
                              }
                            }}
                            // data={v}
                            // onPress={() => {
                            //   if (availableModsToOrder > 0) {
                            //     //setAmountForAdd(1);
                            //     setMod(prev =>
                            //       prev.id === v.id ? {} : v,
                            //     );
                            //   }
                            // }}
                            // checked={mod.id === v.id}
                            // availableToOrder={availableModsToOrder}
                          >
                            {mod.id === v.id ? (
                              <Checkbox1>
                                <img src={Tick} width={16} height={16} alt='' />
                              </Checkbox1>
                            ) : (
                              <Checkbox0 />
                            )}
                            <div style={{width: 235, marginLeft: 16, marginRight: 16}}>
                              <ModifierName>
                                {v.name.replace('+', '').trim()}
                              </ModifierName>
                              {availableToOrder !== Infinity ? (
                                <ModifierAmount>
                                  {availableToOrder > 0
                                    ? `В наличии: ${availableToOrder} шт.`
                                    : 'Закончилось'}
                                </ModifierAmount>
                              ) : null}
                            </div>
                            <ModifierPrice>
                              +{+v.price} ₽
                            </ModifierPrice>
                          </ModifierItem>
                        );
                      }
                    })}
                  </Mods>
                ) : null}

                <ModalButtons>
                  {cartEditMode ? (
                    <>
                      <BottomCounter>
                        <CounterButton isAvailable={true} onClick={() => setAmountForEdit(prev => (prev > 0 ? prev - 1 : prev))}>
                          <img style={{userSelect: 'none'}} src={Minus} width={12} height={1} alt='' />
                        </CounterButton>
                        <AmountForAdd>{amountForEdit}</AmountForAdd>
                        <CounterButton
                          isAvailable={amountForAdd < availableToOrder}
                          onClick={() => {
                            setAmountForEdit(prev => {
                              return prev < availableToOrder ? prev + 1 : prev;
                            })
                          }}
                        >
                          <img style={{userSelect: 'none'}} src={Plus} width={12} height={12} alt='' />
                        </CounterButton>
                      </BottomCounter>
                      <AddButton isOver={availableToOrder < 1} onClick={() => {
                        if (!isAuth) {
                          dispatch(deliveryCycleInfoMessage('promo_message_error', 'Для оформления заказа необходимо авторизоваться'));
                        } else if (!activeCompany) {
                          dispatch(deliveryCycleInfoMessage('promo_message_error', 'Для оформления заказа необходимо выбрать адрес доставки'));
                        } else if (availableToOrder < 1) {
                          console.log('Закончилось');
                        } else {
                          if (dataForModal) {
                            newItem(true);
                          }
                        }
                      }}>
                        <AddButtonText>
                          {amountForEdit === 0
                            ? 'Удалить'
                            : amountForEdit === amountForEditPrev
                              ? 'Готово'
                              : 'Сохранить'}
                        </AddButtonText>
                        <Price>
                          {(+dataForModal.price + (mod.price ? +mod.price : 0)) *
                            (cartEditMode ? amountForEdit : amountForAdd)}{' '}
                          ₽
                        </Price>
                      </AddButton>
                    </>
                  ) : (
                    <>
                      <BottomCounter>
                        <CounterButton isAvailable={true} onClick={() => setAmountForAdd(prev => (prev > 1 ? prev - 1 : prev))}>
                          <img style={{userSelect: 'none'}} src={Minus} width={12} height={1} alt='' />
                        </CounterButton>
                        <AmountForAdd>{amountForAdd}</AmountForAdd>
                        <CounterButton
                          isAvailable={amountForAdd < availableToOrder}
                          onClick={() => {
                            setAmountForAdd(prev => {
                              return prev < availableToOrder ? prev + 1 : prev;
                            })
                          }}
                        >
                          <img style={{userSelect: 'none'}} src={Plus} width={12} height={12} alt='' />
                        </CounterButton>
                      </BottomCounter>
                      <AddButton isOver={availableToOrder < 1} onClick={() => {
                        if (activePeriod && activePeriod.isDisabled) {
                          dispatch(deliveryCycleInfoMessage('promo_message_error', activePeriod.isDisabledReason));
                        } else if (!isAuth) {
                          dispatch(deliveryCycleInfoMessage('promo_message_error', 'Для оформления заказа необходимо авторизоваться'));
                        } else if (!activeCompany) {
                          dispatch(deliveryCycleInfoMessage('promo_message_error', 'Для оформления заказа необходимо выбрать адрес доставки'));
                        } else if (availableToOrder < 1) {
                          console.log('Закончилось');
                        } else if (
                          mod.id
                            ? amountForAdd > availableModToOrder
                            : false
                        ) {
                          dispatch(deliveryCycleInfoMessage(
                            'promo_message_error',
                            `${mod.name.replace('+', '').trim()}. ${plural(
                                        availableModToOrder,
                                        'Осталась %d штука',
                                        'Остались %d штуки',
                                        'Осталось %d штук',
                                      )}`
                          ));
                        } else {
                          if (dataForModal) {
                            newItem();
                          }
                        }
                      }}>
                        <AddButtonText>Добавить</AddButtonText>
                        <Price>
                          {(+dataForModal.price + (mod.price ? +mod.price : 0)) *
                            (cartEditMode ? amountForEdit : amountForAdd)}{' '}
                          ₽
                        </Price>
                      </AddButton>
                    </>
                  )}
                </ModalButtons>
              </ModsAndAmount>
            </ModalRight>
          </ModalWrapper>
        ) : null}
      </Modal>
      <MenuWrapper>
        <MenuContainer>
          <MenuTitle>
            <BackButton to={`/`}>
              <img src={ArrowLeft} width={42} height={42} alt={''} />
            </BackButton>
            {(accountData && accountData.about && accountData.about.name) ? (
              <RestName>{accountData.about.name.trim()}</RestName>
            ) : null}
          </MenuTitle>
          <MenuSubTitle>
            <img src={bicycle} width={32} height={32} alt="" />
            <DeliveryTimeInfo>с 11:30 до 12:30</DeliveryTimeInfo>
          </MenuSubTitle>
          {(deliveryPeriods && deliveryPeriods.length) ? (
            <Periods periods={deliveryPeriods} />
          ) : null}
          {(Array.isArray(newMenu) && newMenu.length) ? (
            newMenu.map(category => {
              return (
                <Category
                  data={category}
                  amounts={amounts}
                  accountId={id}
                  addToCart={(item, edit) => {
                    if (activeAccountForCart && String(activeAccountForCart.id) !== String(id)) {
                      setIsShowGoBackModal({item, edit});
                      //dispatch(deliveryCycleInfoMessage('promo_message_error', 'В вашей корзине уже есть товары другой кухни. Освободите корзину'));
                    } else if (
                      activePeriodForCart
                      && (activePeriodForCart.day !== activeDay || String(activePeriodForCart.period.id) !== String(activePeriod.id))
                    ) {
                      setIsShowGoBackModal2({item, edit});
                      //dispatch(deliveryCycleInfoMessage('promo_message_error', 'В вашей корзине уже есть товары на другой день/период. Освободите корзину'));
                    } else {
                      addToCart(item, edit);
                    }
                  }}
                  openModal={openModal}
                />
              );
            })
          ) : null}
        </MenuContainer>
      </MenuWrapper>
    </Page>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "15px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "auto",
    width: "100%",
    maxWidth: "751px",
  }
};

export default RestMenu;

const Page = styled.div`
  padding-top: 138px;
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
	padding: 32px;
	background: #FFFFFF;
	border-radius: 24px;
`;

const ModalWrapper2 = styled.div`
	padding: 25px 0;
	background: #fff;
	border-radius: 4px;
	position: relative;
	transition: .3s linear;
	
	@media(max-width:561px) and (min-width:490px){
	   margin-top: 96px;
	}
	
    @media(max-width:490px) and (min-width:330px){
	   margin-top: 118px;
	}
	
    @media(max-width:330px){
	   margin-top: 140px;
	}
`;

const ModalLeft = styled.div`
  width: 269px;
`;

const ModalImage = styled.div`
  width: 100%;
  height: 269px;
	background: ${({src}) => 'url(' + src + ')'};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 16px;
`;

const ModalRight = styled.div`
  width: calc(100% - 301px);
	background: ${({src}) => 'url(' + src + ')'};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 16px;
`;

const ItemName = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #37474F;
`;

const ItemWeight = styled.p`
  margin: 10px 0 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #969696;
`;

const Hr = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 16px;
  background-color: #EEEEEE;
`;

const ModsAndAmount = styled.div`
  width: 100%;
`;

const Mods = styled.div`
  width: 100%;
  padding-top: 37px;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;

const BottomCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 121px;
  height: 60px;
  background-color: #F5F4F2;
  border-radius: 16px;
`;

const CounterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 100%;
  cursor: pointer;
  opacity: ${({isAvailable}) => isAvailable ? '1' : '0.1'};
`;

const AmountForAdd = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  user-select: none;
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 219px;
  height: 60px;
  padding: 0 16px;
  background-color: ${MAIN_COLOR};
  border-radius: 16px;
  opacity: ${({isOver}) => isOver ? '0.4' : '1'};
  cursor: pointer;
`;

const AddButtonText = styled.p`
  margin: 0 auto 0 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  user-select: none;
`;

const Price = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #FFFFFF;
  user-select: none;
`;

const ModifierItem = styled.div`
  display: flex;
  margin-top: 16px;
  opacity: ${({enable}) => enable ? '1' : '0.4'};
  cursor: pointer;
`;

const Checkbox1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: linear-gradient(180deg, #96D181 0%, #76BA5F 100%);
  border-radius: 12px;
`;

const Checkbox0 = styled.div`
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 12px;
`;

const ModifierName = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #263238;
`;

const ModifierAmount = styled.p`
  margin: 6px 0 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #737373;
  user-select: none;
`;

const ModifierPrice = styled.p`
  width: 65px;
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #263238;
  text-align: right;
  user-select: none;
`;

const MenuWrapper = styled.div`
  width: 100%;
  padding-top: 40px;
  background-color: #FFFFFF;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
`;

const MenuContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 45px;
`;

const MenuTitle = styled.div`
  display: flex;
`;

const BackButton = styled(Link)`
  display: flex;
  width: 42px;
  height: 42px;
`;

const RestName = styled.p`
  align-self: flex-end;
  margin: 0 0 0 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #2C2E32;
  user-select: none;
`;

const MenuSubTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const DeliveryTimeInfo = styled.p`
  margin: 0 0 0 8px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #2C2E32;
  user-select: none;
`;

const DescriptionTitle = styled.p`
  margin: 18px 0 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #737373;
  user-select: none;
`;

const Description = styled.p`
  margin: 11px 0 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #263238;
`;

const ButtonsRemove = styled.div`
    display: flex;
    justify-content: space-around;
    
    @media(max-width: 526px){
        flex-direction: column;
        align-items: center;
    }
`;

const ModalWrapperTitle = styled.h1`
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: #263238;
    text-align: center;
`;

const Pay = styled.div`
    width: 200px;
    height: 52px;
    background-color: #E0D5D2;
    border: 1px solid #E0D5D2;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #fff;  
    position: relative;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    
    @media(max-width: 526px){
        margin-top: 10px;
    }
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -200px;
        width: 0;
        height: 252px;
        background: rgba(255,255,255,0.2);
        border-radius: 5px;
        -webkit-transition: all .6s ease;
        transition: all .3s ease;
        transform: rotate(210deg);
    }   
    
    &:hover{
        text-decoration: none;
    
        &:before {
            width: 1000%;
        }
    }
`;

const ModalWrapperClose = styled(Pay)`
    border: 1px solid #333333;
    color: #333333;
    font-size: 16px;
    background: #fff;
    margin-top: 40px;
    height: 50px;
`;

const Remove = styled(Pay)`
    background: #E57373;
    border-radius: 6px;
    color: #fff;
    border: 1px solid #E57373;
    outline: none;
    margin-top: 40px;
    
    &:hover{
        &:before {
            width: 1000%;
        }
    }
`;

const ModalWrapperPay = styled(Remove)`
    height: 50px;
`;
