if (window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru')) {
    if(!JSON.parse(localStorage.getItem("currentCity"))){
        localStorage.setItem("currentCity", JSON.stringify({id: "9", name: "Самара"}));
    }
}

window.dataCity = function(){
    return localStorage.getItem("currentCity") ? JSON.parse(localStorage.getItem("currentCity")) : false;
};

window.cityNameFormatted = function() {
    let city = window.dataCity();

    if(city){
        switch(city.name){
            case "Самара": return ["Самаре", "Самару", "Самары", "Самара"];
                break;
            case "Тольятти": return ["Тольятти", "Тольятти", "Тольятти", "Тольятти"];
                break;
            case "Димитровград": return ["Димитровграду", "Димитровград", "Димитровграда", "Димитровград"];
                break;
            case "Балаково": return ["Балаково", "Балаково", "Балаково", "Балаково"];
                break;
            case "Москва": return ["Москве", "Москву", "Москвы", "Москва"];
                break;
        }
    } else {
        return false;
    }
};

window.delete_cookie = function ( cookie_name ){
    var cookie_date = new Date ( );  // Текущая дата и время
    cookie_date.setTime ( cookie_date.getTime() - 1 );
    document.cookie = cookie_name += "=; expires=" + cookie_date.toGMTString();
};

window.getCookie = function (name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
};