import React, {Fragment, useCallback, useEffect, useState} from "react";
import Modal from "react-modal";
import styled from "styled-components";
import {reduxForm} from 'redux-form'
import Tooltip from '@material-ui/core/Tooltip';
import validate from './validate';
import {useDispatch, useSelector} from "react-redux";
import {saveCompany} from "../../../actions/ProfileActions";
import {infoViewToggle} from "../../../actions/ModalActions";
import {MAIN_COLOR} from "../../../utils/constants";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {theme} from "../../Menu/Catalog";
import closeModal from "../../../images/close-nav-cross.svg";
import coord_icon from "../../../images/marker/coord_v2.svg";
import ModalRegStageFirst from "./components/ModalRegStageFirst"
import ModalRegStageSecond from "./components/ModalRegStageSecond"
import {resetAddCompany} from "../../../actions/AddCompanyActions"

Modal.setAppElement("#root");

const AddModalCompany = ({isMobile, button}) => {
  const dispatch = useDispatch()

  const [openModalAdd, setOpenModalAdd] = useState(false)
  const [regStage, setRegStage] = useState(0)
  const [dataCheck, setDataCheck] = useState(false)
  const [requiredFieldsCheck, seRequiredFieldsCheck] = useState({
    companyName: {
      errStatus: false,
      message: ''
    }
  })

  const customerAddress = useSelector(store => store.addCompany.addressObj)
  const additionalComponents = useSelector(store => store.addCompany.addressAdditional)
  const [nextStageAccess, setNextStageAccess] = useState(false)

  useEffect(() => {
    setNextStageAccess(false)
    if (customerAddress && customerAddress.addressComponents && customerAddress.addressComponents.houseNum) {
      setNextStageAccess(true)
    }
  }, [customerAddress, additionalComponents]);

  const handleToggleModalAdd = (e) => {
    if (e) e.stopPropagation();
    setOpenModalAdd(prev => !prev)
  };

  useEffect(() => {
    if(additionalComponents.companyName) {
      setDataCheck(checkDataHandler())
    }
  }, [additionalComponents.companyName])

  const saveCompanySettings = () => {
    const profileData = {
      id: null,
      address: {
        ...customerAddress.location,
        street: customerAddress.addressComponents.streetName,
        house: customerAddress.addressComponents.houseNum,
        floor: additionalComponents.floor || "",
        entrance: additionalComponents.entrance || "",
        flat: additionalComponents.flat || "",
        city: {
          id: JSON.parse(localStorage.getItem('currentCity')).id,
          name: JSON.parse(localStorage.getItem('currentCity')).name
        },
      },
      extra: {
        comment: additionalComponents.comment || "",
      }
    };
    if(additionalComponents.addressType === 'office') {
      profileData.name = additionalComponents.companyName
    }
    if(additionalComponents.addressType === 'home') {
      profileData.name = "Дом"
    }

    setDataCheck(checkDataHandler())

    if(checkDataHandler()) {
      dispatch(saveCompany(profileData));
      handleToggleModalAdd();
      infoViewToggle(false);
    }
  };

  const checkDataHandler = () => {
    let dataIsReady = false
    if (additionalComponents.addressType === 'office') {
      if (additionalComponents.companyName === '') {
        seRequiredFieldsCheck(prevState => ({
          ...prevState,
          companyName: {
            errStatus: true,
            message: 'Поле обязательно к заполнению'
          }}))
        return dataIsReady
      }
      dataIsReady = true
    }
    if (additionalComponents.addressType === 'home') {
      dataIsReady = true
    }
    return dataIsReady
  }

  const clear = useCallback(() => {
      dispatch(resetAddCompany())
      setRegStage(0)
      setNextStageAccess(false)
    },
    [],
  );


  return (
    <Fragment>
      {isMobile ? (
        <AddCompanyBlock
          onClick={(e) => {
          handleToggleModalAdd(e)
        }}>
          {button ? (
            button
          ) : (
            <InviteUser>
              <div style={{
                width: 12,
                height: 2,
                borderRadius: 1,
                backgroundColor: '#263238',
              }}/>
              <div style={{
                position: 'absolute',
                width: 12,
                height: 2,
                borderRadius: 1,
                backgroundColor: '#263238',
                transform: 'rotate(90deg)',
              }}/>
            </InviteUser>
          )}
        </AddCompanyBlock>
      ) : (
        <AddCompanyBlock onClick={(e) => {
          handleToggleModalAdd(e)
        }}>
          {button ? (
            button
          ) : (
            // <MuiThemeProvider theme={theme}>
            //   <Tooltip title={<ToolTipAddCompany>Добавить адрес</ToolTipAddCompany>}>
            //     <InviteUser>
            //       <PlusIcon className="fas fa-plus"/>
            //     </InviteUser>
            //   </Tooltip>
            // </MuiThemeProvider>
            <Fragment>
              <CoordIcon src={coord_icon} alt="" />
              <AddCompanyText>Укажите адрес доставки</AddCompanyText>
            </Fragment>
          )}
        </AddCompanyBlock>
      )}
      <Modal
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleToggleModalAdd}
        closeTimeoutMS={100}
        isOpen={openModalAdd}
        onAfterClose={() => clear()}
        style={isMobile ? customStylesMobile : customStyles}
        overlayClassName={"Modifiers__Overlay"}>
        <ModalWrapper onClick={(e) => {
          e.stopPropagation()
        }}>
          <ModalBody>
            {
              regStage === 0 && (
                <>
                  <ModalRegStageFirst
                    isMobile={isMobile}
                    modalControl={handleToggleModalAdd}
                    customerAddress={customerAddress}>
                    <Title>Укажите адрес доставки</Title>
                    <CloseModalButton onClick={handleToggleModalAdd}>
                      <img src={closeModal} alt='close'/>
                    </CloseModalButton>
                  </ModalRegStageFirst>
                  <Button onClick={() => setRegStage(1)} disabled={!nextStageAccess}>Далее</Button>
                </>

              )
            }
            {
              regStage === 1 && (
                <>
                  <ModalRegStageSecond
                    requiredFieldsCheck={requiredFieldsCheck}
                    customerAddress={customerAddress}
                    setRegStage={setRegStage}
                  >
                    <Title>Укажите детали адреса</Title>
                    <CloseModalButton onClick={handleToggleModalAdd}>
                      <img src={closeModal} alt='close'/>
                    </CloseModalButton>
                  </ModalRegStageSecond>
                  <ButtonWrapper>
                    <ButtonBack onClick={() => setRegStage(0)}>Назад</ButtonBack>
                    <Button onClick={() => saveCompanySettings()}>Добавить</Button>
                  </ButtonWrapper>
                </>
              )
            }
          </ModalBody>
        </ModalWrapper>
      </Modal>
    </Fragment>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "15px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "auto",
    width: "100%",
    maxWidth: "750px",
  }
};

const customStylesMobile = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "8px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "auto",
    width: "100%",
    maxWidth: "750px",
    maxHeight: "100%",
  }
};

export default reduxForm({
  form: 'simple_edit',
  enableReinitialize: true,
  validate
})((AddModalCompany))

const Title = styled.h3`
  text-align: left;
  font-size: 28px;
  font-weight: 500;
  margin: 0;

  @media (max-width: 960px) {
    font-size: 20px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`

const Button = styled.button`
  width: 20%;
  padding: 5px 10px;
  border: none;
  background: ${({disabled}) => disabled ? '#646464' : MAIN_COLOR};
  align-self: flex-end;
  color: #FFF;
  margin-top: 10px;
  border-radius: 8px;

  @media (max-width: 992px) {
    width: 35%;
  }
`

const ButtonBack = styled(Button)`
  background: #fff;
  border: 1px solid #646464;
  color: #646464;
  transition: all .3s ease;
  
  :hover{
    background: silver;
    color: #fff;
  }
`

const ModalWrapper = styled.div`
  background: #fff;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
`;

const AddCompanyBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (min-width: 992.01px) {
    justify-content: flex-start;
    width: calc(100% - 32px);
    margin: 16px auto 24px;
    /*height: 100%;*/
    /*padding: 0 22px 0 23px;*/
  }
`;

const CoordIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 8px;
`;

const AddCompanyText = styled.p`
  margin-bottom: 0;
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #333333;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 668px;
  z-index: ${({action}) => action === 'reg' ? '1' : '0'};
  transition: all .2s linear;
  padding: 25px;
  overflow: hidden;
  position: relative;

  @media (max-width: 992px) {
    padding: 15px 10px 10px;
    min-height: 350px;
  }

  &.reg-enter {
    opacity: 0;
    transform: translateX(170px);
    transition: transform .5s cubic-bezier(0, 0.45, 0, 0.59), opacity .2s;
  }

  &.reg-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
  }

  &.reg-exit {
    opacity: 1;
  }

  &.reg-exit-active {
    opacity: 0;
    transform: translateX(170px);
    transition: transform .5s cubic-bezier(0, 0.45, 0, 0.59), opacity .2s;
  }
`;

const PlusIcon = styled.i`
  color: #fff;
  transition: .2s linear;
  font-size: 14px;
`;

const InviteUser = styled.div`
  width: 26px;
  height: 26px;
  background: ${MAIN_COLOR};
  border-radius: 3px;
  border: 1px solid ${MAIN_COLOR};
  transition: .2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (min-width: 992.01px) {
    &:hover {
      background: #fff;
    }

    &:hover ${PlusIcon} {
      color: ${MAIN_COLOR};
    }
  }

  @media (max-width: 992px) {
    position: relative;
    width: 28px;
    height: 28px;
    background: #CDDC39;
    border-radius: 14px;
    border: 0;
    transition: all 0s ease 0s;
  }
`;
const ToolTipAddCompany = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  padding: 4px 8px;
`;

export const CloseModalButton = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  cursor: pointer;

  @media (max-width: 992px) {
    width: 18px;
    height: 18px;
  }
`
