import { request } from "../utils/request";

export const SET_AMOUNTS = "SET_AMOUNTS";
export const SET_CART_SUM = "SET_CART_SUM";
export const SET_CART_OTHER = "SET_CART_OTHER";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_INDEXES = "ADD_TO_INDEXES";
export const CLEAR_CART = "CLEAR_CART";

export const setAmounts = amounts => async (dispatch) => {
  dispatch({
    type: SET_AMOUNTS,
    payload: amounts,
  });
};

export const setCartSum = sum => async (dispatch) => {
  dispatch({
    type: SET_CART_SUM,
    payload: sum,
  });
};

export const setCart = sum => async (dispatch) => {
  dispatch({
    type: SET_CART_OTHER,
    payload: sum,
  });
};

export const addElemToCart = items => async (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: items,
  });
};

export const addElemToIndexes = items => async (dispatch) => {
  dispatch({
    type: ADD_TO_INDEXES,
    payload: items,
  });
};

export const clearCart = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CART,
  });
};
