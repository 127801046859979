import {request} from "../utils/request";
import {sha256} from "../utils/sha256";
import * as _ from "underscore";
import {deliveryCycleInfoMessage, infoViewToggle} from "./ModalActions";
import {setActiveAccount, setActivePeriodForCart} from "./MenuActions";
import {clearCart} from "./CartActions";
import ym from 'react-yandex-metrika';

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";

export const SEND_CODE_REQUEST = "SEND_CODE_REQUEST";
export const SEND_CODE_SUCCESS = "SEND_CODE_SUCCESS";
export const SEND_CODE_ERROR = "SEND_CODE_ERROR";

export const CREATE_TOKEN_REQUEST = "CREATE_TOKEN_REQUEST";
export const CREATE_TOKEN_SUCCESS = "CREATE_TOKEN_SUCCESS";
export const CREATE_TOKEN_ERROR = "CREATE_TOKEN_ERROR";

export const DELETE_TOKEN_REQUEST = "DELETE_TOKEN_REQUEST";
export const DELETE_TOKEN_SUCCESS = "DELETE_TOKEN_SUCCESS";
export const DELETE_TOKEN_ERROR = "DELETE_TOKEN_ERROR";

export const SAVE_PROFILE_REQUEST = "SAVE_PROFILE_REQUEST";
export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS";
export const SAVE_PROFILE_ERROR = "SAVE_PROFILE_ERROR";

export const SAVE_COMPANY_REQUEST = "SAVE_COMPANY_REQUEST";
export const SAVE_COMPANY_SUCCESS = "SAVE_COMPANY_SUCCESS";
export const SAVE_COMPANY_ERROR = "SAVE_COMPANY_ERROR";

export const AUTH_EMAIL_REQUEST = "AUTH_EMAIL_REQUEST";
export const AUTH_EMAIL_SUCCESS = "AUTH_EMAIL_SUCCESS";
export const AUTH_EMAIL_ERROR = "AUTH_EMAIL_ERROR";

export const HANDLE_REDUX_FORM_AUTH = "HANDLE_REDUX_FORM_AUTH";
export const HANDLE_REDUX_FORM_PROFILE_INFO = "HANDLE_REDUX_FORM_PROFILE_INFO";
export const HANDLE_REDUX_FORM_COMPANY_INFO_INITIAL_VALUE = "HANDLE_REDUX_FORM_COMPANY_INFO_INITIAL_VALUE";
export const HANDLE_REDUX_FORM_REG = "HANDLE_REDUX_FORM_REG";
export const HANDLE_REDUX_FORM_EDIT_COMPANY = "HANDLE_REDUX_FORM_EDIT_COMPANY";

export const PARAMS_GET_REQUEST = "PARAMS_GET_REQUEST";
export const PARAMS_GET_SUCCESS = "PARAMS_GET_SUCCESS";

export const CHANGE_ACTIVE_COMPANY = "CHANGE_ACTIVE_COMPANY";

export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";
export const GET_WALLETS_ERROR = "GET_WALLETS_ERROR";

export const GET_WALLET_LIMIT_SUCCESS = "GET_WALLET_LIMIT_SUCCESS";
export const GET_WALLET_LIMIT_ERROR = "GET_WALLET_LIMIT_ERROR";

export const getClientProfile = () => async dispatch => {
	try {

		let response = await request("AppDinnerController", "GetProfile");

		dispatch(getCompanyProfile());

		dispatch({
			type: GET_PROFILE_SUCCESS,
			payload: response.data.client
		});
	} catch (err) {
		dispatch({
			type: GET_PROFILE_ERROR,
			payload: err.message
		});
	}
};

export const getCompanyProfile = () => async dispatch => {
	try {

		let response = await request("AppDinnerController", "GetCompanies");
		let activeCompany = _.find(response.data.companies, (item)=>{return item.type === "1"});

		dispatch({
			type: GET_COMPANIES_SUCCESS,
			payload: {
				companies: response.data.companies,
				activeCompany: activeCompany
								? activeCompany
								: _.size(response.data.companies) !== 0
									? response.data.companies[0]
									: false
			}
		});

		if (_.size(response.data.companies) === 0) {
            dispatch(infoViewToggle());
        }
	} catch (err) {
		dispatch({
			type: GET_COMPANIES_ERROR,
			payload: err.message
		});
	}
};

export const saveClientProfile = settings => async (dispatch) => {
	try {

		let response = await request("AppDinnerController", "SaveProfile", settings);

		dispatch({
			type: GET_PROFILE_SUCCESS,
			payload: response.data.client
		});

		setTimeout(()=>{
			dispatch(deliveryCycleInfoMessage('save'));
		}, 500)
	} catch (err) {
		dispatch({
			type: SAVE_PROFILE_ERROR,
			payload: err.message
		});
	}
};

export const saveCompany = dataCompany => async (dispatch) => {
	try {
		let response = await request("AppDinnerController", "SaveCompany", {company: {...dataCompany}});

		dispatch({
			type: SAVE_PROFILE_SUCCESS,
			payload: response.data.client
		});

		dispatch(getCompanyProfile());

		setTimeout(()=>{
			dispatch(deliveryCycleInfoMessage('save_info'));
		}, 500)

		if (
			!window.location.href.includes('51obed.ru')
			&& !window.location.href.includes('51.menu4me-test.ru')
			&& !window.location.href.includes('stolovka.su')
			&& !window.location.href.includes('straus.menu4me-test.ru')
		) {
			ym('reachGoal','added_address');
		}

	} catch (err) {
		dispatch({
			type: SAVE_PROFILE_ERROR,
			payload: err.message
		});

		dispatch(deliveryCycleInfoMessage('location_error', 'Попробуйте снова или измените адрес'))
	}



	// let response_geocode = await request("AddressController", "Geocode", {
	// 	address: dataCompany.address.city.name + "," + dataCompany.address.street + "," + dataCompany.address.house,
	// });
	//
	// let geocode = JSON.parse(response_geocode.data.response.geocode)
	// console.log(geocode, 'geocode')
	//
	// if (geocode.status === "OK" && geocode.results && geocode.results.length) {
	// 	dataCompany.address.lat = geocode.results[0].geometry.location.lat || 90
	// 	dataCompany.address.lng = geocode.results[0].geometry.location.lng || 90
	//
	//
	// } else {
	// 	dispatch(deliveryCycleInfoMessage('location_error', 'Попробуйте снова или измените адрес'))
	// }
};

export const sendCodeToPhone = phone => async dispatch => {
	dispatch({
		type: SEND_CODE_REQUEST
	});

	phone = `+${phone.replace(/[^0-9]/g, "")}`;

	let response = await request("ClientAuthService", "SendCodeToPhone", {
		phone
	});

	if (response.result === 1) {
		localStorage.setItem("loginHash", sha256(phone));
		localStorage.setItem("session", response.data.session);

		dispatch({
			type: SEND_CODE_SUCCESS
		});
	} else {
		dispatch(deliveryCycleInfoMessage('send_code_error', null, 5000));
	}
};

export const createTokenBySession = code => async dispatch => {
	dispatch({
		type: CREATE_TOKEN_REQUEST
	});

	localStorage.setItem("codeHash", sha256(code));
	let response = await request("ClientAuthService", "CreateTokenBySession");

	console.log(response, 'response.data')

	if(response && response.result === 1){
		localStorage.setItem("token", response.data.token);
		localStorage.setItem("refreshToken", response.data.refreshToken);
		localStorage.setItem("typeAuth", "phone");

		dispatch({
			type: CREATE_TOKEN_SUCCESS
		});

		dispatch(getClientProfile());
		dispatch(deliveryCycleInfoMessage('enter_phone'));
		setTimeout(()=>{
			dispatch(deliveryCycleInfoMessage('welcome'));
		}, 550);
        dispatch(getClientProfile());

        if (
			!window.location.href.includes('51obed.ru')
			&& !window.location.href.includes('51.menu4me-test.ru')
			&& !window.location.href.includes('stolovka.su')
			&& !window.location.href.includes('straus.menu4me-test.ru')
		) {
			ym('reachGoal','target_auth');
		}
		setTimeout(() => {
			document.location.reload()
		}, 0)
	} else {
		dispatch(deliveryCycleInfoMessage('error_active_company', 'Вы ввели неверный код. Пожалуйста, проверьте код и введите его снова', 4000));
	}
};

export const sendActivationMail = () => async (dispatch, getState) => {
	let valueAuthEmail = getState().profile.valueAuthEmail;
	let response = await request("RegisterService", "SendActivationMail", { email: valueAuthEmail.email_reg, hostName: 'https://xn--h1abpkdcq6cb.xn--p1ai/'});

	if(response.result === 1){
		let errorsData = response.resultCode ? response.resultCode : 'error';

		dispatch(deliveryCycleInfoMessage(errorsData + "_activation_mail"));
	} else if (response.result === 0) {
		dispatch(deliveryCycleInfoMessage(response.code + "_activation_mail"));
	} else {
		dispatch(deliveryCycleInfoMessage("email_not_found"));
	}
};

export const createTokenByPassword = () => async (dispatch, getState) => {
	dispatch({
		type: AUTH_EMAIL_REQUEST
	});

	let valueAuthEmail = getState().profile.valueAuthEmail;

	localStorage.setItem("loginHash", sha256(valueAuthEmail.email));
	localStorage.setItem("codeHash", sha256(valueAuthEmail.pass));

	let response = await request("ClientAuthService", "CreateTokenByPassword", {
		email: valueAuthEmail.email,
		password: valueAuthEmail.pass
	});

	if(response.result !== 0){
		localStorage.setItem("session", response.data.session);
		localStorage.setItem("token", response.data.token);
		localStorage.setItem("refreshToken", response.data.refreshToken);
		localStorage.setItem("typeAuth", "email");

		dispatch({
			type: AUTH_EMAIL_SUCCESS
		});

		dispatch(getClientProfile());
		dispatch(deliveryCycleInfoMessage('enter_email'));
		setTimeout(()=>{
			dispatch(deliveryCycleInfoMessage('welcome'));
		}, 350);
	} else {
		dispatch(deliveryCycleInfoMessage(response.code + '_error_email'));
	}
};

export const deleteToken = () => async dispatch => {
	try {
		dispatch({
			type: DELETE_TOKEN_REQUEST
		});

		await request("ClientAuthService", "DeleteToken");
		localStorage.removeItem("token");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("session");
		localStorage.removeItem("loginHash");
		localStorage.removeItem("codeHash");
		localStorage.removeItem("typeAuth");

		dispatch(clearCart());
		dispatch(setActiveAccount(null));
		dispatch(setActivePeriodForCart(null));

		dispatch({
			type: DELETE_TOKEN_SUCCESS
		});
		dispatch(deliveryCycleInfoMessage('exit'))
	} catch (err) {
		dispatch({
			type: DELETE_TOKEN_ERROR,
			payload: err.message
		});
	}
};

export const handleReduxFormAuth = (values) => async dispatch => {
	dispatch({
		type: HANDLE_REDUX_FORM_AUTH,
		payload: values
	});
};

export const handleReduxFormReg = (values) => async dispatch => {
	dispatch({
		type: HANDLE_REDUX_FORM_REG,
		payload: values
	});
};

export const handleReduxFormEditCompany = (values) => async dispatch => {
	dispatch({
		type: HANDLE_REDUX_FORM_EDIT_COMPANY,
		payload: values
	});
};

export const handleReduxFormProfileInfo = (values) => async dispatch => {
	let objectSettingsProfile = _.omit(values, function(value) {return value === ''});

	dispatch({
		type: HANDLE_REDUX_FORM_PROFILE_INFO,
		payload: objectSettingsProfile
	});
};

export const initialValuesEditCompanyAction = (values) => async dispatch => {
	let initialValues = {};

	if(values){
		initialValues = {
			name_company: values.name,
			street: values.address.street,
			house: values.address.house,
			floor: values.address.floor,
			flat: values.address.flat,
		};
	}

	dispatch({
		type: HANDLE_REDUX_FORM_COMPANY_INFO_INITIAL_VALUE,
		payload: initialValues
	});
};

export const parseParametersGet = (param) => async (dispatch) => {
	if(param !== ""){
		let arrayActivationLink = [];
		const hrefActivation = param.substring(param.indexOf('&') + 1).split('&');

		_.map(hrefActivation, (item)=>{
			let itemSplit = item.split('=');
			let replaceMail = itemSplit[1].replace("%40", "@");

			arrayActivationLink.push({name: itemSplit[0], value: replaceMail});
		});

		dispatch({
			type: PARAMS_GET_SUCCESS,
			payload: {
				params: arrayActivationLink,
				hash: _.find(arrayActivationLink, (item)=>{return item.name === 'hash' && item.value !== ""})
			}
		});
	}
};

export const activateEmailAccount = (valueData) => async (dispatch) => {
    let response = await request("RegisterService", "ActivateAccount", { ...valueData });

    if(response.result === 1){
        let errorsData = response.resultCode ? response.resultCode : 'error';

        dispatch(deliveryCycleInfoMessage(errorsData + "_reg_mail"));
    } else {
        dispatch(deliveryCycleInfoMessage(response.code + "_reg_mail"));
    }
};

export const changeActiveCompany = (company, isChangeAddress = false) => async (dispatch) => {
	let response = await request("AppDinnerController", "SetUserCurrentCompany", { companyId: company.id });

	if(response.result === 1){
		dispatch({
			type: CHANGE_ACTIVE_COMPANY,
			payload: company
		});

		dispatch(getCompanyProfile());
		if (isChangeAddress) {
			dispatch(deliveryCycleInfoMessage("save_address", `${company.address.street} ${company.address.house}`));
		} else {
			dispatch(deliveryCycleInfoMessage("save"));
		}
	}
};

export const getWallets = (data) => async (dispatch) => {

	let response = await request(
		"WalletsController",
		"GetWallets",
		{clientId: data.clientId, companyId: data.companyId, date: data.date, restId: data.restId}
		);

	if (response.result === 1){
		dispatch({
			type: GET_WALLETS_SUCCESS,
			payload: response.data
		});
	} else {
		dispatch({
			type: GET_WALLETS_ERROR,
			payload: []
		});
	}
};

export const getWalletLimit = (id, date) => async (dispatch) => {

	let response = await request(
		"WalletsController",
		"PaidFromWalletPerDay",
		{walletId: id, date: date}
	);

	if (response.result === 1){
		dispatch({
			type: GET_WALLET_LIMIT_SUCCESS,
			payload: response.data
		});
	} else {
		dispatch({
			type: GET_WALLETS_ERROR,
			payload: ''
		});
	}
};
