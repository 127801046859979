import React, {useState, useEffect, useRef} from "react"
import styled from "styled-components"
import plural from "plural-ru"
import * as _ from "underscore"
import * as moment from 'moment'
import {useDispatch, useSelector} from 'react-redux';
import {clearCheck, clearOrder, getCheck, newSaveOrder, clearPayUrl} from "../../actions/MenuActions"
import {deliveryCycleInfoMessage} from "../../actions/ModalActions";
import {MAIN_COLOR} from "../../utils/constants"
import {request} from "../../utils/request";
import {Link} from "react-router-dom";
import selectedImg from "../../images/check/tick_white.svg"
import addressImg from "../../images/check/address_icon.png"
import courierImg from "../../images/check/courier_icon.png"
import infoImg from "../../images/check/paysystem_info_icon.svg"
import arrowDown from "../../images/arrow_down.svg";
import feeInfoImg from "../../images/check/fee_info.png";
import closeImg from "../../images/close-nav-cross.svg";
import closeModal from "../../images/close-nav-cross.svg";
import ArrowLeft from "../../images/menu/arrow_left.svg";
import Title from "../../components/Title"
import Divider from "../../components/Divider"
import Modal from "../../components/Modal"
import Input from "../../components/Input"
import Button from "../../components/Button"
import { CloseIcon, PointsIcon } from "../../components/Icons"

const Check = props => {
    const {} = props;
    const {createdOrder, payUrl, activePeriod, checkData, checkLoaded} = useSelector(store => store.menu);
    const profile = useSelector(store => store.profile.profile)
    const dispatch = useDispatch()
    const periods = activePeriod ? [[activePeriod.id, activePeriod.from, activePeriod.to]] : []
    const [openPromocodeModal, setOpenPromocodeModal] = useState(false)
    const [openGetCheckModal, setOpenGetCheckModal] = useState(false)
    const [openOrderModal, setOpenOrderModal] = useState(false)
    const [openPointsModal, setOpenPointsModal] = useState(false)
    const [promoCode, setPromoCode] = useState('')
    const [selectedPaySystem, setSelectedPaySystem] = useState('')
    const [selectedWallet, setSelectedWallet] = useState('')
    const [selectedWalletObj, setSelectedWalletObj] = useState(null);
    const [selectedCard, setSelectedCard] = useState('')
    const [bonusPoints, setBonusPoints] = useState('')
    const [payStatus, setPayStatus] = useState(null)
    const [info, setInfo] = useState('')
    const isClickedSubmitButton = useRef(false)
    const test1 = useRef()

    useEffect(() => {
        if (checkLoaded) {
            setOpenGetCheckModal(true)
        }
    }, [checkLoaded])

    useEffect(() => {
        if (createdOrder && !(+selectedPaySystem === 16 || +selectedPaySystem === 2)) {
            closeGetCheckModal()
            setOpenOrderModal(true)
        }
    }, [createdOrder])

    useEffect(() => {
        if (payStatus) {
            dispatch(clearPayUrl())

            if (payStatus === 'success') {
                closeGetCheckModal()
            }
            setOpenOrderModal(true)
        }
    }, [payStatus])

    useEffect(() => {
        if (!openGetCheckModal) {
            dispatch(clearCheck())
        }
    }, [openGetCheckModal])

    useEffect(() => {
        if (!openOrderModal) {
            dispatch(clearOrder())
        }
    }, [openOrderModal])

    useEffect(() => {
        if (selectedWallet && !checkData.promocode.result) {
            setOpenPointsModal(true)
        }
    }, [selectedWallet])

    useEffect(() => {
        if (checkData.promocode && checkData.promocode.result && checkData.wallet) {
            setSelectedWallet(checkData.wallet.id)
            setSelectedWalletObj(checkData.wallet)
        }
    }, [checkData])

    useEffect(()=>{
        resize();
    }, []);

    useEffect(() => {
        if (selectedWalletObj && bonusPoints === '') {
            setBonusPoints(selectedWalletObj.limitAmountOnOrder + '')
        }
    }, [selectedWalletObj])

    const resize = () => {
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }

    const closeGetCheckModal = e => {
        if (e) {
            e.stopPropagation()
        }

        setPromoCode('')
        setSelectedPaySystem('')
        setSelectedWallet('')
        setSelectedWalletObj(null)
        setBonusPoints('')
        setOpenGetCheckModal(false)
    }

    const closeGetCheckModal2 = e => {
        if (e) {
            e.stopPropagation();
        }

        setOpenOrderModal(false)
    }

    const check = (promo, paysys, walletId) => {
        let paysystem = null
        if (checkData.paysystems && checkData.paysystems.length) {
            paysystem = _.find(checkData.paysystems, paysystem => {
                return paysystem.id === paysys
            })
        }

        let wallet = null

        if (walletId) {
            if (checkData.wallet && checkData.wallet.id === walletId) {
                wallet = checkData.wallet
                if (+bonusPoints > 0) {
                    wallet.payAmount = +bonusPoints
                }
            } else {
                if (checkData.wallets && checkData.wallets.length) {
                    wallet = _.find(checkData.wallets, wlt => {
                        return wlt.id === walletId
                    })
                    if (+bonusPoints > 0) {
                        wallet.payAmount = +bonusPoints
                    }
                }
            }
        }

        dispatch(getCheck(
            checkData.date.dateString,
            checkData.owner.id,
            checkData.company.id,
            checkData.period.id,
            checkData.products,
            promo,
            paysystem,
            wallet
        ))
    }

    const onChangePoints = (value, maxPoints) => {
        let regexp = [0 - 9];
        value = value.replace(regexp, '').substring(0, (+maxPoints + '').length);

        if (value === '') {
            setBonusPoints(value)
        } else {
            if (value <= 0) {
                setBonusPoints('1')
            } else if (value >= +maxPoints) {
                setBonusPoints(maxPoints + '')
            } else {
                setBonusPoints(value + '')
            }
        }
    }

    const formatPeriod = (currentPeriod) => {
        let periodArr = []
        if (periods) {
            if (periods.length > 0) {
                periodArr = _.find(periods, period => {
                    return period[0] === currentPeriod
                })
            }
        }
        return 'c ' + periodArr[1] + ' по ' + periodArr[2]
    }

    const saveOrder = () => {
        let wallet = null

        if (selectedWallet) {
            if (checkData.wallet) {
                wallet = checkData.wallet
                if (+bonusPoints > 0) {
                    wallet.payAmount = +bonusPoints
                }
            } else {
                if (checkData.wallets && checkData.wallets.length) {
                    wallet = _.find(checkData.wallets, wlt => {
                        return wlt.id === selectedWallet
                    })
                }
            }
        }

        dispatch(newSaveOrder(
            selectedCard !== 'new_card' ? selectedCard : null,
            checkData.company.id,
            checkData.date.dateString,
            checkData.owner.id,
            selectedPaySystem,
            checkData.period.id,
            checkData.products,
            null, // shortchange
            wallet,
            profile.phone,
        ))
    }

    const parseUrl = (url) => {
        // извлекаем строку из URL
        let queryString = url.split('?')[1]

        // объект для хранения параметров
        let obj = {}

        // если есть строка запроса
        if (queryString) {

            // данные после знака # будут опущены
            queryString = queryString.split('#')[0]

            // разделяем параметры
            let arr = queryString.split('&');

            for (let i = 0; i < arr.length; i++) {
                // разделяем параметр на ключ => значение
                let a = arr[i].split('=')

                // обработка данных вида: list[]=thing1&list[]=thing2
                let paramNum = undefined
                let paramName = a[0].replace(/\[\d*\]/, v => {
                    paramNum = v.slice(1, -1)
                    return ''
                })

                // передача значения параметра ('true' если значение не задано)
                let paramValue = typeof a[1] === 'undefined' ? true : a[1]

                // преобразование регистра
                paramName = paramName.toLowerCase()
                if (typeof paramValue === 'string') {
                    paramValue = paramValue.toLowerCase()
                }

                // если ключ параметра уже задан
                if (obj[paramName]) {
                    // преобразуем текущее значение в массив
                    if (typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]]
                    }
                    // если не задан индекс...
                    if (typeof paramNum === 'undefined') {
                        // помещаем значение в конец массива
                        obj[paramName].push(paramValue)
                    } else { // если индекс задан...
                        // размещаем элемент по заданному индексу
                        obj[paramName][paramNum] = paramValue
                    }
                } else {
                    obj[paramName] = paramValue // если параметр не задан, делаем это вручную
                }
            }
        }

        console.log(obj, "URL_OBJECT")
        return obj
    }

    const removeCard = async (cardId, restId, paysystemId) => {
        let response = await request("PaySystemService", "DeleteCard", {
            cardId,
            account: restId,
            paysystem: {id: paysystemId}
        })

        if (response.result === 1) {
            dispatch(deliveryCycleInfoMessage('remove_from_card'));
            check(promoCode, selectedPaySystem, selectedWallet)
        }

        if (selectedCard === cardId) {
            setSelectedCard('')
        }
    }

    console.log(checkData, 'checkData')

    return (
        <Wrapper>
            <Container>

                <TitleGroup>
                    <BackButton to={`/cart`}>
                        <img src={ArrowLeft} width={42} height={42} alt='' />
                    </BackButton>
                    <Title>Оформление заказа</Title>
                </TitleGroup>

                {openGetCheckModal && <>
                    <Section>
                        {checkData.date && checkData.date.dateString && checkData.period && checkData.period.id ? (
                            <Title>
                                Доставим бесплатно<br/>
                                {moment(checkData.date.dateString).format("DD MMMM, dddd")}<br/>
                                {formatPeriod(checkData.period.id)} по адресу
                            </Title>
                        ) : null}

                        {checkData.company && checkData.company.address ? (
                            <Address>
                                <Title level={2}>{checkData.company.name}</Title>
                                <Text>
                                    {
                                        checkData.company.address.street + ' ' +
                                        checkData.company.address.house +
                                        (checkData.company.address.structure ? (', к.' + checkData.company.address.structure) : '') +
                                        (checkData.company.address.floor ? (', этаж ' + checkData.company.address.floor) : '') +
                                        (checkData.company.address.flat ? (', к.' + checkData.company.address.flat) : '')
                                    }
                                </Text>
                            </Address>
                        ) : null}

                        <Divider/>

                        {/* Промокод кнопка модалки */}
                        {checkData && !checkData.wallet ? (
                            <BlockWrapper>
                                <ButtonLink onClick={() => setOpenPromocodeModal(true)}>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" stroke="#2C2E32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 13.3332C4 12.0761 4 11.4476 4.39052 11.057C4.78105 10.6665 5.40959 10.6665 6.66667 10.6665H25.3333C26.5904 10.6665 27.219 10.6665 27.6095 11.057C28 11.4476 28 12.0761 28 13.3332V15.3332C28 15.9544 28 16.2651 27.8985 16.5101C27.7632 16.8368 27.5036 17.0964 27.1769 17.2317C26.9319 17.3332 26.6213 17.3332 26 17.3332V17.3332C25.3787 17.3332 25.0681 17.3332 24.8231 17.4347C24.4964 17.57 24.2368 17.8296 24.1015 18.1563C24 18.4013 24 18.7119 24 19.3332V23.9998C24 25.2569 24 25.8855 23.6095 26.276C23.219 26.6665 22.5904 26.6665 21.3333 26.6665H10.6667C9.40959 26.6665 8.78105 26.6665 8.39052 26.276C8 25.8855 8 25.2569 8 23.9998V19.3332C8 18.7119 8 18.4013 7.89851 18.1563C7.76318 17.8296 7.50362 17.57 7.17691 17.4347C6.93188 17.3332 6.62126 17.3332 6 17.3332V17.3332C5.37874 17.3332 5.06812 17.3332 4.82309 17.2317C4.49638 17.0964 4.23682 16.8368 4.10149 16.5101C4 16.2651 4 15.9544 4 15.3332V13.3332Z" stroke-width="1.33333"/>
                                        <path d="M6.66699 17.3335H25.3337" stroke-width="1.33333" stroke-linecap="round"/>
                                        <path d="M16 9.3335L16 26.6668" stroke-width="1.33333" stroke-linecap="round"/>
                                        <path d="M16.0003 9.33333L14.8289 8.16193C13.4072 6.74018 11.6739 5.66898 9.76646 5.03316V5.03316C8.24165 4.52489 6.66699 5.65983 6.66699 7.26713V7.50994C6.66699 8.59885 7.36378 9.5656 8.39681 9.90994L10.667 10.6667" stroke-width="1.33333" stroke-linecap="round"/>
                                        <path d="M15.9997 9.33333L17.1711 8.16193C18.5928 6.74018 20.3261 5.66898 22.2335 5.03316V5.03316C23.7584 4.52489 25.333 5.65983 25.333 7.26713V7.50994C25.333 8.59885 24.6362 9.5656 23.6032 9.90994L21.333 10.6667" stroke-width="1.33333" stroke-linecap="round"/>
                                    </svg>
                                    <Text>У меня есть промокод</Text>
                                </ButtonLink>
                                <Modal
                                    isOpen={openPromocodeModal}
                                    onClose={() => setOpenPromocodeModal(false)}
                                >   
                                    <PromocodeCard>
                                        <Title level={2} style={{marginBottom: '16px'}}>Ввести промокод</Title>
                                        <Text>При применении промокода, оплатить<br/>баллами заказ не получится</Text>
                                        <PromocodeCardForm>
                                            <Input
                                                placeholder="Промокод"
                                                value={promoCode}
                                                type="text"
                                                onChange={setPromoCode}
                                            />
                                            <Button
                                                type='primary'
                                                onClick={() => {
                                                    isClickedSubmitButton.current = true
                                                    check(promoCode, selectedPaySystem, selectedWallet)
                                                    setOpenPromocodeModal(false)
                                                }}
                                            >
                                                Применить
                                            </Button>
                                        </PromocodeCardForm>
                                    </PromocodeCard>
                                </Modal>
                            </BlockWrapper>
                        ) : null}
                        
                        {/* Промокод введен */}
                        {(checkData.promocode && checkData.promocode.result && checkData.wallet) ? (
                            <BlockWrapper>
                                <WalletItem>
                                    <WalletInfo>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" stroke="#2C2E32" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 13.3332C4 12.0761 4 11.4476 4.39052 11.057C4.78105 10.6665 5.40959 10.6665 6.66667 10.6665H25.3333C26.5904 10.6665 27.219 10.6665 27.6095 11.057C28 11.4476 28 12.0761 28 13.3332V15.3332C28 15.9544 28 16.2651 27.8985 16.5101C27.7632 16.8368 27.5036 17.0964 27.1769 17.2317C26.9319 17.3332 26.6213 17.3332 26 17.3332V17.3332C25.3787 17.3332 25.0681 17.3332 24.8231 17.4347C24.4964 17.57 24.2368 17.8296 24.1015 18.1563C24 18.4013 24 18.7119 24 19.3332V23.9998C24 25.2569 24 25.8855 23.6095 26.276C23.219 26.6665 22.5904 26.6665 21.3333 26.6665H10.6667C9.40959 26.6665 8.78105 26.6665 8.39052 26.276C8 25.8855 8 25.2569 8 23.9998V19.3332C8 18.7119 8 18.4013 7.89851 18.1563C7.76318 17.8296 7.50362 17.57 7.17691 17.4347C6.93188 17.3332 6.62126 17.3332 6 17.3332V17.3332C5.37874 17.3332 5.06812 17.3332 4.82309 17.2317C4.49638 17.0964 4.23682 16.8368 4.10149 16.5101C4 16.2651 4 15.9544 4 15.3332V13.3332Z" stroke-width="1.33333"/>
                                            <path d="M6.66699 17.3335H25.3337" stroke-width="1.33333" stroke-linecap="round"/>
                                            <path d="M16 9.3335L16 26.6668" stroke-width="1.33333" stroke-linecap="round"/>
                                            <path d="M16.0003 9.33333L14.8289 8.16193C13.4072 6.74018 11.6739 5.66898 9.76646 5.03316V5.03316C8.24165 4.52489 6.66699 5.65983 6.66699 7.26713V7.50994C6.66699 8.59885 7.36378 9.5656 8.39681 9.90994L10.667 10.6667" stroke-width="1.33333" stroke-linecap="round"/>
                                            <path d="M15.9997 9.33333L17.1711 8.16193C18.5928 6.74018 20.3261 5.66898 22.2335 5.03316V5.03316C23.7584 4.52489 25.333 5.65983 25.333 7.26713V7.50994C25.333 8.59885 24.6362 9.5656 23.6032 9.90994L21.333 10.6667" stroke-width="1.33333" stroke-linecap="round"/>
                                        </svg>
                                        <WalletInfoBody>
                                            <Title level={2}>{checkData.wallet.promocode}</Title>
                                            <Text style={{fontSize: '15px'}}>{checkData.wallet.name}</Text>
                                        </WalletInfoBody>
                                    </WalletInfo>
                                    <ActiveWalletCloseButton
                                        onClick={() => {
                                            setSelectedWallet('')
                                            setSelectedWalletObj(null)
                                            setPromoCode('')
                                            setBonusPoints('')
                                            check('', selectedPaySystem, '')
                                        }}
                                    >
                                        <CloseIcon/>
                                    </ActiveWalletCloseButton>
                                </WalletItem>
                            </BlockWrapper>
                        ) : null}

                        {/* Баллы */}
                        {checkData.wallets && checkData.wallets.length && checkData.promocode && !checkData.promocode.result ? (
                            <BlockWrapper>
                                {checkData.wallets.map(wallet => (
                                    <WalletItem>
                                        <WalletInfo>
                                            <PointsIcon/>
                                            <WalletInfoBody>
                                                <Title level={2}>{+wallet.amount}</Title>
                                                <Text style={{fontSize: '15px'}}>{wallet.name}</Text>
                                            </WalletInfoBody>
                                        </WalletInfo>
                                        {selectedWallet === wallet.id && +checkData.prices.total !== +checkData.prices.pay ? (
                                            <ActiveWalletAction>
                                                <ActiveWalletInfo>-{checkData.prices.wallet} потрачено</ActiveWalletInfo>
                                                <ActiveWalletCloseButton
                                                    onClick={() => {
                                                        setBonusPoints('')
                                                        setSelectedWallet('')
                                                        setSelectedWalletObj(null)
                                                        check(promoCode, selectedPaySystem, '')
                                                    }}
                                                >
                                                    <CloseIcon/>
                                                </ActiveWalletCloseButton>
                                            </ActiveWalletAction>
                                        ) : (
                                            <Button
                                                round
                                                onClick={() => {
                                                    setBonusPoints('')
                                                    setSelectedWallet(wallet.id)
                                                    setSelectedWalletObj(wallet)
                                                    setPromoCode('')
                                                }}
                                            >
                                                Потратить
                                            </Button>
                                        )}
                                    </WalletItem>
                                ))}

                                <Modal
                                    isOpen={openPointsModal}
                                    onClose={() => {
                                        setOpenPointsModal(false)
                                        setBonusPoints('')
                                        setSelectedWallet('')
                                        setSelectedWalletObj(null)
                                        check(promoCode, selectedPaySystem, '')
                                    }}
                                >
                                    {selectedWalletObj ? (
                                        <PromocodeCard>
                                            <Title level={2} style={{marginBottom: '16px'}}>Оплатить баллами</Title>
                                            <Text>Вы можете оплатить заказ полностью<br/>баллами с этого счёта</Text>
                                            <WalletInfo>
                                                <PointsIcon/>
                                                <WalletInfoBody>
                                                    <Title level={2}>{+selectedWalletObj.amount}</Title>
                                                    <Text style={{fontSize: '15px'}}>{selectedWalletObj.name}</Text>
                                                </WalletInfoBody>
                                            </WalletInfo>
                                            <PromocodeCardForm>
                                                <Input
                                                    placeholder="Промокод"
                                                    type="number"
                                                    value={bonusPoints === '' ? bonusPoints : (+bonusPoints).toFixed(0)}
                                                    onChange={value => onChangePoints(value, selectedWalletObj.limitAmountOnOrder)}
                                                />
                                                <Button
                                                    type='primary'
                                                    disabled={+bonusPoints === 0}
                                                    onClick={() => {
                                                        if (+bonusPoints > 0) {
                                                            setOpenPointsModal(false)
                                                            check(promoCode, selectedPaySystem, selectedWallet)
                                                        }
                                                    }}
                                                >
                                                    Применить
                                                </Button>
                                            </PromocodeCardForm>
                                            <PointsModalText>
                                                Вы можете использовать
                                                только {`${plural(+selectedWalletObj.limitAmountOnOrder, '%d балл', '%d балла', '%d баллов')}`} для
                                                оплаты заказа частично
                                            </PointsModalText>
                                        </PromocodeCard>
                                    ) : null}
                                </Modal>
                            </BlockWrapper>
                        ) : null}

                        <BlockWrapper>
                            <BlockTitle>Рубли</BlockTitle>
                            {checkData.paysystemsComment ? (
                                <PaysystemsComment>
                                    <img src={infoImg} alt="i" width={16} height={16}/>
                                    <PaysystemsCommentText>{checkData.paysystemsComment}</PaysystemsCommentText>
                                </PaysystemsComment>
                            ) : null}
                            <PaySystems>
                                {checkData.paysystems && checkData.paysystems.map(paysystem => {
                                    if (paysystem.isDisabled && selectedPaySystem === paysystem.id) {
                                        setSelectedPaySystem('')
                                    }
                                    return (
                                        <PaySystem
                                            key={'paysystem_' + paysystem.id}
                                            disabled={paysystem.isDisabled}
                                            onClick={() => {
                                                if (!paysystem.isDisabled) {
                                                    if (checkData.promocode && !checkData.promocode.code) {
                                                        setSelectedPaySystem(paysystem.id)
                                                        setPromoCode('')
                                                        //check('', paysystem.id, selectedWallet)
                                                    }
                                                    if (checkData.promocode && checkData.promocode.code) {
                                                        setSelectedPaySystem(paysystem.id)
                                                        //check(promoCode, paysystem.id, selectedWallet)
                                                    }
                                                }
                                            }}
                                        >
                                            <PaySystemRadio active={selectedPaySystem === paysystem.id}>
                                                {selectedPaySystem === paysystem.id && (
                                                    <img src={selectedImg} alt="✔" width={10} height={10}/>
                                                )}
                                            </PaySystemRadio>
                                            <PaySystemName>
                                                {+paysystem.id === 1 ? 'Наличными' : 'Картой онлайн'}
                                            </PaySystemName>
                                        </PaySystem>
                                    )
                                })}
                            </PaySystems>
                            {selectedPaySystem && +selectedPaySystem !== 1 ? (
                                <Cards>
                                    {(checkData.cards && checkData.cards.length > 0) ? checkData.cards.map(card => {
                                        return (
                                            <CardWrapper key={card.id}>
                                                <Card onClick={() => {
                                                    setSelectedCard(card.id)
                                                }}>
                                                    <CardRadio active={selectedCard === card.id}>
                                                        {selectedCard === card.id ? (
                                                            <CardRadioInner/>
                                                        ) : null}
                                                    </CardRadio>
                                                    <CardName>{card.name}</CardName>
                                                </Card>
                                                <RemoveCardButton onClick={() => removeCard(card.id, checkData.owner.id, card.paysystemId)}>
                                                    <img src={closeImg} width={12} alt="x" />
                                                </RemoveCardButton>
                                            </CardWrapper>
                                        )
                                    }) : null}
                                    <Card key={'new_card'} onClick={() => {
                                        setSelectedCard('new_card')
                                    }}>
                                        <CardRadio active={selectedCard === 'new_card'}>
                                            {selectedCard === 'new_card' ? (
                                                <CardRadioInner/>
                                            ) : null}
                                        </CardRadio>
                                        <CardName style={{letterSpacing: 'normal'}}>Новая карта</CardName>
                                    </Card>
                                </Cards>
                            ) : null}
                        </BlockWrapper>
                    </Section>

                    <Section>
                        {checkData.prices ? (
                            <>
                                {checkData.products && checkData.prices.cart ? (
                                    <DeliveryWrapper>
                                        <TitleWrapper>
                                            <BlockTitle style={{marginBottom: 0}}>
                                                {`${plural(checkData.products.length, '%d товар', '%d товара', '%d товаров')}`}
                                            </BlockTitle>
                                            <BlockTitle style={{marginBottom: 0}}>
                                                {+checkData.prices.cart + ' ₽'}
                                            </BlockTitle>
                                        </TitleWrapper>
                                    </DeliveryWrapper>
                                ) : null}
                                <DeliveryWrapper>
                                    <TitleWrapper>
                                        <FeeBlockTitle>
                                            <BlockTitle style={{marginBottom: 0, marginRight: 8}}>
                                                Доставка
                                            </BlockTitle>
                                            {checkData.prices.isPayedDelivery && +checkData.prices.delivery > 0 ? (
                                                <img src={feeInfoImg} alt="" width={16} height={16} onClick={() => {
                                                    setInfo('delivery')
                                                }}/>
                                            ) : null}
                                        </FeeBlockTitle>
                                        <BlockTitle
                                            style={{
                                                marginBottom: 0,
                                                color: (checkData.prices.isPayedDelivery || +checkData.prices.delivery === 0)
                                                    ? '#A4A4A4'
                                                    : '#161616',
                                                textDecoration: (checkData.prices.isPayedDelivery && +checkData.prices.delivery > 0)
                                                    ? 'line-through'
                                                    : 'none',
                                            }}
                                        >
                                            {+checkData.prices.delivery} ₽
                                        </BlockTitle>
                                    </TitleWrapper>
                                </DeliveryWrapper>
                                <DeliveryWrapper>
                                    <TitleWrapper>
                                        <FeeBlockTitle>
                                            <BlockTitle style={{marginBottom: 0, marginRight: 8}}>
                                                Сервисный сбор
                                            </BlockTitle>
                                            <img src={feeInfoImg} alt="" width={16} height={16} onClick={() => {
                                                setInfo('service')
                                            }}/>
                                        </FeeBlockTitle>
                                        <BlockTitle
                                            style={{
                                                marginBottom: 0,
                                                color: (checkData.prices.isPayedServiceFee || +checkData.prices.serviceFee === 0)
                                                    ? '#A4A4A4'
                                                    : '#161616',
                                                textDecoration: (checkData.prices.isPayedServiceFee && +checkData.prices.serviceFee > 0)
                                                    ? 'line-through'
                                                    : 'none',
                                            }}
                                        >
                                            {+checkData.prices.serviceFee + ' ₽'}
                                        </BlockTitle>
                                    </TitleWrapper>
                                </DeliveryWrapper>
                                {checkData.prices.wallet && !openPointsModal ? (
                                    <DeliveryWrapper>
                                        {checkData.promocode && checkData.promocode.result === 1 ? (
                                            <TitleWrapper>
                                                <BlockTitle style={{marginBottom: 0}}>
                                                    Скидка по промокоду
                                                </BlockTitle>
                                                <BlockTitle style={{marginBottom: 0}}>
                                                    -{+checkData.prices.wallet} ₽
                                                </BlockTitle>
                                            </TitleWrapper>
                                        ) : (
                                            <TitleWrapper>
                                                <BlockTitle style={{marginBottom: 0}}>
                                                    Баллы
                                                </BlockTitle>
                                                <Points>
                                                    <BlockTitle style={{marginBottom: 0}}>
                                                        -{+checkData.prices.wallet}
                                                    </BlockTitle>
                                                    <PointsIcon style={{marginLeft: 6}}>
                                                        <PointsIconText>Б</PointsIconText>
                                                    </PointsIcon>
                                                </Points>
                                            </TitleWrapper>
                                        )}
                                    </DeliveryWrapper>
                                ) : null}
                            </>
                        ) : null}

                        {checkData.prices ? (
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Total>
                                    {(checkData.prices.total && +checkData.prices.total !== +checkData.prices.pay) ? (
                                        <Points>
                                            <Title
                                                style={{
                                                    marginBottom: 0,
                                                    marginRight: 10,
                                                    color: '#A4A4A4',
                                                    textDecoration: 'line-through',
                                                }}
                                            >
                                                {+checkData.prices.total} ₽
                                            </Title>
                                            <Title style={{marginBottom: 0}}>
                                                {+checkData.prices.pay} ₽
                                            </Title>
                                        </Points>
                                    ) : (
                                        <Title style={{marginBottom: 0}}>
                                            {+checkData.prices.pay} ₽
                                        </Title>
                                    )}
                                </Total>
                                {checkData.prices.pay ? (
                                    <Button
                                        type='primary'
                                        disabled={!((+selectedPaySystem === 2 || +selectedPaySystem === 16) && selectedCard || +selectedPaySystem === 1)}
                                        onClick={() => {
                                            if (selectedPaySystem) {
                                                if ((+selectedPaySystem === 2 || +selectedPaySystem === 16) && selectedCard || +selectedPaySystem === 1) {
                                                    saveOrder()
                                                }
                                            }
                                        }}
                                    >
                                        <>
                                            {
                                                (+selectedPaySystem === 2 || +selectedPaySystem === 16)
                                                    ? 'Оплатить онлайн ' + checkData.prices.pay + ' ₽'
                                                    : 'Отправить заказ на ' + checkData.prices.pay + ' ₽'
                                            }
                                        </>
                                    </Button>
                                ) : (
                                    <Button
                                        type='primary'
                                        disabled={!(checkData.prices && !checkData.prices.pay)}
                                        onClick={() => {
                                            if (checkData.prices && !checkData.prices.pay) {
                                                saveOrder()
                                            }
                                        }}
                                    >
                                        Оплатить заказ баллами
                                    </Button>
                                )}
                            </div>
                        ) : null}

                        <PaymentProblemsButton>
                            <SberLink href="http://www.sberbank.ru/ru/certificates" target="_blank">
                                Проблемы с оплатой онлайн?
                            </SberLink>
                        </PaymentProblemsButton>

                        <Modal
                            isOpen={info}
                            onClose={e => {
                                e.stopPropagation()
                                setInfo('')
                            }}
                        >
                            <ModalWrapper onClick={e => e.stopPropagation()}>
                                <PointsModal>
                                    <PointsModalTitle style={{marginBottom: 20}}>
                                        {info === 'service' ? 'Сервисный сбор' : info === 'delivery' ? 'Доставка' : ''}
                                    </PointsModalTitle>
                                    {(info === 'service' && checkData.prices.isPayedServiceFee && +checkData.prices.serviceFee > 0) ? (
                                        <PointsModalText style={{marginBottom: 10}}>
                                            Вы уже совершали заказ сегодня, поэтому платить повторно за сервисный сбор не нужно
                                        </PointsModalText>
                                    ) : null}
                                    {info === 'service' ? (
                                        <PointsModalText style={{marginBottom: 0}}>
                                            Мы развиваемся и растём. Сервисный сбор помогает сделать сервис лучше для вас. Если вы делаете несколько заказов в день, то сервисный сбор добавляется только в ваш первый заказ
                                        </PointsModalText>
                                    ) : null}
                                    {info === 'delivery' ? (
                                        <PointsModalText style={{marginBottom: 0}}>
                                            Доставка уже оплачена
                                        </PointsModalText>
                                    ) : null}
                                </PointsModal>
                            </ModalWrapper>
                        </Modal>
                    </Section>
                </>} 
            </Container>

            {/* Заказ оформлен */}
            {openOrderModal && createdOrder ?
                !payStatus || payStatus === 'success' ? (
                    <SuccessContainer>
                        <svg width="159" height="139" viewBox="0 0 159 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.2" d="M9.24512 134.888C9.24512 132.83 40.6963 131.158 79.4997 131.158C118.303 131.158 149.754 132.83 149.754 134.888C149.754 136.945 118.303 138.617 79.4997 138.617C40.6963 138.617 9.24512 136.951 9.24512 134.888Z" fill="url(#paint0_radial_289_6685)"/>
                            <path d="M101.054 0L45.4604 59.8835L10.8341 38.8564L0 48.4389L46.6429 93.2891L107.416 4.98396L101.054 0Z" fill="url(#paint1_linear_289_6685)"/>
                            <path d="M152.245 0.391113L97.2859 60.8175L81.9737 51.9409L71.375 67.8266L98.2275 94.2231L159 5.68997L152.245 0.391113Z" fill="#D9D9D9"/>
                            <defs>
                                <radialGradient id="paint0_radial_289_6685" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(79.5265 134.499) rotate(180) scale(49.935 2.62765)">
                                    <stop offset="0.14"/>
                                    <stop offset="1" stop-color="#DAE5DF" stop-opacity="0"/>
                                </radialGradient>
                                <linearGradient id="paint1_linear_289_6685" x1="99.7372" y1="10.0033" x2="0.619638" y2="71.0293" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#78BA67"/>
                                    <stop offset="1" stop-color="#77CA62"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <Title>Заказ успешно оформлен на сумму {+createdOrder.priceResult} ₽</Title>
                        
                        <Time style={{marginBottom: 30}}>
                            <img src={courierImg} alt="" width={40} height={40}/>
                            <TimeInfo>
                                <TimeText>
                                    {moment(createdOrder.date.dateString).format("D MMMM, dddd")}
                                </TimeText>
                                <TimeText>
                                    {formatPeriod(createdOrder.period.id)}
                                </TimeText>
                                <TimeText>
                                    {!createdOrder.prices.delivery ? (
                                        'Доставка бесплатно'
                                    ) : (
                                        'Доставка ' + createdOrder.prices.delivery + '₽'
                                    )}
                                </TimeText>
                            </TimeInfo>
                        </Time>

                        <ButtonGroup>
                            <Link to={'/my-orders'} style={{textDecoration: 'none'}}><Button>Мои заказы</Button></Link>
                            <Link to={'/'} style={{textDecoration: 'none'}}><Button type='primary'>На главную</Button></Link>
                        </ButtonGroup>
                    </SuccessContainer>
                ) : (
                    <ModalWrapper onClick={e => e.stopPropagation()}>
                        <CheckTop style={{paddingBottom: 30}}>
                            <ModalTitle>
                                Ошибка оформления заказа
                            </ModalTitle>
                            <OwnerTitle>
                                <OwnerName style={{maxWidth: '100%'}}>Заказ не оплачен</OwnerName>
                            </OwnerTitle>
                            <Link to={'/'} style={{textDecoration: 'none'}}><Button type='primary'>На главную</Button></Link>
                        </CheckTop>
                    </ModalWrapper>
                )
            : null}

            {payUrl &&
                <FullScreenWrapper onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <p
                        onClick={(e) => dispatch(clearPayUrl())}
                        style={{position: 'absolute', top: 8, right: 20, cursor: 'pointer', fontSize: 20}}
                    >
                        x
                    </p>
                    <iframe ref={test1} id="payFrame" src={payUrl} frameBorder="0" width="100%" height="100%" onLoad={e => {
                        try {
                            const target = e.currentTarget.contentWindow.location.href
                            console.log(target, 'TARGET')
                            if (parseUrl(target).success) {
                                setPayStatus('success')
                            } else {
                                setPayStatus('fail')
                            }
                        } catch (e) {
                            console.log(e, 'ERROR')
                        }
                    }}/>
                </FullScreenWrapper>
            }
        </Wrapper>
    )
}

export default Check;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 120px;
`
const Container = styled.div`
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 10px;
`
const Section = styled.div`
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin-bottom: 24px;
    padding: 64px;
    border-radius: 32px;
`
const TitleGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 48px 0 32px;
`
const Text = styled.p`
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    letter-spacing: -0.44px;
    color: #000000;
    margin: 0;
`
const ButtonLink = styled.p`
    display: flex;
    align-items: center;
    gap: 5px;
    color: #000000;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
        p, svg {
            transition: all .3s;
            color: #017ec1;
            stroke: #017ec1;
        }
    }
`
const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`
const PromocodeCard = styled.div`
    display: flex;
    flex-direction: column;
`
const PromocodeCardForm = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
`
const WalletItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`
const WalletInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`
const WalletInfoBody = styled.div`
    display: flex;
    flex-direction: column;
`
const ActiveWalletAction = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`
const ActiveWalletInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 26px;
    height: 60px;
    width: fit-content;
    background: #CCE1C5;
    border-radius: 60px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    letter-spacing: -0.44px;
`
const ActiveWalletCloseButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    min-width: 60px;
    background: #CCE1C5;
    border-radius: 60px;
    cursor: pointer;
    transition: all .3s;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    letter-spacing: -0.44px;
    &:hover {
        background: #A4CFA1;
    }
`
const FullScreenWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    overflow:hidden;
`
const CloseButton = styled.p`
    position: absolute;
    top: 8px;
    right: 20px;
    cursor: pointer;
    font-size: 20px;
    color: white;
`
const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding: 0 10px;
`
const ModalWrapper = styled.div`
	background: #fff;
	border-radius: 6px;
    overflow: hidden;
    width: 100%;
    
    @media (max-width: 992px) {
        border-radius: 0;
    }
`

const CheckTop = styled.div`
    width: 100%;
    transition: all .2s linear;
    padding: 40px 30px 0;
    overflow: hidden;
    position: relative;

    & p {
        font-family: "Roboto", sans-serif;
        color: #161616;
    }

    @media (max-width: 992px) {
        padding: 25px 37px;
    }
`
const BackButton = styled(Link)`
  display: flex;
  width: 42px;
  height: 42px;
`;
const ModalTitle = styled.p`
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 21px;
`

const BlockWrapper = styled.div`
    margin-bottom: 32px;
`

const BlockTitle = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
`

const PromoLine = styled.div`
    display: flex;
    align-items: center;
`

const PromoInput = styled.input`
    width: 200px;
    margin-right: 43px;
    padding: 14px 16px;
    background: #eeeeee;
    border: none;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #161616;
    border-radius: 6px;
    
    @media (max-width: 360px) {
        width: 140px;
    }
`

const UsePromo = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A18CD1 !important;
    margin: 0;
    cursor: pointer;
`

const Wallet = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const WalletRadio = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({active}) => active ? MAIN_COLOR : '#E6E9F0'};
    width: 22px;
    height: 22px;
    margin-right: 11px;
    border-radius: 6px;
`

const WalletName = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    max-width: calc(100% - 110px);
`

const Points = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`

const PointsCount = styled.p`
    font-size: 16px;
    line-height: 19px;
    margin: 0 6px 0;
`

// const PointsIcon = styled.div`
//     width: 16px;
//     height: 16px;
//     border-radius: 666px;
//     background: ${MAIN_COLOR};
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 1.5px;
// `

const PointsIconText = styled.p`
    font-size: 10px;
    font-weight: 700;
    color: #ffffff !important;
    margin: 0;
`

const PointsModal = styled.div`
    width: 100%;
    transition: all .2s linear;
    padding: 22px 26px 26px;
    overflow: hidden;
    position: relative;

    & p {
        font-family: "Roboto", sans-serif;
        color: #161616;
    }

    @media (max-width: 992px) {
        padding: 25px 37px;
    }
`

const PointsModalTitle = styled(BlockTitle)`
    margin-bottom: 6px;
`

const PointsModalAmount = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 17px;
`

const PointsModalInput = styled.input`
    width: 100%;
    margin-bottom: 8px;
    padding: 10px 12px;
    background: #E6E9F0;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.05);
    border: none;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #161616;
    border-radius: 6px;
`

const PointsModalText = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 22px;
`

const OrderButton = styled.div`
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: ${({active}) => active ? MAIN_COLOR : '#EEEEEE'};
    cursor: ${({active}) => !active ? 'not-allowed' : 'pointer'};

    & p {
        color: ${({active}) => active ? '#ffffff !important' : '#999999 !important'};
    }
`

const OrderButtonText = styled.p`
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin: 0;
`

const PaySystems = styled.div`
    display: flex;
`

const PaySystem = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    opacity: ${({disabled}) => disabled ? '0.5' : '1'};
    cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
`

const PaySystemRadio = styled(WalletRadio)`
    border-radius: 66px;
`

const PaySystemName = styled(WalletName)`
    max-width: none;
`

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
`

const CardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
`

const Card = styled.div`
    display: inline-flex;
    /*display: flex;*/
    align-items: center;
    /*margin-bottom: 15px;*/
    cursor: pointer;
`

const RemoveCardButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
`

const CardRadio = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: ${({active}) => active ? '0' : '1px solid #CACACA'};
    background: ${({active}) => active ? '#67B8EE' : '#DADADA'};
`

const CardRadioInner = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #ffffff;
`

const CardName = styled.p`
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.12em;
    color: #2D2D2D;
    margin: 0 0 0 10px;
`

const Address = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 32px;
`

const AddressInfo = styled.div`
    margin-left: 8px;
    max-width: calc(100% - 48px);
`

const AddressText = styled.p`
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;

    &:last-child {
        marin-bottom: 0;
    }
`

const TimeWrapper = styled.div`
    margin-bottom: 40px;
`

const Time = styled(Address)``

const TimeInfo = styled(AddressInfo)``

const TimeText = styled(AddressText)``

const DeliveryWrapper = styled.div`
    margin-bottom: 18px;
`

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const FeeBlockTitle = styled.div`
    display: flex;
    align-items: center;
`

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
`

const DeliveryInfo = styled.p`
   margin-bottom: 0;
   margin-left: 6px;
   font-weight: 400;
   font-size: 11px;
   line-height: 14px;
`

const CheckBottom = styled.div`
    width: 100%;
    padding: 25px 30px 32px;
    border-top: 2px solid #E6E9F0;
    
    background-color: #ffffff;
    position: sticky;
    bottom: 0;

    & p {
        font-family: "Roboto", sans-serif;
        color: #161616;
    }
`

const Total = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const TotalText = styled(AddressText)`
    margin-bottom: 0;
`

const PaysystemsComment = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`

const PaysystemsCommentText = styled.p`
    font-size: 13px;
    line-height: 15px;
    margin: 0 0 0 6px;
`

const OwnerTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`

const OwnerName = styled.p`
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 12px;
    max-width: calc(100% - 92px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Products = styled.div`
    margin-bottom: 30px;
`

const CreatedOrderItem = styled.div`
    margin-bottom: 15px;
    
    &:last-child{
		margin-bottom: 20px;
	}
`

const Product = styled.div`
    display: flex;
    justify-content: space-between;
`

const Modifier = styled.div`
    & > span {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        line-height: 18px;
        color: #161616;
        padding-left: 10px;
    }
`

const ProductName = styled.p`
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 0;
    max-width: calc(100% - 90px);
`

const ProductPrice = styled.p`
    font-size: 13px;
    line-height: 15px;
    margin: 2px 0 0 auto;
`

const OpenProductsButton = styled.div`
    width: 40px;
    height: 40px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const Arrow = styled.img`
    transform: ${({opened}) => opened ? 'rotate(0deg)' : 'rotate(90deg)'};
    transition: transform 0.5s;
`

const Hr = styled.div`
    width: 100%;
    height: 1px;
    background: #E6E9F0;
    margin-bottom: 30px;
`

const PaymentProblemsButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    margin: 16px auto 0;
`

const SberLink = styled.a`
    border-bottom: 1px dashed ${MAIN_COLOR};
    font-size: 14px;
    line-height: 17px;
	  color: ${MAIN_COLOR};

    &:hover {
        text-decoration: none;
        color: ${MAIN_COLOR};
    }
`
