import React from 'react'
import ym from "react-yandex-metrika";
import styled from 'styled-components'
import BlueProm from './imagesForPromo/BlueProm.jpg'
import YellowProm from './imagesForPromo/YellowProm.jpg'
import GreenProm from './imagesForPromo/GreenProm.jpg'
import PinkProm from './imagesForPromo/PinkProm.jpg'
import Logo from './imagesForPromo/logo.svg'
import Site from './imagesForPromo/Suite.png'
import Cupp from './imagesForPromo/Cupp.svg'
import Cupp2 from './imagesForPromo/Cupp2.svg'
import AppStore from './imagesForPromo/AppStore.png'
import PlayMarket from './imagesForPromo/PlayMarket.png'
// import Montserrat from '../../public/fonts/Montserrat-Italic-VariableFont_wght.ttf';
const Landing = (props) => {
  
const WINDOWKITCHENS =
!window.location.href.includes('51obed.ru')
&& !window.location.href.includes('51.menu4me-test.ru')
&& !window.location.href.includes('stolovka.su')
&& !window.location.href.includes('straus.menu4me-test.ru')
// && !window.location.href.includes('so.menu4me-test.ru')
  const MetricAppStore=()=>{
    if (WINDOWKITCHENS){
    ym('reachGoal','click_to_app_store');
  }
  }
  const MetricPlayMarket=()=>{
    if (WINDOWKITCHENS){
    ym('reachGoal','click_to_google_play');
  }
  }
  const MetricAPK=()=>{
    if (WINDOWKITCHENS){
      ym('reachGoal','click_to_download_apk');
    }
  }
  const MetricSite=()=>{
    if (WINDOWKITCHENS){
    ym('reachGoal','click_to_site');  
  }
  }
  return (
    <Wrapper>
            <Helpers>
              <img  src={Logo}/>
              <div className='Tittle' >Эконом-доставка<br/>из столовых, кафе и ресторанов</div>
              {props.cuppon &&
              (
                props.cuppon === 'Promo' ?
                (
               <img style={{paddingBottom:24}} src={Cupp} />
                )
                :
                (
               <img style={{paddingBottom:24}} src={Cupp2} />
                )
              )
              }
            </Helpers>
            <Btn>
              <a target='_blank'  href='https://itunes.apple.com/us/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9-%D0%BE%D1%84%D0%B8%D1%81-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D0%B5%D0%B4%D0%BE%D0%B2/id1361986950?l=ru&ls=1&mt=8'style={{width:'100%',padding: 0}} >
                <button style={{padding: 0,width:'100%',border:'none',background:'#ffffff'}} onClick={MetricAppStore}>
                  <div className='AppStore' style={{marginBottom:8}}>
                  <ImgCompany  src={AppStore}/> Загрузить в App Store
                  </div>
                </button>
              </a>
              <a  target='_blank'  href='https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice'style={{width:'100%',padding: 0}} >
                <button style={{padding: 0,width:'100%',border:'none',background:'#ffffff'}} onClick={MetricPlayMarket}>
                  <div className='AppStore' style={{marginBottom:8}}>
                     <ImgCompany  src={PlayMarket}/> Загрузить в Google Play
                  </div>
                </button>
              </a>
              <a target='_blank' href='https://menuforme.online/files/temp/sitiy_release.apk' style={{width: '100%', padding: 0}}>
                <button style={{padding: 0, width: '100%', border: 'none', background: '#ffffff'}} onClick={MetricAPK}>
                  <div className='AppStore'>
                    Скачать APK-файл
                  </div>
                </button>
              </a>
              <a  target='_blank'  href='/'style={{width:'100%',padding: 0,marginTop:24}} >
                <button style={{padding: 0,width:'100%',border:'none',background:'#ffffff'}} onClick={MetricSite}>
                <SuiteHref >
                  <ImgCompany style={{width:28,height:28}} src={Site}/> <div className='tittle'>Посмотреть на сайте</div>
               </SuiteHref>
                </button>
              </a>
            </Btn>
            <ImgSide>
            <ImgItem src={BlueProm}/>
            <ImgItem src={YellowProm}/>
            <ImgItem src={GreenProm}/>
            <ImgItem src={PinkProm}/>
            </ImgSide>

            <Foot>
              <p>ООО "Бизнес-Софт"</p>
              <p className='foot' >
                <a className='tel' href="tel:+7-800-200-69-24">+7-800-200-69-24</a>
              </p>
            </Foot>
    </Wrapper>
  )
}

export default Landing

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background: #FFFFFF;
padding-left: 19px;
padding-right: 21px;
/* padding-top: 47px; */
`

const Helpers =styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-bottom: 25px;
 .Tittle{
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  letter-spacing: 0.0779122px;
   padding-top:15px;
   padding-bottom: 24px;
   /* padding-left: 23px; */
   @media (min-width:300px) {
    width: 271px;
   }
   @media (max-width:300px) {
    width: 220px;
   }
 }
    
`
const Btn = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width:100%;
max-width: 480px;
 .AppStore{
    display: flex;
    width:100%;
    align-items: center;
    justify-content: center;
    background-color:#000000;
    border-radius: 16px;
    font-weight: 400;
    /* font-size: 18px; */
    line-height: 116.9%;
    letter-spacing: 0.160192px;
    color: #FFFFFF;
    font-style: normal;
    font-family: 'Montserrat';
    @media (min-width:300px) {
      padding:18px 32px 18px 32px;
   }
   @media (max-width:335px) {
      padding:9px 16px 9px 16px;
   }
 }
`
const ImgSide = styled.div`
display: flex;
flex-direction: column;
margin-top: 30px;
margin-bottom: 32px;
`
const Foot = styled.div`
width: 100vw;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color:#2E2E2E;
font-family: 'Montserrat';
font-style: normal;
color:#ffffff;
letter-spacing: 0.160192px;
font-weight: 400;
font-size: 18px;
line-height: 130%;
padding-top:20px;

.foot{
}
.tel{
color:#ffffff;
}
`
const ImgItem = styled.img`
width:100%;
margin-bottom: 16px;
/* @media (min-width:300px) {
  width: 320px;
}
@media (max-width:300px) {
  width: 240px;
} */
`
const SuiteHref= styled.div`
display: flex;
align-items: center;
justify-content: center;
  .tittle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 116.9%;
    letter-spacing: 0.160192px;
    text-decoration-line: underline;
    color: #406FCD;
  }
`
const ImgCompany = styled.img`

margin-right:8px;
@media (min-width:300px) {
  width:24px;
  height:24px;
   }
   @media (max-width:300px) {
    width:16px;
    height:16px;
   }
`