import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import plural from 'plural-ru';
import {deliveryCycleInfoMessage} from '../../../actions/ModalActions';
import {getDay, getPeriod, setActiveDay, setActivePeriod, getMenuNew} from '../../../actions/MenuActions';
import Tick from '../../../images/menu/tick_pink.svg';
import Fire from '../../../images/menu/fire.svg';
import Lightning from '../../../images/menu/lightning.png';
import {MAIN_COLOR} from '../../../utils/constants';

const Periods = props => {
  const {periods} = props;

  const [periodsArr, setPeriodsArr] = useState([]);
  const [nextPeriodIndex, setNextPeriodIndex] = useState(-1);
  // const [isOnlyOnePeriod, setIsOnlyOnePeriod] = useState(false);

  const {activeDay, activePeriod} = useSelector(store => store.menu);

  const dispatch = useDispatch();

  useEffect(() => {
    let arr = [];
    let periodIds = [];
    if (Array.isArray(periods) && periods.length > 0) {
      periods.forEach(date => {
        if (Array.isArray(date.periods) && date.periods.length > 0) {
          date.periods.forEach(period => {
            if (periodIds.length < 2 && !periodIds.includes(+period.id)) {
              periodIds.push(+period.id);
            }
            arr.push({dateString: date.dateString, dateValue: date.dateValue, ...period});
          });
        }
      });
    }
    // setIsOnlyOnePeriod(periodIds.length < 2);
    arr.sort((a, b) => {
      return (a.dateValue + +a.fromValue) - (b.dateValue + +b.fromValue);
    });
    setPeriodsArr(arr);
  }, [periods]);

  useEffect(() => {
    setNextPeriodIndex(periodsArr.findIndex(i => !+i.isDisabled));
  }, [periodsArr]);

  const submit = period => {
    dispatch(getDay(moment(period.dateString).format('YYYY-MM-DD')));
    dispatch(getPeriod('1'));

    dispatch(setActiveDay(moment(period.dateString).format('YYYY-MM-DD')));
    dispatch(setActivePeriod({...period, dateString: undefined, dateValue: undefined}));
  };

  const replaceStr = (str) => {
    return str.replace('понедельник', 'Пн')
      .replace('вторник', 'Вт')
      .replace('среда', 'Ср')
      .replace('четверг', 'Чт')
      .replace('пятница', 'Пт')
      .replace('суббота', 'Сб')
      .replace('воскресенье', 'Вс')
  }

  return (
    <Wrapper>
      {periodsArr.map((period, index) => {
        let isUnpublished = +period.isDisabled && period.isDisabledReason === 'На это время меню не опубликовано';
        return (
          <PeriodItem
            key={'period_item_' + index}
            isLast={index === (periodsArr.length - 1)}
            isActive={moment(period.dateString).isSame(moment(activeDay), 'day')}
            onClick={() => {
              if (isUnpublished) {
                dispatch(deliveryCycleInfoMessage(
                  'unpublished_period',
                  'Вы можете сделать заказ на другой день'
                ));
              } else {
                submit(period);
              }
            }}
          >
            <div>
              <PeriodTitle>
                <PeriodDay isDisabled={+period.isDisabled}>
                  {moment(period.dateString).isSame(moment(), 'day')
                    ? `Сегодня, ${moment(period.dateString).format('D MMMM')}`
                    : moment(period.dateString).isSame(moment().add('days', 1), 'day')
                      ? `Завтра, ${moment(period.dateString).format('D MMMM')}`
                      : moment(period.dateString).isSame(moment().subtract('days', 1), 'day')
                        ? `Вчера, ${moment(period.dateString).format('D MMMM')}`
                        : replaceStr(moment(period.dateString).format('dddd, D MMMM'))
                  }
                </PeriodDay>
                {moment(period.dateString).isSame(moment(activeDay), 'day') && (
                  <img src={Tick} style={{marginLeft: 16}} width={15} height={14} alt='' />
                )}
              </PeriodTitle>
              {(index === nextPeriodIndex && moment(period.dateString).add(period.orderTo).valueOf() > moment().valueOf()) ? (
                <NextPeriodRow>
                  {(moment(period.dateString).add(period.orderTo).valueOf() - moment().valueOf()) < 1200000 ? (
                    <>
                      <img src={Fire} width={10} height={13} alt='' />
                      <NextPeriodText>
                        Осталось {`${plural(
                          Math.ceil((moment(period.dateString).add(period.orderTo).valueOf() - moment().valueOf()) / 60000),
                          '%d минута',
                          '%d минуты',
                          '%d минут'
                        )}`} для заказа
                      </NextPeriodText>
                    </>
                  ) : (
                    <NearestPeriod>
                      <img src={Lightning} width={14} height={14} alt='' />
                      <NearestPeriodText>Ближайшая доставка</NearestPeriodText>
                    </NearestPeriod>
                  )}
                </NextPeriodRow>
              ) : (
                moment(period.dateString).isSame(moment(), 'day') &&
                moment().isAfter(moment(period.dateString).add(period.orderTo))
              ) ? (
                <NextPeriodRow>
                  <NextPeriodText style={{marginLeft: 0, color: MAIN_COLOR}}>
                    Приём заказов завершён в {period.orderTo}
                  </NextPeriodText>
                </NextPeriodRow>
              ) : null}
            </div>
          </PeriodItem>
        );
      })}
    </Wrapper>
  );
};

export default Periods;

const Wrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const PeriodItem = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  margin-right: ${({isLast}) => isLast ? '0' : '16px'};
  padding: 0 24px;
  background-color: #FBFBFB;
  border-width: 1px;
  border-style: solid;
  border-color: ${({isActive}) => isActive ? MAIN_COLOR : '#E0E0E0'};
  border-radius: 16px;
  cursor: pointer;
`;

const PeriodTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PeriodDay = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: ${({isDisabled}) => isDisabled ? '#A4A4A4' : '#2C2E32'};
  user-select: none;
`;

const NextPeriodRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

const NextPeriodText = styled.p`
  margin: 0 0 0 8px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #2C2E32;
  user-select: none;
`;

const NearestPeriod = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  margin-top: -1px;
  padding: 0 8px;
  background: linear-gradient(110.84deg, #95CB87 7.03%, #76B865 124.86%);
  border-radius: 11px;
`;

const NearestPeriodText = styled.p`
  margin: 0 0 0 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #FFFFFF;
  user-select: none;
`;
