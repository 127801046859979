import {
  SET_AMOUNTS,
  SET_CART_SUM,
  SET_CART_OTHER,
  ADD_TO_CART,
  ADD_TO_INDEXES,
  CLEAR_CART,
} from "../actions/CartActions";

const initialState = {
  cart: [],
  cartModifiedIndexes: [],
  sum: 0,
  amounts: {},
  other: null,
  getOtherStatus: null,
};

export function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: action.payload,
      };

    case SET_AMOUNTS:
      return {
        ...state,
        amounts: action.payload,
      };

    case SET_CART_SUM:
      return {
        ...state,
        sum: action.payload,
      };

    case SET_CART_OTHER:
      return {
        ...state,
        other: action.payload,
        getOtherStatus: 'resolved',
      };

    case ADD_TO_INDEXES:
      return {
        ...state,
        cartModifiedIndexes: action.payload,
      };

    case CLEAR_CART:
      return {
        ...state,
        cart: [],
        sum: 0,
        cartModifiedIndexes: [],
      };

    default:
      return state;
  }
}
