import PropTypes from "prop-types";
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Link, NavLink, withRouter} from "react-router-dom";
import styled from "styled-components";
import {getCountStatistics, getRestaurants} from "../../actions/MainActions";
import {getTodayOrdersCount} from "../../actions/HeaderActions";
import {
	authModalToggle,
	cityModalToggle,
	deliveryCycleInfoMessage,
	profileModalToggle
} from "../../actions/ModalActions";
import {
	changeActiveCompany,
	createTokenByPassword,
	createTokenBySession,
	deleteToken,
	getClientProfile,
	handleReduxFormAuth,
	handleReduxFormEditCompany,
	saveClientProfile,
	saveCompany,
	sendActivationMail,
	sendCodeToPhone
} from "../../actions/ProfileActions";
import CountUp from "react-countup";
import City from "../../components/Header/City";
import AuthModal from "../../components/Modal/AuthModal";
import Nav, {NavLinked} from "../../components/Header/Nav";
import logo from "../../images/logo_v3.svg";
import logo51 from "../../images/logo_new.png";
import logoStraus from "../../images/logo_new.jpg";
import profileLogo from "../../images/marker/mark_user_v2.svg";
import cartImg from "../../images/marker/basket_white.svg";
import navClose from "../../images/close-nav-cross.svg";
import logoString from "../../images/logo-string.svg";
import logoStringStraus from "../../images/logo_new.jpg";
import cartImage from "../../images/cart-icon.svg";
import checkImage from "../../images/order-green.svg";
import {_} from "underscore";
import HeaderMini from "./../HeaderMini";
import {
	changeWidgetAction,
	currrentTimeServer,
	getAccountsForDate,
	getAddDialogGood,
	getCheckEdit,
	getMenu,
	getOrders,
	getSum,
	payTypeChange,
	saveOrder,
	saveOrders,
	getCheck,
	clearCheck,
	setActiveAccount,
	setActivePeriodForCart,
} from "../../actions/MenuActions";
import {clearCart} from "../../actions/CartActions";
import {BOLD_FONT, LIGHT_FONT, MAIN_COLOR, NAME_COMPANY, TXT_COLOR} from "../../utils/constants";
import {SelectAddress} from "../../components/Menu/SelectAddress";
import {confirmInviteToCompany, getRemoveInviteToCompany} from "../../actions/PersonalAction";
import Widget from "../../components/Menu/Widget";
import SentencePopup from "../Popup";

class Header extends Component {
	constructor(props){
		super(props);

		this.state = {
			mobileData: false,
			headerMini: false,
			openSelectAddress: false,
			widthScreen: 480,
			innerWidth: window.innerWidth,
			isNavOpen: false,
			isCartOpen: false,
			toggleSection: false,
			openBasket: true,
			basketFixed: false,
			basketBottom: false,
			items: this.props.orders,
			openPayType: false,
			periods: [],
		}
	}

    handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        const widthScreen = document.documentElement.clientWidth;

        if(widthScreen > 992){
			if(!this.state.headerMini){
				if(scrollTop >= 100){
					this.setState({
						headerMini: true,
						widthScreen: widthScreen
					})
				}
			} else if (this.state.headerMini){
				if(scrollTop < 100){
					this.setState({
						headerMini: false,
						widthScreen: widthScreen
					})
				}
			}
		}
    };

	static propTypes = {
		cities: PropTypes.array.isRequired,
		todayOrdersCount: PropTypes.number.isRequired,
		nextOrdersCount: PropTypes.number.isRequired,
		getRestaurants: PropTypes.func.isRequired,
        getCountStatistics: PropTypes.func.isRequired,
	};

	changeOpenSelect = () => {
		if(this.state.openSelectAddress){
			this.setState({
				openSelectAddress: false
			})
		}else{
			this.setState({
				openSelectAddress: true
			})
		}
	};

	getCookie (name) {
		let value = "; " + document.cookie;
		let parts = value.split("; " + name + "=");
		if (parts.length === 2) return parts.pop().split(";").shift();
	};

	componentDidMount() {
		this.props.currrentTimeServer();
        this.props.getCountStatistics();
        this.props.getTodayOrdersCount();
        window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.resize);
	}


	resize = () => {
		const innerWidth = window.innerWidth;
		if (innerWidth !== this.state.innerWidth) {
			this.setState({innerWidth});
		}
	};

	closeNavMenu = () => {
		this.setState(
			{isNavOpen: false},
			() => {document.body.style.overflowX = "visible"; document.body.style.overflowY = "scroll"}
			)
	}

	closeCart = () => {
		this.setState(
			{isCartOpen: false},
			() => {document.body.style.overflowX = "visible"; document.body.style.overflowY = "scroll"}
		)
	}

	changeWidget = (change) => {
		let objActions = {
			basketWidget: this.props.basketWidget,
			checkWidget: this.props.checkWidget,
			editWidget: this.props.editWidget,
		};

		switch(change){
			case "basket": objActions.basketWidget = true; objActions.editWidget = false; objActions.checkWidget = false;
				break;
			case "edit": objActions.basketWidget = false; objActions.editWidget = true; objActions.checkWidget = false;
				break;
			case "check": objActions.basketWidget = false; objActions.editWidget = false; objActions.checkWidget = true;
				break;
		}

		this.props.changeWidgetAction(objActions);
	};

	changeOpenBasket = (action = null) => {
		this.setState({
			openBasket: action
		})
	};

	addToCart = (item, isViewMessage = true) => {
		let items = this.props.orders ? this.props.orders.slice() : [];
		let itemInCart = items.find(i => i.id === item.id);

		let modifiersItemInCard = _.find(items, (item_mod)=>{
			return _.size(item_mod.modifiers) !== 0 && _.size(item.modifiers) !== 0 && item_mod.modifiers[0].id === item.modifiers[0].id && item_mod.id === item.id;
		});

		let sumModified = 0;
		let imageArray = _.find(this.props.menu.images, (img) => { return parseInt(img.id) === parseInt("1000" + item.MediaId); });

		if (itemInCart && _.size(item.modifiers) === 0) {
			itemInCart.amount += 1;

			sumModified = parseInt(itemInCart.price);
		} else if (modifiersItemInCard) {
			modifiersItemInCard.amount += 1;

			sumModified = parseInt(modifiersItemInCard.price) + parseInt(modifiersItemInCard.modifiers[0].price);
		} else {
			item.amount = 1;
			sumModified = _.size(item.modifiers) === 0
				? parseInt(item.amount) * parseInt(item.price)
				: (parseInt(item.price) * parseInt(item.amount)) + (parseInt(item.modifiers[0].price) * parseInt(item.amount));
			items.push({
				MediaId: item.MediaId,
				amount: item.amount,
				id: item.id,
				name: item.name,
				price: String(item.price * item.amount),
				restId: item.restId,
				image: _.size(imageArray) !== 0 ? imageArray.source : null,
				section: this.props.activeProduct,
				modifiers: item.modifiers ? item.modifiers : [],
				amountForOrder: item.amountForOrder,
				amountOrdered: item.amountOrdered,
				amountPerOrder: item.amountPerOrder,
			});
		}

		if (isViewMessage) this.props.deliveryCycleInfoMessage('add', item.name);
		this.setState({ items });
		this.props.getSum(this.props.sum + sumModified);
		this.props.getOrders(items);
		setTimeout(()=>{
			this.props.getAddDialogGood(false);
		}, 2000);
	};

	removeFromCart = (item, isViewMessage = true) => {
		let items = this.props.orders ? this.props.orders.slice() : [];

		let itemInCart = items.find(i => i.id === item.id);
		let modifiersItemInCard = _.find(items, (item_mod)=>{return _.size(item_mod.modifiers) !== 0 && _.size(item.modifiers) !== 0 && item_mod.modifiers[0].id == item.modifiers[0].id && item_mod.id == item.id});

		let sumModified = 0;

		if (itemInCart && !modifiersItemInCard) {
			_.map(items, (item_order)=>{
				if(item_order.id === item.id){
					item_order.amount -= 1;
				}
			});

			items = _.filter(items, item_remove => {return parseInt(item_remove.amount) !== 0} );

			sumModified = itemInCart.price;
		} else if (modifiersItemInCard) {
			_.map(items, (item_order)=>{
				if (_.size(item_order.modifiers) !== 0) {
					if(item_order.modifiers[0].id === item.modifiers[0].id){
						item_order.amount -= 1;
					}
				}

			});

			items = _.filter(items, item_remove => {return _.size(item_remove.modifiers) === 0 || _.size(item_remove.modifiers) !== 0 && parseInt(item_remove.amount) !== 0} );

			sumModified = _.size(modifiersItemInCard.modifiers) !== 0
				? parseInt(modifiersItemInCard.modifiers[0].price) + parseInt(modifiersItemInCard.price)
				: parseInt(modifiersItemInCard.price);
		}

		if (isViewMessage) this.props.deliveryCycleInfoMessage('remove', item.name);
		this.setState({ items });
		this.props.getSum(this.props.sum - sumModified);
		this.props.getOrders(items)
	};

	deleteToCart = (item) => {
		let items = this.props.orders ? this.props.orders.slice() : [];
		let sumModified = 0;

		if(_.size(item.modifiers) !== 0){

			items = _.filter(items, (card)=>{
				return _.size(card.modifiers) === 0 || _.size(card.modifiers) !== 0 && card.modifiers[0].id !== item.modifiers[0].id;
			});

			sumModified = parseInt(parseInt(item.price) + (item.modifiers[0].amount) * parseInt(item.modifiers[0].price));
		} else {
			let item_find = _.find(items, (item_main)=>{return item_main.id == item.id});
			items = _.filter(this.props.orders, (card)=>{return card.id !== item.id});
			sumModified = parseInt(item_find.amount) * parseInt(item_find.price);
		}

		this.props.deliveryCycleInfoMessage('remove', item.name);
		this.setState({ items });
		this.props.getSum(this.props.sum - sumModified);
		this.props.getOrders(items);
	};

	editToCart = () => {
		if(_.size(this.props.registrationOrders) !== 0){
			let elementOrder = this.props.menu.elements;
			let ordersRest = this.props.registrationOrders
			let orders = [];
			let newBasket = [];
			let allSumProduct = 0;

			_.map(ordersRest, (regRestOrder)=>{
				orders.push(...regRestOrder.products);
			});

			_.map(orders, (ord)=>{
				let findOrder = _.find(elementOrder, (el)=>{return el.id === ord.id});
				let image = _.find(this.props.menu.images, (img) => { return parseInt(img.id) === parseInt("1000" + findOrder.MediaId); });
				let sectionActive = _.find(this.props.menu.sections, (section)=>{
					return _.some(section.elementsAsArray, (elAr)=>{return elAr == ord.id})
				});

				newBasket.push({
					MediaId: findOrder.MediaId,
					amount: ord.amount,
					activeDay: this.props.day,
					id: findOrder.id,
					name: findOrder.name,
					price: ord.price,
					restId: findOrder.restId,
					image: image && image.source,
					section: sectionActive.id,
					modifiers: ord.modifiers ? ord.modifiers : [],
					amountForOrder: findOrder.amountForOrder,
					amountOrdered: findOrder.amountOrdered,
					amountPerOrder: findOrder.amountPerOrder,
				});

				allSumProduct += _.size(ord.modifiers) === 0
					? parseInt(ord.amount) * parseInt(ord.price)
					: (parseInt(ord.price) * parseInt(ord.amount)) + (parseInt(ord.modifiers[0].price) * parseInt(ord.amount));
			});

			this.props.getSum(allSumProduct);
			this.setState({ items: newBasket });
			this.props.getOrders(newBasket);
		}
	};

	changeOpenPayTypeModal = () => {
		if(this.state.openPayType){
			this.setState({
				openPayType: false
			})
		}else{
			this.setState({
				openPayType: true
			})
		}
	};

	deleteBasket = () => {
		this.setState({ items: [] });
		this.props.getSum(0);
		this.props.getOrders([]);
		setTimeout(()=>{
			this.props.getAddDialogGood(false);
		}, 2000);
	};


	render() {
		const {
			isToken,
			cities,
			getRestaurants,
			isAuth,
			sendCodeToPhone,
			createTokenBySession,
			codeSent,
			cityModalIsOpen,
			cityModalToggle,
			phoneError,
			handleReduxFormAuth,
			createTokenByPassword,
			sendActivationMail,
			valueAuthEmail,
            errorMessage,
			todayOrdersCount,
			nextOrdersCount,
			profile,
			activeCompany,
			saveCompany,
			day,
			period,
			valueEditCompany,
			handleReduxFormEditCompany,
			changeActiveCompany,
			getRemoveInviteToCompany,
			confirmInviteToCompany,
			deliveryCycleInfoMessage,
			getAccountsForDate,
			changeInformationView,
			viewInfoPerformance,
			infoViewToggle,
			companies,
			getMenu,
			cart,
			sumNew,
			clearCart,
			setActiveAccount,
			setActivePeriodForCart,
		} = this.props;

        let countPosition = 0;

        _.each(this.props.orders, (item, key)=>{
            countPosition += parseInt(item.amount);
		});

		const getCookie = (name) => {
			var value = "; " + document.cookie;
			var parts = value.split("; " + name + "=");
			if (parts.length === 2) return parts.pop().split(";").shift();
		}

		let cookieCheck = getCookie("settingAttachment");

		let is51obed = window.location.href.includes('51obed.ru') || window.location.href.includes('51.menu4me-test.ru');
		let isStraus = window.location.href.includes('stolovka.su') || window.location.href.includes('straus.menu4me-test.ru');

		return (
			<Fragment>
				<Container id="header">
					{
						(!is51obed && !isStraus) && (cookieCheck === "no" || cookieCheck === undefined)
							?
							/Android/i.test(navigator.userAgent)
								? <SentencePopup editMargin={this.props.editMargin} electrStore="google-play" href="https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice&utm_source=my_comp&utm_medium=cpa&utm_campaign=promo&utm_content=landing_page&utm_term=just"/>
									: /webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)
										? <SentencePopup editMargin={this.props.editMargin} electrStore="apple" href="https://itunes.apple.com/ru/app/id1361986950?mt=8"/>
								: false
							:   false
					}
					<Wrapper style={this.props.modalPopup ? {filter: 'blur(1px)'} : {}}>
						{this.state.innerWidth > 992 ? (
							<Left>
								{/*<FlexWrapperLeft>*/}
								{/*	<LogoLink to="/">*/}
								{/*		<LogoImg*/}
								{/*			src={*/}
								{/*				is51obed ? logo51 :*/}
								{/*					isStraus ? logoStraus :*/}
								{/*						logo*/}
								{/*			}*/}
								{/*			rounded={isStraus}*/}
								{/*			alt={NAME_COMPANY}*/}
								{/*		/>*/}
								{/*	</LogoLink>*/}
								{/*	<WrapperLeft>*/}
								{/*		<div>*/}
								{/*			<TitleLink to="/">{NAME_COMPANY}</TitleLink>*/}
								{/*			<Subtitle>Сервис доставки еды</Subtitle>*/}
								{/*		</div>*/}
								{/*	</WrapperLeft>*/}
								{/*</FlexWrapperLeft>*/}
								{/*<WrapperNav>*/}
								{/*	<Nav />*/}
								{/*</WrapperNav>*/}
								{/*<WrapperNavCenter>*/}
								{/*	<Nav*/}
								{/*		isAuth={isAuth}*/}
								{/*		companies={companies}*/}
								{/*		activeCompany={activeCompany}*/}
								{/*		saveCompany={saveCompany}*/}
								{/*		handleReduxFormEditCompany={handleReduxFormEditCompany}*/}
								{/*		valueEditCompany={valueEditCompany}*/}
								{/*		cities={cities}*/}
								{/*		changeActiveCompany={changeActiveCompany}*/}
								{/*		getRemoveInviteToCompany={getRemoveInviteToCompany}*/}
								{/*		confirmInviteToCompany={confirmInviteToCompany}*/}
								{/*		profile={profile.email !== null ? profile.email : profile.phone}*/}
								{/*		openSelect={this.state.openSelectAddress}*/}
								{/*		changeOpenSelect={this.changeOpenSelect}*/}
								{/*		deliveryCycleInfoMessage={deliveryCycleInfoMessage}*/}
								{/*		day={day}*/}
								{/*		period={period}*/}
								{/*		getAccountsForData={getAccountsForDate}*/}
								{/*		changeInformationView={changeInformationView}*/}
								{/*		viewInfoPerformance={viewInfoPerformance}*/}
								{/*		infoViewToggle={infoViewToggle}*/}
								{/*		widthScreen={this.state.widthScreen}*/}
								{/*		getMenu={getMenu}*/}
								{/*	/>*/}
								{/*	{isAuth ? (*/}
								{/*		<ProfileIconBlock to="/personal">*/}
								{/*			<ProfileIcon src={profileLogo}/>*/}
								{/*		</ProfileIconBlock>*/}
								{/*	) : (*/}
								{/*		<AuthModal*/}
								{/*			onChange={handleReduxFormAuth}*/}
								{/*			profile={profile}*/}
								{/*			codeSent={codeSent}*/}
								{/*			phoneError={phoneError}*/}
								{/*			sendCodeToPhone={sendCodeToPhone}*/}
								{/*			createTokenBySession={createTokenBySession}*/}
								{/*			errorMessage={errorMessage}*/}
								{/*			valueAuthEmail={valueAuthEmail}*/}
								{/*			buttonFront={false}*/}
								{/*			isToken={isToken}*/}
								{/*			createTokenByPassword={createTokenByPassword}*/}
								{/*			sendActivationMail={sendActivationMail}*/}
								{/*		/>*/}
								{/*	)}*/}
								{/*</WrapperNavCenter>*/}
								<LogoLink to="/">
									<LogoImg
										src={
											is51obed ? logo51 :
												isStraus ? logoStraus :
													logo
										}
										isSitiy={!is51obed && !isStraus}
										rounded={isStraus}
										alt={NAME_COMPANY}
									/>
								</LogoLink>
								{isAuth && (
									<SelectAddress
										companies={companies}
										activeCompany={activeCompany}
										saveCompany={saveCompany}
										handleReduxFormEditCompany={handleReduxFormEditCompany}
										valueEditCompany={valueEditCompany}
										cities={cities}
										changeActiveCompany={changeActiveCompany}
										getRemoveInviteToCompany={getRemoveInviteToCompany}
										confirmInviteToCompany={confirmInviteToCompany}
										profile={profile.email !== null ? profile.email : profile.phone}
										openSelect={this.state.openSelectAddress}
										changeOpenSelect={this.changeOpenSelect}
										deliveryCycleInfoMessage={deliveryCycleInfoMessage}
										day={day}
										period={period}
										getAccountsForData={getAccountsForDate}
										changeInformationView={changeInformationView}
										viewInfoPerformance={viewInfoPerformance}
										infoViewToggle={infoViewToggle}
										getMenu={getMenu}
										clearCart={clearCart}
										setActiveAccount={setActiveAccount}
										setActivePeriodForCart={setActivePeriodForCart}
									/>
								)}
							</Left>
						) : (
							<Left>
								<ShowNavButton
									onClick={() => this.setState(
										{isNavOpen: true},
										() => {document.body.style.overflow = "hidden"}
										)}
								>
									<Bar/>
									<Bar/>
									<Bar/>
								</ShowNavButton>
								{/*<NavMobileBox isNavOpen={this.state.isNavOpen}>*/}
								{/*	*/}
								{/*</NavMobileBox>*/}
								<NavBackground isNavOpen={this.state.isNavOpen} onClick={this.closeNavMenu}/>
								<NavMobile isNavOpen={this.state.isNavOpen}>
									<NavHeader>
										<NavClose onClick={this.closeNavMenu}>
											<img src={navClose} alt="Закрыть"/>
										</NavClose>
										{is51obed ? (
											<LogoString src={logo51} width={40} alt="Дом 51"/>
										) : (
											isStraus ? (
												<LogoString src={logoStringStraus} width={40} style={{borderRadius: 20}} alt="Страус"/>
											) : (
												<LogoString src={logoString} alt="Сытый"/>
											)
										)}
									</NavHeader>
									{isAuth ? (
										<NavMain>
											<NavMainLink to="/" onClick={this.closeNavMenu} style={{width: 43}}>
												Меню
											</NavMainLink>
											<NavLinkedWithNotificatorMobile exact to="/my-orders" onClick={this.closeNavMenu}>
												Мои заказы
												{/*<span>2</span>*/}
											</NavLinkedWithNotificatorMobile>
											<NavMainLink to="/personal" onClick={this.closeNavMenu} style={{width: 65}}>
												Профиль
											</NavMainLink>
											<NavMainItem
												onClick={() => {
													this.closeNavMenu();
													setTimeout(() => {
														this.setState({isCartOpen: true}, () => {
															document.body.style.overflow = "hidden"
														})
													}, 500)
												}}
												style={{width: 60}}
											>
												Корзина
											</NavMainItem>
											<NavMainLink to="/delivery" onClick={this.closeNavMenu}  style={{width: 69}}>
												Доставка
											</NavMainLink>
											{/*{(!is51obed && !isStraus) && (*/}
											{/*	<NavMainLink to="/promo" onClick={this.closeNavMenu} style={{width: 74}}>*/}
											{/*		Промокод*/}
											{/*	</NavMainLink>*/}
											{/*)}*/}
											<NavMainLink to="/pay" onClick={this.closeNavMenu} style={{width: 52}}>
												Оплата
											</NavMainLink>
											{(!is51obed && !isStraus) && (
												<CorsLink href="https://partner.sitiy.ru" target="_blank" style={{width: 52}}>
													Кухням
												</CorsLink>
											)}
											{(!is51obed && !isStraus) && (
												<NavMainLink to="/manager" onClick={this.closeNavMenu} style={{width: 139}}>
													Компаниям
												</NavMainLink>
											)}
											{!isStraus && (
												<City
													cities={cities}
													getRestaurants={getRestaurants}
													cityModalIsOpen={cityModalIsOpen}
													cityModalToggle={cityModalToggle}
													mobileHeader={true}
													isMobile={true}
												/>
											)}
										</NavMain>
									) : (
										<NavMain>
											<NavMainItem style={{width: 120}}>
												<AuthModal
													onChange={handleReduxFormAuth}
													profile={profile}
													codeSent={codeSent}
													phoneError={phoneError}
													sendCodeToPhone={sendCodeToPhone}
													createTokenBySession={createTokenBySession}
													errorMessage={errorMessage}
													valueAuthEmail={valueAuthEmail}
													buttonFront={false}
													isToken={isToken}
													createTokenByPassword={createTokenByPassword}
													sendActivationMail={sendActivationMail}
													fromNav={true}
													closeNav={() => this.closeNavMenu()}
												/>
											</NavMainItem>
											<NavMainLink to="/" onClick={this.closeNavMenu} style={{width: 43}}>
												Меню
											</NavMainLink>

											<NavMainItem
												onClick={() => {
													this.closeNavMenu();
													setTimeout(() => {
														this.setState({isCartOpen: true}, () => {
															document.body.style.overflow = "hidden"
														})
													}, 500)
												}}
												style={{width: 60}}
											>
												Корзина
											</NavMainItem>
											<NavMainLink to="/delivery" onClick={this.closeNavMenu}  style={{width: 69}}>
												Доставка
											</NavMainLink>
											{/*{(!is51obed && !isStraus) && (*/}
											{/*	<NavMainLink to="/promo" onClick={this.closeNavMenu} style={{width: 74}}>*/}
											{/*		Промокод*/}
											{/*	</NavMainLink>*/}
											{/*)}*/}
											<NavMainLink to="/pay" onClick={this.closeNavMenu} style={{width: 52}}>
												Оплата
											</NavMainLink>
											{(!is51obed && !isStraus) && (
												<CorsLink href="https://partner.sitiy.ru" target="_blank" style={{width: 52}}>
													Кухням
												</CorsLink>
											)}
											{(!is51obed && !isStraus) && (
												<NavMainLink to="/manager" onClick={this.closeNavMenu} style={{width: 139}}>
													Компаниям
												</NavMainLink>
											)}
											{!isStraus && (
												<City
													cities={cities}
													getRestaurants={getRestaurants}
													cityModalIsOpen={cityModalIsOpen}
													cityModalToggle={cityModalToggle}
													mobileHeader={true}
													isMobile={true}
												/>
											)}
										</NavMain>
									)}
								</NavMobile>
							</Left>
						)}
						{this.state.innerWidth > 992 ? (
							<Center>
								{!isStraus && (
									<Description>Доставка еды из столовых&nbsp;
										<City
											cities={cities}
											getRestaurants={getRestaurants}
											cityModalIsOpen={cityModalIsOpen}
											cityModalToggle={cityModalToggle}
											mobileHeader={true}
										/>
									</Description>
								)}
							</Center>
						) : (
							<Center>
								{isAuth ? (
									<SelectAddress
										companies={companies}
										activeCompany={activeCompany}
										saveCompany={saveCompany}
										handleReduxFormEditCompany={handleReduxFormEditCompany}
										valueEditCompany={valueEditCompany}
										cities={cities}
										changeActiveCompany={changeActiveCompany}
										getRemoveInviteToCompany={getRemoveInviteToCompany}
										confirmInviteToCompany={confirmInviteToCompany}
										profile={profile.email !== null ? profile.email : profile.phone}
										openSelect={this.state.openSelectAddress}
										changeOpenSelect={this.changeOpenSelect}
										deliveryCycleInfoMessage={deliveryCycleInfoMessage}
										day={day}
										period={period}
										getAccountsForData={getAccountsForDate}
										changeInformationView={changeInformationView}
										viewInfoPerformance={viewInfoPerformance}
										infoViewToggle={infoViewToggle}
										isMobile={true}
										getMenu={getMenu}
										getRestaurants={getRestaurants}
										cityModalIsOpen={cityModalIsOpen}
										cityModalToggle={cityModalToggle}
									/>
								) : (
									<AuthModal
										onChange={handleReduxFormAuth}
										profile={profile}
										codeSent={codeSent}
										phoneError={phoneError}
										sendCodeToPhone={sendCodeToPhone}
										createTokenBySession={createTokenBySession}
										errorMessage={errorMessage}
										valueAuthEmail={valueAuthEmail}
										buttonFront={false}
										isToken={isToken}
										createTokenByPassword={createTokenByPassword}
										sendActivationMail={sendActivationMail}
									/>
								)}
							</Center>
						)}
						{this.state.innerWidth > 992 ? (
							<Right style={{flexDirection: 'row'}}>
								{isAuth ? (
									<>
										{cart && cart.length ? (
											<CartButtonNew to="/cart">
												<CartIcon src={cartImg} alt="" />
												<CartButtonText>{sumNew} ₽</CartButtonText>
											</CartButtonNew>
										) : null}
										<DropDown>
											<ProfileToggleInfo to="/personal">
												<ProfileIcon src={profileLogo} alt="" />
												<ProfileToggleText>
													{/*{profile && `${*/}
													{/*	profile.name ? profile.name : profile.phone || profile.phone*/}
													{/*}`}*/}
													Профиль
												</ProfileToggleText>
											</ProfileToggleInfo>
											<DropDownWrapper className="dropdown-content">
												<DropDownContent>
													<DropDownItem to="/my-orders">
														<p>Мои заказы</p>
													</DropDownItem>
												</DropDownContent>
											</DropDownWrapper>
										</DropDown>
									</>
								) : (
									<AuthModal
										onChange={handleReduxFormAuth}
										profile={profile}
										codeSent={codeSent}
										phoneError={phoneError}
										sendCodeToPhone={sendCodeToPhone}
										createTokenBySession={createTokenBySession}
										errorMessage={errorMessage}
										valueAuthEmail={valueAuthEmail}
										buttonFront={false}
										isToken={isToken}
										createTokenByPassword={createTokenByPassword}
										sendActivationMail={sendActivationMail}
									/>
								)}
								{/*{isAuth && (*/}
								{/*	<SelectAddress*/}
								{/*		companies={companies}*/}
								{/*		activeCompany={activeCompany}*/}
								{/*		saveCompany={saveCompany}*/}
								{/*		handleReduxFormEditCompany={handleReduxFormEditCompany}*/}
								{/*		valueEditCompany={valueEditCompany}*/}
								{/*		cities={cities}*/}
								{/*		changeActiveCompany={changeActiveCompany}*/}
								{/*		getRemoveInviteToCompany={getRemoveInviteToCompany}*/}
								{/*		confirmInviteToCompany={confirmInviteToCompany}*/}
								{/*		profile={profile.email !== null ? profile.email : profile.phone}*/}
								{/*		openSelect={this.state.openSelectAddress}*/}
								{/*		changeOpenSelect={this.changeOpenSelect}*/}
								{/*		deliveryCycleInfoMessage={deliveryCycleInfoMessage}*/}
								{/*		day={day}*/}
								{/*		period={period}*/}
								{/*		getAccountsForData={getAccountsForDate}*/}
								{/*		changeInformationView={changeInformationView}*/}
								{/*		viewInfoPerformance={viewInfoPerformance}*/}
								{/*		infoViewToggle={infoViewToggle}*/}
								{/*		getMenu={getMenu}*/}
								{/*	/>*/}
								{/*)}*/}
							</Right>
						) : (
							<Right>
								{this.props.registrationOrders && this.props.registrationOrders.length > 0 ? (
									<Link to={'/my-orders'}>
										<CartButton>
											<CartImage src={checkImage} style={{top: 3, left: 4, width: 36}}/>
										</CartButton>
									</Link>
								) : (
									<CartButton
										onClick={() => this.setState(
											{isCartOpen: true},
											() => {document.body.style.overflow = "hidden"}
										)}
									>
										<CartImage src={cartImage}/>
										{_.size(this.props.orders) > 0 ?
											<div style={{
												position: 'absolute',
												right: 0,
												top: 4,
												width: 20,
												height: 20,
												borderWidth: 2,
												borderColor: '#ffffff',
												borderRadius: 10,
												borderStyle: 'solid',
												backgroundColor: '#CDDC39',
											}}>
												<p style={{
													textAlign: 'center',
													color: '#333333',
													fontFamily: 'Roboto',
													fontSize: 9,
													lineHeight: '16px',
													margin: 0,
												}}>
													{_.size(this.props.orders)}
												</p>
											</div>
											: false
										}
									</CartButton>
								)}
								<NavBackground isCartOpen={this.state.isCartOpen}/>
								<Cart isCartOpen={this.state.isCartOpen}>
									<Widget
										changeWidget={this.changeWidget}
										edit={this.props.editWidget}
										basket={this.props.basketWidget}
										check={this.props.checkWidget}
										toggle={this.state.toggleSection}
										changeOpenBasket={this.changeOpenBasket}
										openBasket={this.state.openBasket}
										basketFixed={this.state.basketFixed}
										basketBottom={this.state.basketBottom}
										basketBottomPos={this.state.basketBottomPos}
										orders={this.props.orders}
										addToCart={this.addToCart}
										removeFromCart={this.removeFromCart}
										deleteToCart={this.deleteToCart}
										editToCart={this.editToCart}
										regOrder={this.props.registrationOrders}
										payTypeChange={this.props.payTypeChange}
										changeOpenPayTypeModal={this.changeOpenPayTypeModal}
										openPayType={this.state.openPayType}
										payType={this.props.payType}
										saveOrder={this.props.saveOrder}
										saveOrders={this.props.saveOrders}
										getSum={this.props.getSum}
										getCheckEdit={this.props.getCheckEdit}
										getOrders={this.props.getOrders}
										payStatus={this.state.payStatus}
										day={this.props.day}
										restaurants={this.props.restaurants}
										deleteBasket={this.deleteBasket}
										companyOrder={this.props.companyOrder}
										deliveryCycleInfoMessage={this.props.deliveryCycleInfoMessage}
										sum={this.props.sum}
										widgetPreloader={this.props.widgetPreloader}
										timeStatus={this.props.timeStatus}
										payLoading={this.props.payLoading}
										restarauntsInfo={this.props.restarauntsInfo}
										activeCompany={this.props.activeCompany}
										getAccountsForDate={this.props.getAccountsForDate}
										activePeriod={this.props.activePeriod}
										isMobile={this.state.innerWidth <= 992}
										closeCart={this.closeCart}
										periods={this.props.periods}
										profile={this.props.profile}
										getCheck={this.props.getCheck}
										checkData={this.props.checkData}
										checkLoaded={this.props.checkLoaded}
										clearCheck={this.props.clearCheck}
									/>
								</Cart>
							</Right>
						)}
					</Wrapper>
				</Container>
				<HeaderMini fixed={this.state.headerMini}/>
			</Fragment>
		);
	}
}

const mapStateToProps = store => ({
	cities: store.main.cities,
    statistic: store.main.statistic,
    orders: store.menu.orders,
    todayOrdersCount: store.header.todayOrdersCount,
	nextOrdersCount: store.header.nextOrdersCount,
	isAuth: store.profile.isAuth,
	isToken: store.profile.isToken,
	profile: store.profile.profile,
	companies: store.profile.companies,
	activeCompany: store.profile.activeCompany,
	valueAuthEmail: store.profile.valueAuthEmail,
    errorMessage: store.profile.errorMessage,
	codeSent: store.profile.codeSent,
	phoneError: store.profile.phoneError,
	cityModalIsOpen: store.modal.cityModalIsOpen,
	modalCompanies: store.modal.modalCompanies,
	authModalIsOpen: store.modal.authModalIsOpen,
	profileModalIsOpen: store.modal.profileModalIsOpen,
	sumCount: store.menu.sum,
	valueEditCompany: store.profile.valueEditCompany,
	day: store.menu.day,
	period: store.menu.period,
	basketWidget: store.menu.basketWidget,
	checkWidget: store.menu.checkWidget,
	editWidget: store.menu.editWidget,
	menu: store.menu.menu,
	activeProduct: store.menu.activeProduct,
	sum: store.menu.sum,
	registrationOrders: store.menu.registrationOrders,
	payType: store.menu.payType,
	restaurants: store.main.restaurants,
	companyOrder: store.menu.companyOrder,
	widgetPreloader: store.menu.widgetPreloader,
	timeStatus: store.menu.timeStatus,
	payLoading: store.personal.payLoading,
	restarauntsInfo: store.menu.restarauntsInfo,
	checkData: store.menu.checkData,
	checkLoaded: store.menu.checkLoaded,
	cart: store.cart.cart,
	sumNew: store.cart.sum,
});

const mapDispatchToProps = dispatch => ({
    getCountStatistics: () => dispatch(getCountStatistics()),
    getRestaurants: () => dispatch(getRestaurants()),
	getClientProfile: () => dispatch(getClientProfile()),
	sendCodeToPhone: phone => dispatch(sendCodeToPhone(phone)),
	createTokenBySession: code => dispatch(createTokenBySession(code)),
	deleteToken: () => dispatch(deleteToken()),
	saveClientProfile: profile => dispatch(saveClientProfile(profile)),
	cityModalToggle: () => dispatch(cityModalToggle()),
	currrentTimeServer: () => dispatch(currrentTimeServer()),
	authModalToggle: () => dispatch(authModalToggle()),
	profileModalToggle: () => dispatch(profileModalToggle()),
    getTodayOrdersCount: () => dispatch(getTodayOrdersCount()),
	getOrders: (items) => dispatch(getOrders(items)),
	handleReduxFormAuth: (values) => dispatch(handleReduxFormAuth(values)),
	createTokenByPassword: () => dispatch(createTokenByPassword()),
	sendActivationMail: () => dispatch(sendActivationMail()),
	changeActiveCompany: (company, isChangeAddress) => dispatch(changeActiveCompany(company, isChangeAddress)),
	handleReduxFormEditCompany: (values) => dispatch(handleReduxFormEditCompany(values)),
	getRemoveInviteToCompany: (companyId, essence) => dispatch(getRemoveInviteToCompany(companyId, essence)),
	confirmInviteToCompany: (companyId) => dispatch(confirmInviteToCompany(companyId)),
	getAccountsForDate: (day, periodId) => dispatch(getAccountsForDate(day, periodId)),
	deliveryCycleInfoMessage: (actionUser, product, timeOut) => dispatch(deliveryCycleInfoMessage(actionUser, product, timeOut)),
	saveCompany: (dataCompany) => dispatch(saveCompany(dataCompany)),
	changeWidgetAction: (action) => dispatch(changeWidgetAction(action)),
	getSum: (count) => dispatch(getSum(count)),
	getAddDialogGood: (good, bool) => dispatch(getAddDialogGood(good, bool)),
	payTypeChange: (id) => dispatch(payTypeChange(id)),
	saveOrder: (day, items, action, city, regOrder, periodId) => dispatch(saveOrder(day, items, action, city, regOrder, periodId)),
	saveOrders: (day, items, action, city, regOrder, periodId, metrika, phone) => dispatch(saveOrders(day, items, action, city, regOrder, periodId, metrika, phone)),
	getCheckEdit: () => dispatch(getCheckEdit()),
	getMenu: (restId, mainDate, periodId) => dispatch(getMenu(restId, mainDate, periodId)),
	getCheck: (date, owner, company, period, products, promocode, paysystem, wallet) => dispatch(getCheck(date, owner, company, period, products, promocode, paysystem, wallet)),
	clearCheck: () => dispatch(clearCheck()),
	clearCart: () => dispatch(clearCart()),
	setActiveAccount: account => dispatch(setActiveAccount(account)),
	setActivePeriodForCart: period => dispatch(setActivePeriodForCart(period)),
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Header)
);

const ProfileToggleInfo = styled(NavLink)`
	display: flex;
	align-items: center;
	padding: 16px 22px;
	border-radius: 16px;
	background-color: #F1F1F1;
	cursor: pointer;
	
	/*@media(min-width: 992.01px){
		flex-direction: row;
		align-items: center;
	}*/
	
	&:hover{
		color: #333333;
		text-decoration: none;
	}
	
	@media(max-width: 992px){
		display: none;
	}	
`;

const ProfileToggleText = styled.p`
	font-family: 'Montserrat';
	font-weight: 500;
	color: #333333;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 0;

	/*&:hover {
		text-decoration: none;
		text-shadow: 0 0 3px #c0c0c0;
	}*/
	
	/*@media(min-width: 992px){
		display: block !important;
	}*/
	
	/*@media(min-width: 992px){
		order: 1;
	}*/
`;

const CartButtonNew = styled(NavLink)`
	display: flex;
	align-items: center;
	margin-right: 32px;
	padding: 16px 17px;
	border-radius: 16px;
	background-color: ${MAIN_COLOR};
	cursor: pointer;
	
	&:hover{
		color: #FFFFFF;
		text-decoration: none;
	}
`;

const CartButtonText = styled.p`
	margin-bottom: 0;
	font-family: 'Montserrat';
	font-weight: 500;
	color: #FFFFFF;
	font-size: 18px;
	line-height: 22px;
`;

const FlexWrapperLeft = styled.div`
	/*display: flex;*/
	/*align-items: center;*/
	/*margin-bottom: 10px;*/
	
	@media(max-width: 992px){
		margin: 5px 0;
	}
`;

const WrapperLeft = styled.div`
	display: flex;
	flex-direction: column;
`;

const CityClick = styled.div`
	@media(min-width: 992px){
		display: none;
	}
`;

const BasketData = styled.div`
	display: flex;
	align-items: center;
	margin-top: 15px;
	order: 2;
	position: relative;
	
	@media(max-width: 992px){
		margin: 0 5px 0 20px;
	}
`;

const SumCountPrice = styled.span`
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #263238;
	white-space: nowrap;
`;

const Basket = styled.div`
	background: #E0D5D2;
	border-radius: 6px;
	min-width: 125px;
	height: 40px;
	margin-left: 20px;
	display: flex;
	align-items: center;
    justify-content: center;
    cursor: pointer;
    
	@media(max-width: 992px){
    	display: none;
    }
`;

const CountBasketIcon = styled.span`
    width: 17px;
    height: 17px;
    position: absolute;
    right: -9px;
    top: -5px;
    background: #E57373;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
	
	@media(min-width: 992px){
		display: none;
	}
`;

const BasketIcon = styled.img`
	width: 25px;
	height: 25px;
	
	@media(min-width: 992px){
		display: none;		
	}
`;

const OrderTodayCounter = styled.span`
	color: #212121;
	font-weight: 400;
`;

const BasketText = styled.div`
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: #fff;
`;

const Container = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1002;
	padding: 32px 40px;
	/*border: 1px solid #e9e9e9;*/
	background-color: #fff;
	/*transition: .5s cubic-bezier(0.39, 0.58, 0.57, 1);*/
	opacity: 1;
	
	@media (max-width: 992px) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1002;
		width: 100%;
		border: 0;
	}
`;

const Wrapper = styled.div`
	/*max-width: 1280px;*/
	/*padding: 10px 15px;*/
	/*margin: 0 auto;*/
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	@media (max-width: 992px) {
		width: 100%;
		height: 68px;
		margin: 0;
		padding: 13px 7px 13px 0;
	}
`;

const Left = styled.div`
	display: flex;
	/*flex-direction: column;*/
	/*flex: 1;*/
	align-items: center;

	@media (max-width: 992px) {
		flex: none;
		width: 44px;
	}
`;

const LogoLink = styled(Link)`
	margin-right: 55px;
`;

const LogoImg = styled.img`
	height: ${({isSitiy}) => isSitiy ? '28px' : '52px'};
	border-radius: ${({rounded}) => rounded ? '26px' : '0px'};

	@media (max-width: 992px) {
		height: 36px;
	}
`;

const TitleLink = styled(Link)`
	${BOLD_FONT};
	font-size: 20px;
	color: #2d2d2d;
	margin-bottom: 0;
	line-height: 1.3;
	
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		color: #7129bd;
	}

	&:hover {
		text-decoration: none;
		color: #2d2d2d;
	}
`;

const Subtitle = styled.span`
	${LIGHT_FONT};
	font-size: 12px;
	color: #2d2d2d;
	display: block;
	margin-bottom: 1px;
	
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		color: #7129bd;
		font-weight: bold;
	}
`;

const Center = styled.div`
	text-align: center;
	color: #2d2d2d;
	flex: 1;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: 992px) {
		display: flex;
		flex: none;
		/*order: 3;*/
		/*display: inline-flex;*/
		/*align-items: center;*/
		width: calc(100% - 88px);
		padding: 0 4px 0 11px;
	}
`;

const Description = styled.p`
	${BOLD_FONT};
	font-size: 18px;
	margin-bottom: 0;
`;

// const City = styled.span`
// 	color: #E65100;
// `;

// const Preorder = styled.p`
// 	font-family: "Lato", sans-serif;
// 	font-size: 16px;
// 	display: inline-block;
// 	margin-bottom: 0;
//
// 	@media (max-width: 992px) {
// 		display: none;
// 	}
// `;

const Right = styled.div`
	display: flex;
	justify-content: flex-end;
    align-items: flex-end;
	flex: 1;
	flex-direction: column;

	@media (max-width: 992px) {
		//display: none;
		flex: none;
		//margin-left: auto;
		//align-items: center;
		//display: flex;
		//flex-direction: inherit;
		//padding-top: 0;
		width: 44px;
	}
`;

const WrapperNav = styled.div`
	width: 100%;
	margin-top: 10px;

	@media(max-width: 992px){
		display: none;
	}
`;

const WrapperNavCenter = styled.div`
	width: 100%;
	display: flex;
	align-items: center;

	@media(min-width: 992.01px){
		display: none;
	}
	
	@media(max-width: 992px){
		
	}
`;

const ProfileIconBlock = styled(NavLink)`
	width: 26px;
	margin-left: -75px;
	cursor: pointer;
	
	@media(min-width: 992px){
		display: none;
	}
	
	@media(max-width: 992px){
		display: none;
	}
`;

const ProfileIcon = styled.img`
	width: 28px;
	height: 28px;
	margin-right: 8px;
	
	/*@media(min-width: 992px){
		width: 22px;
		margin-right: -15px;
	}*/
`;

const CartIcon = styled.img`
	width: 28px;
	height: 28px;
	margin-right: 10px;
`;

const OrderDay = styled.div`
	@media(max-width: 992px){
		display: none
	}
`;

const OrderToday = styled.span`
	${LIGHT_FONT};
	color: #78909C;
	font-size: 12px;
	margin: 0 5px;
`;

const OrderNextDay = styled(OrderToday)`
	color: rgba(51, 51, 51, 0.35);
`;

const ShowNavButton = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
`;

const Bar = styled.div`
	width: 16px;
	height: 2px;
	background-color: #111111;
	border-radius: 1px;
	
	&:not(:last-child) {
		margin-bottom: 5px;
	}
`

const NavMobileBox = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1003;
	width: ${({isNavOpen}) => isNavOpen ? '100vw' : '0'};
	height: 100vh;
	background-color: transparent;
	overflow-x: hidden;
`

const NavBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1004;
	width: 100vw;
	height: 100vh;
	opacity: ${({isNavOpen, isCartOpen}) => isNavOpen || isCartOpen ? '1' : '0'};
	pointer-events: ${({isNavOpen, isCartOpen}) => isNavOpen || isCartOpen ? 'auto' : 'none'};
	background-color: rgba(38, 50, 56, 0.35);
	overflow-x: hidden;
	transition: opacity 0.5s;
`

const NavMobile = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 1005;
	width: ${({isNavOpen}) => isNavOpen ? '88.33vw' : '0'};
	height: 100vh;
	background-color: #ffffff;
	overflow-x: hidden;
	transition: width 0.44165s;
`

const NavHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 88.33vw;
	height: 52px;
	border-bottom: 2px solid #eeeeee;
`

const NavClose = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	margin-left: 24px;
`

const LogoString = styled.img`
	margin-right: 29.44vw;
`

const NavMain = styled.div`
	display: flex;
	flex-direction: column;
	width: 88.33vw;
`

const NavMainLink = styled(NavLink)`
	height: 44px;
	margin: 6px 0 0 38px;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 44px;
	color: #222222 !important;
	text-decoration: none !important;
	
	&:first-child {
		margin-top: 18px;
	}
`

const NavLinkedWithNotificatorMobile = styled(NavMainLink)`
	display: flex;
	align-items: center;
	
	> span {
		margin-left: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		background: ${MAIN_COLOR};
		font-size: 12px;
		line-height: 14px;
		color: #fff;
	}
`
const NavLinkWithNotificatorSide = styled(NavLink)`
	height: auto;
	line-height: 20px;
	font-weight: 300;
	padding-right: 15px;
	margin: 0;
`
const CorsLink = styled.a`
	height: 44px;
	margin: 6px 0 0 38px;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 44px;
	color: #222222 !important;
	text-decoration: none !important;
`;

const NavMainItem = styled.div`
	display: inline-block;
	height: 44px;
	margin: 6px 0 0 38px;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-size: 15px;
	line-height: 44px;
	color: #222222;
`

const CartButton = styled.div`
	position: relative;
	width: 44px;
	height: 44px;
`

const CartImage = styled.img`
	position: absolute;
	left: 9px;
	top: 6px;
`

const Cart = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1005;
	width: ${({isCartOpen}) => isCartOpen ? '100vw' : '0'};
	height: 100vh;
	background-color: #ffffff;
	overflow-x: hidden;
	transition: width 0.5s;
`

const DropDown = styled.div`
	position: relative;
	
	&:hover .dropdown-content {
		display: block;
	}
`;

const DropDownWrapper = styled.div`
	display: none;
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 1;
  width: 436px;
  padding-top: 16px;
`;

const DropDownContent = styled.div`
  width: 100%;
	padding: 8px 0;
	background-color: #FFFFFF;
	border-radius: 16px;
	box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
	overflow: hidden;
`;

const DropDownItem = styled(NavLink)`
	display: flex;
	align-items: center;
  width: 100%;
  height: 54px;
  padding: 0 32px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #2C2E32;
  background-color: #F3F3F3;
  user-select: none;
  cursor: pointer;
  
  & p {
  	margin-bottom: 0;
  }
  
  &:hover {
		color: #2C2E32;
		text-decoration: none;
	}
`;
