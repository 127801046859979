import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCities, setUserCurrentCityId } from '../../features/utils/commonReducer';
import { setDeliveryPolygons, setMapCenter } from '../../features/utils/mapReducer';
import { Helmet } from "react-helmet";
import ym from "react-yandex-metrika";
import styled from 'styled-components';

import logo from '../../imagesForPromo/logo.svg';

import { ReactComponent as PlaymarketButton } from '../../imagesForPromo/appLinks/playmarket.svg';
import { ReactComponent as AppstoreButton } from '../../imagesForPromo/appLinks/appstore.svg';
import { ReactComponent as RustoreButton } from '../../imagesForPromo/appLinks/rustore.svg';

import newGiftImage from '../../imagesForPromo/new-gift-image.svg';

import iconBecycle from '../../imagesForPromo/icon-becycle.svg';
import iconPackage from '../../imagesForPromo/icon-package.svg';
import iconWallet from '../../imagesForPromo/icon-wallet.svg';
import iconNote from '../../imagesForPromo/icon-note.svg';

import Modal from '../../components/Modal';
import CitySelector from './components/CitySelector';
import Stories from './components/Stories';

const Welcome = () => {
	const dispatch = useDispatch();
	const { cities } = useSelector((state) => state.interactiveMapPromo.common);
	const [utmParams, setUtmParams] = useState('');
	const [showCityPopup, setShowCityPopup] = useState(false);

	const appstoreLink = 'https://itunes.apple.com/us/app/%D1%81%D1%8B%D1%82%D1%8B%D0%B9-%D0%BE%D1%84%D0%B8%D1%81-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D0%B5%D0%B4%D0%BE%D0%B2/id1361986950?l=ru&ls=1&mt=8';
	const playmarketLink = 'https://play.google.com/store/apps/details?id=air.com.menu4me.dinner.sitiyoffice';
	const rustoreLink = 'https://apps.rustore.ru/app/air.com.menu4me.dinner.sitiyoffice';

	const images = [
		'/image/storiesImage/photo_1_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_2_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_3_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_4_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_5_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_6_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_7_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_8_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_9_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_10_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_28_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_29_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_30_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_31_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_32_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_34_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_35_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_37_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_38_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_39_2024-12-02_18-42-55.jpg',
		'/image/storiesImage/photo_40_2024-12-02_18-42-55.jpg',
	]

	const cardsInfo = [
		{
			title: 'Бесплатная доставка обедов',
			text: 'Даже при заказе на 100 ₽',
			icon: iconBecycle,
			color: '#FFE2DF',
		},
		{
			title: 'Заказывайте в любимых заведениях',
			text: 'Доставляем из местных столовых и кафе на любой вкус и кошелёк',
			icon: iconPackage,
			color: '#DFECFF',
		},
		{
			title: 'Минимальная сумма заказа 100 ₽',
			text: 'Можно собрать один заказ сразу в нескольких кухнях\nОплата картой онлайн',
			icon: iconWallet,
			color: '#CBF4C5',
		},
		{
			title: 'Заключаем договоры с предприятиями',
			text: 'Решаем вопросы с питанием ваших сотрудников\nДоставляем обеды, ужины, помогаем организовать банкет в офисе',
			icon: iconNote,
			color: '#FFE1B7',
		},
	];

	useEffect(() => {
        dispatch(getCities());
    }, [dispatch]);

	const handleOpenPopup = () => {
		setShowCityPopup(true);
		ym('reachGoal','click_get_gift');
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const utmSource = queryParams.get('utm_source') || '';
		const utmMedium = queryParams.get('utm_medium') || '';
		const utmCampaign = queryParams.get('utm_campaign') || '';

		if (utmSource || utmMedium || utmCampaign) {
			const utmString = `utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`;
			setUtmParams(utmString);
		}
	}, []);

	const MetricAppStore = (event) => {
		event.preventDefault();
		const redirectUrl = `https://redirect.appmetrica.yandex.com/serve/29076955303249395/${utmParams ? `?${utmParams}&button=appstore_download` : ''}`;
		ym('reachGoal','click_to_app_store');
		ym('reachGoal','click_to_download_apps');

		window._tmr.push({
			type: 'reachGoal',
			id: 3473633,
			goal: 'AnyMarketButton'
		});
		  
		window.location.href = redirectUrl;
	}

  	const MetricPlayMarket = (event) => {
		event.preventDefault();
		const redirectUrl = `https://redirect.appmetrica.yandex.com/serve/101134546874934766/${utmParams ? `?${utmParams}&button=playmarket_download` : ''}`;
		ym('reachGoal','click_to_google_play');
		ym('reachGoal','click_to_download_apps');

		window._tmr.push({
			type: 'reachGoal',
			id: 3473633,
			goal: 'AnyMarketButton'
		});

		window.location.href = redirectUrl;
  	}

    const MetricRustore = () => {
		ym('reachGoal','click_to_rustore');
		ym('reachGoal','click_to_download_apps');

		window._tmr.push({
			type: 'reachGoal',
			id: 3473633,
			goal: 'AnyMarketButton'
		});
  	}

	const handleCitySelect = (city) => {
        dispatch(setUserCurrentCityId(+city.id));
        dispatch(setMapCenter([city.latitude, city.longitude]));
        dispatch(setDeliveryPolygons(city.zones));
        setShowCityPopup(false);
    };

	return (
		<Wrapper>
			<Helmet>
				<title>Сервис доставки "СЫТЫЙ</title>
				<meta name="description" content="Доставка из местных столовых и кафе! Установи мобильное приложение, выбирай любимую кухню и заказывай еду на работу и домой!"/>
			</Helmet>

			<Container>
				<Header>
					<MainLogo src={logo}/>
					<Title style={{marginBottom: '20px'}}>Бесплатная доставка обедов на работу и домой!</Title>
					<Text style={{marginBottom: '20px'}}>Заказы принимаем в нашем удобном приложении. Не забудьте получить 250 баллов на ваш первый заказ!</Text>
				</Header>

				<AppButton>
					<a href={appstoreLink} onClick={MetricAppStore}>
						<AppstoreButton/>
					</a>
					<a href={playmarketLink} onClick={MetricPlayMarket}>
						<PlaymarketButton/>
					</a>
					<a href={rustoreLink} onClick={MetricRustore}>
						<RustoreButton/>
					</a>
				</AppButton>

				<GiftBlock>
					<div>
						<Title style={{marginBottom: '12px'}}>250 баллов<br/>в подарок</Title>
						<Text>1 балл = 1 рубль</Text>
					</div>
					<div>
						<GiftImage src={newGiftImage}/>
						<GiftButton onClick={handleOpenPopup}>Получить</GiftButton>
					</div>

					<Modal
						visible={showCityPopup}
						onClose={() => setShowCityPopup(false)}
					>
						<CitySelector
							cities={cities}
							onSelect={handleCitySelect}
						/>
					</Modal>
				</GiftBlock>

				<StoriesBlock>
					<Title style={{margin: '0 0 42px'}}>Большой выбор блюд на любой вкус</Title>
					<Stories images={images}/>
				</StoriesBlock>

				<CardsBlock>
					{cardsInfo.map(item => (
					<CardWrapper style={{background: item.color}}>
						<CardIcon src={item.icon}/>
						<Title style={{marginBottom: '12px'}}>{item.title}</Title>
						<Text>{item.text}</Text>
					</CardWrapper>
					))}
				</CardsBlock>

				
			</Container>

			<Foot>
				<p>ООО "Бизнес-Софт"</p>
				<p>ИНН: 6321264045</p>
				<p className='foot' >
					<a className='tel' href="tel:+7-800-200-69-24">+7-800-200-69-24</a>
				</p>
			</Foot>
		</Wrapper>
	)
}

export default Welcome;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`
const Container = styled.div`
	margin: 0 auto;
	max-width: 480px;
	width: 100%;
	height: auto;
`
const Header =styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 16px;
`
const MainLogo =styled.img`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 32px 0;
	width: 100px;
	height: 100px;
`
const Title =styled.div`
	font-family: Gilroy, Arial, Helvetica, sans-serif;
	font-size: 32px;
	font-weight: 700;
	line-height: 35.2px;
	color: #2A2A2A;
`
const Text =styled.div`
	font-family: Gilroy, Arial, Helvetica, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 21.83px;
	color: #2A2A2A;
`
const AppButton = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	width: 100%;
	margin-bottom: 60px;
	padding: 0 16px;

	a, div {
		flex: 1;
		cursor: pointer;
	}
`
const GiftBlock =styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: 5px;
	margin-bottom: 50px;
	padding: 48px 40px;
	background: #F4F4F4;
	border-radius: 32px;
`
const GiftImage =styled.img`
	position: absolute;
	top: -50px;
	right: 40px;
	display: flex;
	align-items: flex-end;
	gap: 12px;
`
const GiftButton =styled.div`
	padding: 0 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 50px;
	background: #EA394B;
	border: none;
	border-radius: 16px;
	color: #ffffff;
	transition: all .3s;
	cursor: pointer;

	&:hover {
		color: #81cbfc;
		text-decoration: none;
	}
`
const StoriesBlock =styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
	padding: 0 16px;
`
const CardsBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
`
const CardWrapper = styled.div`
  	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 32px;
	border-radius: 32px;
`
const CardIcon = styled.img`
	margin-bottom: 16px;
	width: fit-content;
  	height: 70px;
`
const Foot = styled.div`
	/* position: absolute;
	bottom: 0;
	left: 0; */
	margin-top: 50px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color:#2E2E2E;
	font-family: 'Montserrat';
	font-style: normal;
	color:#ffffff;
	letter-spacing: 0.160192px;
	font-weight: 400;
	font-size: 14px;
	padding: 15px 20px;
	p{
		margin-bottom: 0;
	}
	.tel{
		color:#ffffff;
	}
`
