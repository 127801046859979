import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import MenuItem from "../MenuItem";

const Category = props => {
  const {data, amounts, accountId, addToCart, openModal} = props;

  const [someState, setSomeState] = useState([]);

  const {} = useSelector(store => store.menu);

  const dispatch = useDispatch();

  useEffect(() => {

  }, []);

  return (
    <Wrapper>
      <CategoryName>{data.title}</CategoryName>
      <ItemsList>
        {data.data && Array.isArray(data.data) ? (
          data.data.map(item => {
            return (
              <MenuItem
                data={item}
                amount={amounts[item.id]}
                accountId={accountId}
                addToCart={addToCart}
                onPress={() => openModal(item)}
              />
            );
          })
        ) : null}
      </ItemsList>
    </Wrapper>
  );
};

export default Category;

const Wrapper = styled.div`
  margin-top: 40px;
`;

const ItemsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
`;

const CategoryName = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2C2E32;
  user-select: none;
`;
