import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import * as _ from "underscore";
import {addElemToIndexes} from "../../../actions/CartActions";
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions";
import NoPhoto from '../../../images/menu/no_photo.png';
import Food from '../../../images/menu/food.png';
import Minus from '../../../images/menu/minus.svg';
import Plus from '../../../images/menu/plus.svg';

const MenuItem = props => {
  const {data, amount, accountId, onPress, addToCart} = props;

  const [isOnCart, setIsOnCart] = useState(false);

  const {activeDay, activePeriod, activePeriodForCart} = useSelector(store => store.menu);
  const {cartModifiedIndexes} = useSelector(store => store.cart);
  const {isAuth, activeCompany} = useSelector(store => store.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsOnCart(
      amount && (
        activePeriodForCart === null ||
        activePeriodForCart.day === activeDay && String(activePeriodForCart.period.id) === String(activePeriod.id)
      )
    );
  }, [amount, activePeriodForCart, activeDay, activePeriod]);

  useEffect(() => {
    console.log(isOnCart, 'isOnCart')
  }, [isOnCart])

  const newItem = (type = false) => {
    let image = _.find(data.images, img => {
      if (img && img.id) {
        return parseInt(img.id, 10) === parseInt('1000' + data.MediaId, 10);
      } else {
        return false;
      }
    });

    let itemForAdd = {
      ...data,
      activeDay: activeDay,
      amount: type
        ? type === 'remove'
          ? amount - 1
          : type === 'add'
            ? amount + 1
            : 1
        : 1,
      image: image ? image.source : null,
      modifiers: [],
      restId: accountId,
    };

    delete itemForAdd.images;

    addToCart(itemForAdd, type);
  };

  const removeModItem = () => {
    let image = _.find(data.images, img => {
      return parseInt(img.id, 10) === parseInt('1000' + data.MediaId, 10);
    });

    let mod = [];

    let arr = cartModifiedIndexes.map(a => Object.assign({}, a));
    arr.reverse();
    let itemFromArr = arr.find(i => i.id === data.id);

    let countTest = arr.reduce((acc, cur) => {
      if (cur.id === data.id && cur.modId === itemFromArr.modId) {
        return acc + cur.amount
      } else {
        return acc
      }
    }, 0)

    if (itemFromArr) {
      if (itemFromArr.amount > 1) {
        itemFromArr.amount -= 1;
      } else {
        let index = arr.findIndex(i => i.id === data.id);
        arr.splice(index, 1);
        itemFromArr.amount -= 1;
      }
      if (itemFromArr.modId && _.size(data.modifiers) > 0) {
        let foundMod = data.modifiers.find(i => i.id === itemFromArr.modId);
        mod.push(foundMod);
      }
    }
    arr.reverse();
    dispatch(addElemToIndexes(arr));

    console.log(itemFromArr, 'itemFromArr');

    let itemForRemove = {
      ...data,
      activeDay: activeDay,
      //amount: itemFromArr ? itemFromArr.amount : 0, //!!!
      amount: countTest ? countTest - 1 : 0,
      image: image ? image.source : null,
      modifiers: mod,
      restId: accountId,
    };

    delete itemForRemove.images;

    console.log(itemForRemove, 'itemForRemove');

    addToCart(itemForRemove, 'remove');
  };

  return (
    <Wrapper onClick={() => onPress()}>
      <ImageBackground
        src={data.mediaId ? `https://menuforme.online/img/cache/so_product_preview_quad/${data.mediaId}.jpg` : NoPhoto}
      >
        {data.amountPerOrder > 0 && data.amountPerOrder < 6 ? (
          <AmountPerOrderView>
            <img src={Food} style={{marginBottom: 1}} width={24} height={24} alt=''/>
            <AmountPerOrder style={{marginLeft: 5}}>{data.amountPerOrder} шт</AmountPerOrder>
          </AmountPerOrderView>
        ) : data.amountPerOrder <= 0 && data.amountPerOrder !== null ? (
          <AmountPerOrderView style={{backgroundColor: '#BBBBBB'}}>
            <AmountPerOrder>закончилось</AmountPerOrder>
          </AmountPerOrderView>
        ) : null}
      </ImageBackground>
      <ItemInfo>
        <ItemName>
          {data.name.trim()}
        </ItemName>
        <Weight isCenter={isOnCart}>
          {isOnCart
            ? (!!+data.weight ? `${+data.weight} г` : '') + ' • ' + +data.price + ' ₽'
            : !!+data.weight
              ? `${+data.weight} г`
              : ''
          }
        </Weight>
        {isOnCart ? (
          (data.modifiers && data.modifiers.length > 0) ? (
            <EditButtons>
              <EditButton onClick={e => {
                e.stopPropagation();
                removeModItem();
              }}>
                <img src={Minus} width={24} height={2} alt='' />
              </EditButton>
              <AmountText>{amount}</AmountText>
              <EditButton onClick={e => {
                e.stopPropagation();
                onPress();
              }}>
                <img src={Plus} width={24} height={24} alt='' />
              </EditButton>
            </EditButtons>
          ) : (
            <EditButtons>
              <EditButton onClick={e => {
                e.stopPropagation();
                newItem('remove');
              }}>
                <img src={Minus} width={24} height={2} alt='' />
              </EditButton>
              <AmountText>{amount}</AmountText>
              <EditButton onClick={e => {
                e.stopPropagation();
                if (amount < data.amountPerOrder || data.amountPerOrder === null) {
                  newItem('add');
                }
              }}>
                <img src={Plus} width={24} height={24} alt='' />
              </EditButton>
            </EditButtons>
          )
        ) : (data.modifiers && data.modifiers.length > 0) ? (
          <EditButtons>
            <BuyButton
              isOver={data.amountPerOrder <= 0 && data.amountPerOrder !== null}
              onClick={e => {
                e.stopPropagation();
                if (data.amountPerOrder > 0 || data.amountPerOrder === null) {
                  onPress();
                }
              }}
            >
              <AmountText>{+data.price} ₽</AmountText>
            </BuyButton>
          </EditButtons>
        ) : (
          <EditButtons>
            <BuyButton
              isOver={data.amountPerOrder <= 0 && data.amountPerOrder !== null}
              onClick={e => {
                e.stopPropagation();
                if (data.amountPerOrder > 0 || data.amountPerOrder === null) {
                  if (activePeriod && +activePeriod.isDisabled) {
                    dispatch(deliveryCycleInfoMessage('promo_message_error', activePeriod.isDisabledReason));
                  } else if (!isAuth) {
                    dispatch(deliveryCycleInfoMessage('promo_message_error', 'Для оформления заказа необходимо авторизоваться'));
                  } else if (!activeCompany) {
                    dispatch(deliveryCycleInfoMessage('promo_message_error', 'Для оформления заказа необходимо выбрать адрес доставки'));
                  } else {
                    newItem();
                  }
                }
              }}
            >
              <AmountText>{+data.price} ₽</AmountText>
            </BuyButton>
          </EditButtons>
        )}
      </ItemInfo>
    </Wrapper>
  );
};

export default MenuItem;

const Wrapper = styled.div`
  width: calc((100% - 60px) / 4);
  background: red;
  margin: 0 20px 20px 0;
  background-color: #FFFFFF;
  box-shadow: 0px 8.92941px 25.0024px rgba(193, 193, 193, 0.25);
  border-radius: 16px;
  cursor: pointer;
  
  &:nth-child(4n) {
    margin-right: 0;
  }
`;

const ImageBackground = styled.div`
  position: relative;
  height: 221px;
  border-radius: 16px;
  background: ${({src}) => src ? 'url(' + src + ')' : ''};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const AmountPerOrderView = styled.div`
  position: absolute;
  right: 12px;
  bottom: 12px;
  zIndex: 1;
  display: flex;
  align-items: center;
  height: 47px;
  padding: 0 16px;
  border-radius: 24px;
  background-color: #2C2E32;
  box-shadow: 0px 7.14353px 7.14353px rgba(0, 0, 0, 0.08);
`;

const AmountPerOrder = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  user-select: none;
`;

const ItemInfo = styled.div`
  padding: 24px 16px 16px;
`;

const ItemName = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #2C2E32;
  user-select: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 62px;
`;

const Weight = styled.p`
  margin: 16px 0 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #A4A4A4;
  text-align: ${({isCenter}) => isCenter ? 'center' : 'left'};
  user-select: none;
`;

const EditButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 71px;
  margin-top: 16px;
`;

const EditButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 71px;
  height: 71px;
  background-color: #F5F4F2;
  border-radius: 14px;
  user-select: none;
`;

const AmountText = styled.p`
  margin-bottom: 0;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  user-select: none;
`;

const BuyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 71px;
  background-color: #F5F4F2;
  border-radius: 14px;
  opacity: ${({isOver}) => isOver ? '0.4' : '1'};
`;
